/*
 * All states data
 */
export const allStates = [
  {
    slug: 'maharashtra',
    name: 'Maharashtra',
    localised_name: 'महाराष्ट्र',
    language: 'mr',
    supportedLanguages: ['mr', 'en'],
    code: 'MH',
    source: 'APPMH',
    shop: true,
  },
  {
    slug: 'gujarat',
    name: 'Gujarat',
    localised_name: 'ગુજરાત',
    language: 'gu',
    supportedLanguages: ['gu', 'en'],
    code: 'GJ',
    source: 'APPGJ',
    shop: true,
  },
  {
    slug: 'rajasthan',
    name: 'Rajasthan',
    localised_name: 'राजस्थान',
    language: 'hi',
    supportedLanguages: ['hi', 'en'],
    code: 'RJ',
    source: 'APPRJ',
    shop: true,
  },
  {
    slug: 'uttar-pradesh',
    name: 'Uttar Pradesh',
    localised_name: 'उत्तर प्रदेश',
    language: 'hi',
    supportedLanguages: ['hi', 'en'],
    code: 'UP',
    source: 'APPUP',
    shop: true,
  },
  {
    slug: 'madhya-pradesh',
    name: 'Madhya Pradesh',
    localised_name: 'मध्य प्रदेश',
    language: 'hi',
    supportedLanguages: ['hi', 'en'],
    code: 'MP',
    source: 'APPMP',
    shop: true,
  },
  {
    slug: 'bihar',
    name: 'Bihar',
    localised_name: 'बिहार',
    language: 'hi',
    supportedLanguages: ['hi', 'en'],
    code: 'BH',
    source: 'APPBH',
    shop: false,
  },
  {
    slug: 'haryana',
    name: 'Haryana',
    localised_name: 'हरयाणा',
    language: 'hi',
    supportedLanguages: ['hi', 'en'],
    code: 'HR',
    source: 'APPHR',
    shop: true,
  },
  {
    slug: 'karnataka',
    name: 'Karnataka',
    localised_name: 'ಕರ್ನಾಟಕ',
    language: 'kn',
    supportedLanguages: ['kn', 'en'],
    code: 'KA',
    source: 'APPKA',
    shop: true,
  },
  {
    slug: 'andhra-pradesh',
    name: 'Andhra Pradesh',
    localised_name: 'ఆంధ్రప్రదేశ్',
    language: 'te',
    supportedLanguages: ['te', 'en'],
    code: 'AD',
    source: 'APPAD',
    shop: true,
  },
  {
    slug: 'telangana',
    name: 'Telangana',
    localised_name: 'తెలంగాణ',
    language: 'te',
    supportedLanguages: ['te', 'en'],
    code: 'TL',
    source: 'APPTL',
    shop: false,
  },
  {
    slug: 'chhattisgarh',
    name: 'Chhattisgarh',
    localised_name: 'छत्तीसगढ़',
    language: 'hi',
    supportedLanguages: ['hi', 'en'],
    code: 'CT',
    source: 'APPCT',
    shop: false,
  },
];

export const defaultUserState = allStates[0].slug;

// This is to generate canonicals where the entity is identical
// for multiple states except for language difference
export const defaultStateForLanguage = {
  en: 'maharashtra',
  hi: 'rajasthan',
  mr: 'maharashtra',
  gu: 'gujarat',
  te: 'telangana',
  kn: 'karnataka',
};

const slugToState = {};
const sourceToState = {};

allStates.forEach((state) => {
  slugToState[state.slug] = state;
  sourceToState[state.source] = state;
});

export const getAppSource = (userState) => {
  const selectedState = slugToState[userState];
  return selectedState ? selectedState.source : 'APP';
};

export const getLanguageForState = (userState) => {
  const selectedState = slugToState[userState];
  return selectedState && selectedState.language
    ? selectedState.language
    : 'en';
};

export const isAgriShopEnabled = (userState) => {
  const selectedState = slugToState[userState];
  return selectedState && selectedState.shop;
};

export const getStateBySource = (source) => {
  return sourceToState[source] || allStates[0];
};

export const getStateByCode = (code) => {
  return getStateBySource(`APP${code}`);
};

export const getStateCode = (state) => {
  return slugToState[state]?.code;
};

export const getStateBySlug = (slug) => {
  return slugToState[slug] || allStates[0];
};

export const isSourceSupported = (source) => {
  return sourceToState[source] != null;
};

export const isStateSupported = (slug) => {
  return slugToState[slug] != null;
};

export const isStateLanguageCombinationValid = (slug, language) => {
  if (slugToState[slug].supportedLanguages.includes(language)) return true;

  return false;
};
