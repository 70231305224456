import React from 'react';
import { injectIntl } from 'react-intl';
import { Redirect } from 'react-router-dom';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { useFetchData } from '../services/useFetchData';
import { getRouteParams, makeShopHref } from '../utilities/links';
import { isAgriShopEnabled } from '../utilities/states';
import store from '../utilities/store';
import MessagePage from './MessagePage';

function ShopNotAvailable({ setHeaderURLs, intl }) {
  const { loadingStatus, match, search } = useFetchData(
    ShopNotAvailable,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;
  const { state } = getRouteParams(match, search);
  const isValidState = isAgriShopEnabled(state);

  if (isValidState) {
    const url = makeShopHref(match, search);
    return <Redirect to={url} />;
  }
  return (
    <MessagePage messageId="label_no_agri_shop" image="/shop_unavailable.png" />
  );
}

ShopNotAvailable.fetchData = () => ({
  titleId: 'label_no_agri_shop',
  canonical: 'nstate',
  stateURLs: 'home',
  languageURLs: 'home',
  initialData: {},
});

export default injectIntl(ShopNotAvailable);
