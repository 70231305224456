import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import apiCall from '../utilities/apiCall';
import store from '../utilities/store';
import { getAppSource, isAgriShopEnabled } from '../utilities/states';
import BrandCard, { BrandListWrapper } from './BrandCard';
import LayoutWrapper from '../components/Layout';
import { Title } from '../components/Heading';
import { getRouteParams, makeShopNotAvailableHref } from '../utilities/links';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { getEnv } from '../utilities/getEnv';

const BrandListPage = ({ setHeaderURLs, intl }) => {
  const { data, loadingStatus } = useFetchData(
    BrandListPage,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;
  const { response } = data;

  const { brands } = response;
  return (
    <LayoutWrapper>
      <Title>
        <FormattedMessage id="label_all_brands" />
      </Title>
      <BrandListWrapper>
        {brands.map((b) => (
          <BrandCard key={b.brandId} brand={b} />
        ))}
      </BrandListWrapper>
    </LayoutWrapper>
  );
};

async function getBrandsList(userState, lang) {
  const api = `${getEnv('FWA_API_URL')}/catalogservice/v2/brand/`;

  const url = new URL(api);
  url.searchParams.append('language', lang);

  return apiCall(url.href, { Source: getAppSource(userState) });
}

BrandListPage.fetchData = async (match, search) => {
  const { id, language, state } = getRouteParams(match, search);
  if (!isAgriShopEnabled(state)) {
    return { redirectURL: makeShopNotAvailableHref(match, search, state) };
  }

  const title = id || '';
  const seoData = {};
  let result = {};

  result = await getBrandsList(state, language);

  const initialData = { response: result.responseData || [] };

  /*
   * Canonical: we return self, as the list of brands (the order mainly)
   * varies between states.
   */
  return {
    initialData,
    title,
    titleId: 'label_all_brands',
    stateURLs: 'shop',
    seoData,
    canonical: 'self',
  };
};

export default injectIntl(BrandListPage);
