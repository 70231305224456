import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { styled } from '@mui/material/styles';

import Heading, { Title, Text } from '../components/Heading';
import LayoutWrapper from '../components/Layout';
import ProductCard, { ProductListWrapper } from '../shop/ProductCard';
// TODO: import Share from '../../components/Share';
import store from '../utilities/store';
import apiCall from '../utilities/apiCall';
import slugify from '../utilities/slugify';
import {
  getRouteParams,
  makeIssueHref,
  makeShopNotAvailableHref,
  normalizeState,
} from '../utilities/links';
import { shopLanguages } from '../utilities/languages';
import {
  getStateBySlug,
  allStates,
  isAgriShopEnabled,
} from '../utilities/states';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { getEnv } from '../utilities/getEnv';
import ClevertapService from '../services/ClevertapService';

const IssueNameWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  height: '55px',
});

const IssuePage = ({ setHeaderURLs, intl }) => {
  const { data, loadingStatus } = useFetchData(
    IssuePage,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;
  const { response } = data;

  if (!response) {
    return <FormattedMessage id="label_no_product_available" />;
  }

  const {
    issueDetails: {
      issueName,
      metadata: { image, description },
      productList,
    },
  } = response;

  if (!issueName) {
    return <FormattedMessage id="label_no_product_available" />;
  }

  return (
    <>
      <LayoutWrapper>
        <IssueNameWrapper>
          <Title>{issueName}</Title>
          {/* <Share url={issueUrl} /> TODO */}
        </IssueNameWrapper>
        <img src={image} alt={issueName} width="100%" />
        <Text>{description}</Text>
        {productList && productList.length > 0 && (
          <>
            <Heading>
              <FormattedMessage id="issue_heading_solutions_for" />
            </Heading>
            <ProductListWrapper>
              {productList.map((p) => (
                <div
                  key={p.skuCode}
                  onClick={() =>
                    ClevertapService.pushEvent(`Card Clicked`, {
                      Type: 'Product',
                      SkuCode: p?.skuCode,
                      'Product Name': p?.productName,
                    })
                  }
                >
                  <ProductCard product={p} />
                </div>
              ))}
            </ProductListWrapper>
          </>
        )}
      </LayoutWrapper>
    </>
  );
};

async function getIssueDetails(id, lang) {
  const api = `${getEnv('FWA_API_URL')}/catalogservice/v2/issue/${id}`;
  const url = new URL(api);
  url.searchParams.append('language', lang);

  return apiCall(url.href, { Source: 'APP' });
}

IssuePage.fetchData = async (match, search) => {
  const { id, state, language } = getRouteParams(match, search);
  if (!isAgriShopEnabled(state)) {
    return { redirectURL: makeShopNotAvailableHref(match, search, state) };
  }
  const seoData = {};

  const result = await getIssueDetails(id, language);

  if (!result.responseData || !result.responseData.issueDetails) {
    console.error('ERROR fetching issue details for:', id, language);
    return {
      initialData: {},
      titleId: 'no_products_found_title',
      seoData: {},
    };
  }

  const issueDetails = result.responseData.issueDetails;
  const {
    issueName,
    cropName,
    metadata: { description, imageUrl },
  } = issueDetails;
  const canonical = normalizeState(match, search);
  const titleId = 'title_shop';
  const titleParams = { heading: `${cropName} ${issueName}` };
  seoData.description = `${cropName} ${issueName}`;

  seoData.ogTagData = {
    type: 'issue',
    description,
    imageUrl,
  };

  const initialData = {
    response: result.responseData,
    issueUrl: canonical,
  };

  // All languages are not supported, products within issues are not supported
  // for non-shop languages (server error if we try to query for that language)
  const languageSlugs = {};
  const jobs = shopLanguages.map((lang) =>
    (async (l) => {
      const resp = await getIssueDetails(id, l);
      if (!!resp && !!resp.responseData) {
        const { issueName: i, cropName: c } = resp.responseData.issueDetails;
        languageSlugs[l] = slugify(`${c} ${i}`);
      }
    })(lang),
  );
  await Promise.all(jobs);

  const languageURLs = {};
  getStateBySlug(state).supportedLanguages.forEach((lang) => {
    const slug = languageSlugs[lang];
    languageURLs[lang] = slug
      ? makeIssueHref(match, search, slug, id, lang)
      : 'languageNa';
  });

  const stateURLs = {};
  allStates.forEach((s) => {
    const lang = language === 'en' ? 'en' : s.language;
    const slug = languageSlugs[lang];
    stateURLs[s.slug] = slug
      ? makeIssueHref(match, search, slug, id, lang, s.slug)
      : 'stateNa';
  });

  return {
    initialData,
    titleId,
    titleParams,
    seoData,
    canonical,
    languageURLs,
    stateURLs,
  };
};

export default injectIntl(IssuePage);
