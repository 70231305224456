import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import store from '../utilities/store';
import apiCall from '../utilities/apiCall';
import { getAppSource } from '../utilities/states';

import FarmersPost from './FarmersPost';
import FarmerDetails from './FarmerDetails';
import { getRouteParams } from '../utilities/links';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { getEnv } from '../utilities/getEnv';

function FarmerProfilePage({ setHeaderURLs, intl }) {
  const { data, loadingStatus, match, search } = useFetchData(
    FarmerProfilePage,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;
  const { response } = data;

  if (!response) {
    return <FormattedMessage id="post_article_not_found" />;
  }
  const { page } = getRouteParams(match, search);

  return (
    <>
      <FarmerDetails userDetails={response.userDetails} />
      {response.userPosts.totalCount && (
        <FarmersPost
          name={response.userDetails.aliasName}
          userPosts={response.userPosts}
          page={page}
        />
      )}
    </>
  );
}

async function getUserDetails(id, lang, userState) {
  const api = `${getEnv('FWA_API_URL')}/appuserservice/v1/appuser/${id}`;
  const url = new URL(api);
  url.searchParams.append('language', lang);
  url.searchParams.append('source', getAppSource(userState));
  return apiCall(url.href, { Source: 'APP' });
}

async function getUserPosts(id, lang, userState, page) {
  const offset = (page - 1) * 20;
  const url = new URL(`${getEnv('FWA_API_URL')}/communicationservice/v2/post`);
  url.searchParams.append('userId', id);
  url.searchParams.append('curated', 'false');
  url.searchParams.append('language', lang);
  url.searchParams.append('limit', 21);
  url.searchParams.append('offset', offset);
  url.searchParams.append('source', getAppSource(userState));
  return apiCall(url.href, { Source: 'APP' });
}

FarmerProfilePage.fetchData = async (match, search) => {
  const { id, language, state, page } = getRouteParams(match, search);
  let userDetails = {};
  let userPosts = {};
  let final = {};
  const seoData = {};

  userDetails = await getUserDetails(id, language, state);
  if (!userDetails.responseData) {
    return {
      initialData: {},
      titleId: 'post_article_not_found',
      stateURLs: 'home',
      languageURLs: 'home',
    };
  }

  userPosts = await getUserPosts(id, language, state, page);

  const title = userDetails.responseData.aliasName || id;
  seoData.description = userDetails.responseData.aliasName;

  final = {
    userDetails: userDetails.responseData,
    userPosts: userPosts.responseData,
  };

  const initialData = { response: final || [] };
  return {
    initialData,
    title,
    seoData,
    canonical: 'nstate',
  };
};

export default injectIntl(FarmerProfilePage);
