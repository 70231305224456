import React from 'react';

import BrandCard, { BrandListWrapper } from './BrandCard';
import ProductCard, { ProductListWrapper } from './ProductCard';
import CropCard, { CropListWrapper } from '../crop/CropCard';
import { Title } from '../components/Heading';
import LayoutWrapper from '../components/Layout';
import Footer from '../components/Footer';
import store from '../utilities/store';
import { getAppSource, isAgriShopEnabled } from '../utilities/states';
import { getGoogleStructuredData } from '../utilities/googleStructuredData';

import {
  getRouteParams,
  makeFeaturedProductsHref,
  makeTrendingProductsHref,
  makeAllBrandsHref,
  makeShopNotAvailableHref,
} from '../utilities/links';
import { getEnv } from '../utilities/getEnv';
import Categories from './Categories';
import SeeAllLink from '../components/SeeAllLink';
import Banners from './TiledBanner';
import CarouselBanner from './ScrollableBanner';
import { addHrefsToBanners } from '../utilities/bannerUtils';
import apiCall from '../utilities/apiCall';
import feedMockData from './feedMockData';
import { getAllCrops } from '../services';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { injectIntl } from 'react-intl';
import Search from '../components/Search';
import CorporateBar from '../components/CorporateBar';

const ctaLinkMakers = {
  FEATURED_PRODUCTS_CTA: makeFeaturedProductsHref,
  POPULAR_PRODUCTS_CTA: makeTrendingProductsHref,
  FEATURED_BRANDS_CTA: makeAllBrandsHref,
};

const ShopPage = ({ setHeaderURLs, intl }) => {
  const { data, loadingStatus, match, search } = useFetchData(
    ShopPage,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;
  const { sections, crops } = data;
  const { language } = getRouteParams(match, search);

  const components = [];
  sections?.forEach((section, index) => {
    if (section.type === 'SCROLLABLE_BANNERS') {
      components.push(<CarouselBanner key={index} banner={section.data} />);
    } else if (section.type === 'HEADING') {
      if (
        section.data.value !== 'RECENTLY_VIEWED_PRODUCTS' &&
        section.data.value !== 'RELATED_PRODUCTS'
      ) {
        components.push(<Title key={index}>{section.data.label}</Title>);
      }
    } else if (section.type === 'REDIRECT_ENTITY') {
      components.push(<Banners key={index} banner={section.data} />);
    } else if (section.type === 'SHOP_BY_CATEGORY') {
      components.push(
        <Categories key={index} categories={section.data.categories} />,
      );
    } else if (section.type === 'TAGS' && crops) {
      components.push(
        <CropListWrapper key={index}>
          {crops.map((c) => (
            <CropCard key={c.id} crop={c} />
          ))}
        </CropListWrapper>,
      );
    } else if (section.type === 'PRODUCTS') {
      if (
        section.section !== 'RECENTLY_VIEWED_PRODUCTS' &&
        section.section !== 'RELATED_PRODUCTS'
      ) {
        components.push(
          <ProductListWrapper key={index}>
            {section.data.products.map((p) => (
              <ProductCard key={p.skuCode} product={p} />
            ))}
          </ProductListWrapper>,
        );
      }
    } else if (section.type === 'CALL_TO_ACTION') {
      const hrefMaker = ctaLinkMakers[section.data.value];
      if (hrefMaker) {
        components.push(
          <SeeAllLink
            key={index}
            text={section.data.label}
            href={hrefMaker(match, search)}
          />,
        );
      }
    } else if (section.type === 'BRANDS') {
      components.push(
        <BrandListWrapper key={index}>
          {section.data.brands.map((b) => (
            <BrandCard key={b.brandId} brand={b} />
          ))}
        </BrandListWrapper>,
      );
    }
  });

  return (
    <>
      <Search match={match} search={search} />
      <CorporateBar />
      <LayoutWrapper>
        {components}
        <Footer lang={language} />
      </LayoutWrapper>
    </>
  );
};

ShopPage.fetchData = async (match, search) => {
  const { language, state } = getRouteParams(match, search);
  if (!isAgriShopEnabled(state)) {
    return { redirectURL: makeShopNotAvailableHref(match, search, state) };
  }
  const source = getAppSource(state);
  const feedUrl = `${getEnv('FWA_API_URL')}/feedservice/v1/section/SHOP/entity`;
  const feedParams = `offset=0&limit=30&language=${language}&source=${source}&version=500`;
  const feedResponse = getEnv('USE_MOCK_DATA')
    ? feedMockData
    : await apiCall(`${feedUrl}?${feedParams}`);

  let initialData = {};
  if (feedResponse && feedResponse.responseData) {
    initialData.sections = feedResponse.responseData;
  }
  const cropsResponse = await getAllCrops(language, state, 6);
  initialData.crops = cropsResponse.responseData.crops;

  const bannerList = [];
  initialData?.sections?.forEach((section) => {
    if (
      section.type === 'SCROLLABLE_BANNERS' ||
      section.type === 'REDIRECT_ENTITY'
    ) {
      bannerList.push(section.data);
    }
  });
  await addHrefsToBanners(bannerList, match, search);

  // Canonical: self, as each state has its own list and banners.
  return {
    initialData,
    titleId: 'shop_home',
    stateURLs: 'shop',
    canonical: 'self',
    seoData: {
      googleStructuredData: getGoogleStructuredData({
        type: 'Agriculture',
        name: 'Agriculture Shops',
      }),
    },
  };
};

export default injectIntl(ShopPage);
