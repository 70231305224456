import React from 'react';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import ChatBubbleRoundedIcon from '@mui/icons-material/ChatBubbleRounded';
import ThumbUpAltRoundedIcon from '@mui/icons-material/ThumbUpAltRounded';

import { pallette } from '../constants/pallette';
import { Title } from '../components/Heading';
import getAddress from '../utilities/getAddress';
import { getLanguageLabel } from '../utilities/languages';

const ColumnWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  background: '#fff',
  margin: '10px 0px',
  '.name': {
    fontWeight: 'bold',
    fontSize: '20px',
  },
  '.address, .language': {
    marginTop: '4px',
  },
  '.title': {
    margin: '0px 20px',
    '> span': {
      marginRight: '5px',
    },
  },
  '.details': {
    width: '33%',
    alignItems: 'center',
  },
  '@media (max-width: 375px)': {
    '.detail': {
      width: '50%',
    },
    '.pic': {
      width: '100px',
      height: '100px',
      borderRadius: '50px',
      lineHeight: '100px',
    },
  },
});

const RowWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  '.rowData': {
    '> b': {
      margin: '0px 20px',
    },
  },
});

const FarmerProfilePicContainer = styled('div')({
  position: 'relative',
  display: 'inline-block',
  margin: '10px 30px 10px 10px',
  width: '200px',
  height: '200px',
  borderRadius: '100px',
  overflow: 'hidden',
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: '50px',
  lineHeight: '200px',
  img: {
    objectFit: 'fill',
    height: '100%',
    width: '100%',
  },
});

function FarmerDetails({ userDetails }) {
  const color = pallette[userDetails.aliasName.charCodeAt(0) % 16];

  let picture = userDetails.aliasName[0];
  if (userDetails.profilePicture) {
    let transform = '';
    if (userDetails.profilePicture.includes('static.agrostar.in')) {
      transform = '?tr=w-200,h-200,fo-auto';
    }
    picture = <img src={userDetails.profilePicture + transform} alt="profile" />
  }

  return (
    <ColumnWrapper>
      <RowWrapper>
        <FarmerProfilePicContainer
          sx={{ backgroundColor: color }}
          className="pic"
        >
          {picture}
        </FarmerProfilePicContainer>

        <ColumnWrapper className="detail">
          <div className="name">
            <b>{userDetails.aliasName}</b>
          </div>
          <div className="address">
            {getAddress(userDetails.profileAddress)}
          </div>
          <div className="language">
            <FormattedMessage id="label_profile_language" />
            {': '}
            {getLanguageLabel(userDetails.language)}
          </div>
        </ColumnWrapper>
      </RowWrapper>

      <Title className="title">
        <FormattedMessage id="label_popularity" />
      </Title>
      <RowWrapper>
        <ColumnWrapper className="details">
          <RowWrapper className="rowData">
            <img height="25" width="25" src="/followers.svg" alt="followers" />
            <b>{userDetails.communicationStats.followers}</b>
          </RowWrapper>
          <FormattedMessage id="label_followers" />
        </ColumnWrapper>
        <ColumnWrapper className="details">
          <RowWrapper className="rowData">
            <ChatBubbleRoundedIcon />
            <b>{userDetails.communicationStats.comments}</b>
          </RowWrapper>
          <FormattedMessage id="label_comments_received" />
        </ColumnWrapper>
        <ColumnWrapper className="details">
          <RowWrapper className="rowData">
            <ThumbUpAltRoundedIcon />
            <b>{userDetails.communicationStats.likes}</b>
          </RowWrapper>
          <FormattedMessage id="label_likes_received" />
        </ColumnWrapper>
      </RowWrapper>

      <Title className="title">
        <FormattedMessage id="label_activity" />
      </Title>
      <RowWrapper>
        <ColumnWrapper className="details">
          <b>{userDetails.communicationStats.following}</b>
          <FormattedMessage id="label_people_following" />
        </ColumnWrapper>
        <ColumnWrapper className="details">
          <b>{userDetails.communicationStats.commentsMade}</b>
          <FormattedMessage id="label_comments_made" />
        </ColumnWrapper>
      </RowWrapper>
    </ColumnWrapper>
  );
}

export default FarmerDetails;
