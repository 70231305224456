import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Clamp from 'react-multiline-clamp';
import { FormattedMessage } from 'react-intl';

import { East } from '@mui/icons-material';
import { Card, CardMedia, Grid, Typography } from '@mui/material';

import slugify from '../utilities/slugify';
import AndroidService from '../services/AndroidService';
import ClevertapService from '../services/ClevertapService';
import { SubHeadingWrapper, ViewAllButton } from './ExpertVideos';
import {
  getRouteParams,
  makeAndroidIssueHref,
  makeIssueHref,
  makeIssueListingPageHref,
} from '../utilities/links';
import { getEnv } from '../utilities/getEnv';
import { getRegionalAppString } from '../utilities/getRegionalAppString';
import { CropInfoSkeleton } from './CropsTabs';
import useQuery from '../utilities/useQuery';

export default function CropIssues({
  match,
  search,
  loading,
  isAndroid,
  cropSchedule,
}) {
  const query = useQuery();
  const appLinks = query.get('appLinks');
  const { language } = getRouteParams(match, search);
  const issueListingPageURL =
    makeIssueListingPageHref(
      match,
      search,
      cropSchedule?.cropName,
      cropSchedule?.cropId,
    ) + '&showHeader=false';

  if (loading === 'skeleton') return <CropInfoSkeleton />;
  if (!cropSchedule?.issues?.length) return null;

  return (
    <>
      <SubHeadingWrapper>
        <FormattedMessage id="label_pest_and_disease" />
      </SubHeadingWrapper>

      <Grid
        container
        flexWrap="nowrap"
        gap="10px"
        sx={{
          mt: 1,
          p: '1px 16px 2px',
          overflowX: 'scroll',
          overflowY: 'hidden',
        }}
      >
        {cropSchedule?.issues?.map((issue, index) => {
          return (
            <IssueCard
              key={index}
              issue={issue}
              match={match}
              search={search}
            />
          );
        })}
      </Grid>

      <ViewAllButton
        variant="text"
        endIcon={<East />}
        href={
          appLinks === 'saathi'
            ? `${issueListingPageURL}&appLinks=saathi`
            : isAndroid
            ? `ulink://android.agrostar.in/internalWebpage?url=${getEnv(
                'FWA_BASE_URL',
              )}${issueListingPageURL}&title=${
                cropSchedule?.cropName
              }: ${getRegionalAppString('label_issues_and_diseases', language)}`
            : `${issueListingPageURL}`
        }
        onClick={() =>
          ClevertapService.pushEvent(`Button Clicked`, {
            Type: 'View All Issues',
            'Crop Name': cropSchedule?.category,
          })
        }
      >
        <FormattedMessage id="label_view_all_issues" />
      </ViewAllButton>
    </>
  );
}

export function IssueCard({
  match,
  search,
  issue,
  width = '240px',
  height = '180px',
}) {
  const query = useQuery();
  const appLinks = query.get('appLinks');
  const [isAndroid, setIsAndroid] = useState(null);

  useEffect(() => {
    if (AndroidService.checkIfAndroid()) {
      setIsAndroid(true);
    }
  }, []);

  const issueCardUi = (
    <Card elevation={0} sx={{ borderRadius: '8px' }}>
      <CardMedia
        component="img"
        image={issue?.image + '?tr=h-180'}
        alt="issues"
        sx={{ width: '100%', height }}
      />
      <Clamp lines={1} maxLines={1}>
        <Typography fontSize={14} sx={{ margin: '6px' }}>
          {issue?.issue}
        </Typography>
      </Clamp>
    </Card>
  );

  const handleIssueClickEvent = () => {
    ClevertapService.pushEvent(`Card Clicked`, {
      Type: 'Issue',
      'Issue Id': issue?.associationId,
    });
  };

  return appLinks === 'saathi' ? (
    <Link
      to={
        makeIssueHref(
          match,
          search,
          slugify(issue?.issue || ''),
          issue?.associationId,
        ) + '&showHeader=false&appLinks=saathi'
      }
      onClick={handleIssueClickEvent}
      style={{ minWidth: width, width }}
    >
      {issueCardUi}
    </Link>
  ) : isAndroid ? (
    <a
      href={makeAndroidIssueHref(issue?.issue, issue?.associationId)}
      onClick={handleIssueClickEvent}
      style={{ minWidth: width, width }}
    >
      {issueCardUi}
    </a>
  ) : (
    <Link
      to={makeIssueHref(
        match,
        search,
        slugify(issue?.issue || ''),
        issue?.associationId,
      )}
      onClick={handleIssueClickEvent}
      style={{ minWidth: width, width }}
    >
      {issueCardUi}
    </Link>
  );
}
