import React from 'react';
import { styled } from '@mui/material/styles';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

const BannerListWrapper = styled('div')({
  display: 'grid',
  width: '100%',
  margin: '10px 0',
  lineHeight: 0,
});

const BannerWrapperLink = styled(Link)({
  position: 'relative',
  img: {
    objectFit: 'fill',
  },
});

const BannerWrapperDiv = styled('div')({
  position: 'relative',
  img: {
    objectFit: 'fill',
  },
});

const Banners = ({ banner }) => {
  return (
    <BannerListWrapper
      sx={{ gridTemplateColumns: `repeat(${banner.columns}, 1fr)` }}
    >
      {banner.data.map(({ media: { type, url } }, index) => {
        if (!url) return null;

        return banner.data[index].href ? (
          <BannerWrapperLink
            key={url}
            to={banner.data ? banner.data[index].href : undefined}
            target={
              banner.data[index].type === 'EXTERNAL_WEB_PAGE'
                ? '_blank'
                : undefined
            }
          >
            {type === 'YOUTUBE' ? (
              <ReactPlayer
                width="100%"
                url={`https://www.youtube.com/watch?v=${url}`}
              />
            ) : (
              <img width="100%" src={url} alt={`banner_${index}`} />
            )}
          </BannerWrapperLink>
        ) : (
          <BannerWrapperDiv key={url}>
            {type === 'YOUTUBE' ? (
              <ReactPlayer
                width="100%"
                url={`https://www.youtube.com/watch?v=${url}`}
              />
            ) : (
              <img width="100%" src={url} alt={`banner_${index}`} />
            )}
          </BannerWrapperDiv>
        );
      })}
    </BannerListWrapper>
  );
};

export default Banners;
