import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

import Carousel from '../components/Carousel';
import { Link } from 'react-router-dom';

const BannerWrapper = styled(Link)({
  position: 'relative',
  img: {
    objectFit: 'fill',
  },
});

function CarouselBanner({ banner }) {
  return (
    <Carousel autoplay={false} autoPlayInterval={5000}>
      {banner.data.map(({ media: { url } }, index) => (
        <BannerWrapper
          key={index}
          to={banner.data ? banner.data[index].href : undefined}
          target={
            banner.data[index].type === 'EXTERNAL_WEB_PAGE'
              ? '_blank'
              : undefined
          }
        >
          <Box
            component="img"
            sx={{
              display: 'block',
              overflow: 'hidden',
              width: '100%',
            }}
            src={url}
            alt={`banner_${index}`}
          />
        </BannerWrapper>
      ))}
    </Carousel>
  );
}

export default CarouselBanner;
