import React from 'react';
import { withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Title } from '../components/Heading';
import ShowMore from '../components/ShowMore';
import PostPreview from './PostPreview';
import { changePage } from '../utilities/links';

function FarmersPost({ match, location: { search }, name, userPosts, page }) {
  return (
    <>
      <Title>
        {`${name}'s `}
        <FormattedMessage id="label_posts" />
        {` (${userPosts.totalCount})`}
      </Title>
      {userPosts?.posts?.slice(0, 20).map((post) => (
        // eslint-disable-next-line no-underscore-dangle
        <PostPreview post={post} key={post._id} />
      ))}
      <ShowMore
        href={changePage(match, search, page + 1)}
        hasMore={userPosts?.posts?.length > 20 && page < 5}
      />
    </>
  );
}

export default withRouter(FarmersPost);
