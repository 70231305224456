/*
 * For slugifying we just remove all 'non-word' characters. Originally this was \w
 * But to retain Indian language characters, we need a unicode way of specifying this:
 * p{L} is the character class, \p{M} is a modifer (matra)/ The u flag indicates unicode.
 * https://javascript.info/regexp-unicode
 */
export default function slugify(text) {
  return text
    ?.toString()
    ?.toLowerCase()
    ?.replace(/\s+/g, '-') // Replace spaces with -
    ?.replace(/[^\p{L}\p{M}0-9-]+/gu, '') // Remove all non-word chars
    ?.replace(/--+/g, '-') // Replace multiple - with single -
    ?.replace(/^-+/, '') // Trim - from start of text
    ?.replace(/-+$/, ''); // Trim - from end of text
}
