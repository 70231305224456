import LRUCache from 'lru-cache';

import apiCall from '../utilities/apiCall';
import { getRouteParams } from '../utilities/links';
import { getAppSource } from '../utilities/states';
import { getEnv } from '../utilities/getEnv';

/*
 * todo: split this into one file per service, eg, communication, content etc.
 * so that the caller can call it like content.getArticle() etc.
 */

export const featuredProducts = (match, search) => {
  const { language, state } = getRouteParams(match, search);
  const api = `${getEnv('FWA_API_URL')}/shopaggregator/v1/featuredproducts`;
  const url = new URL(api);
  url.searchParams.append('language', language);

  return apiCall(url.href, { Source: getAppSource(state) });
};

export const trendingProducts = (match, search, limit) => {
  const { language, state } = getRouteParams(match, search);
  const api = `${getEnv('FWA_API_URL')}/shopaggregator/v1/popularproducts`;
  const url = new URL(api);
  url.searchParams.append('language', language);
  url.searchParams.append('count', limit);
  return apiCall(url.href, { Source: getAppSource(state) });
};

export const featuredBrands = (match, search) => {
  const { language, state } = getRouteParams(match, search);
  const api = `${getEnv('FWA_API_URL')}/catalogservice/v2/feauredbrands`;
  const url = new URL(api);
  url.searchParams.append('language', language);
  const Source = getAppSource(state);
  return apiCall(url.href, { Source });
};

export const getBanners = (match, search) => {
  const { language, state } = getRouteParams(match, search);
  const api = `${getEnv(
    'FWA_API_URL',
  )}/contentservice/v1/banners?language=${language}&section=SHOP`;
  const url = new URL(api);
  return apiCall(url.href, { Source: getAppSource(state) });
};

export const getIssueDetails = (issueId, language) => {
  const api = `${getEnv('FWA_API_URL')}/shopaggregator/v1/issue/${issueId}`;
  const url = new URL(api);
  url.searchParams.append('language', language);
  return apiCall(url.href, { Source: 'APP' });
};

let productDetailsCache;

// Clear the cache (hours == undefined), or set the hours and clear the cache.
export function setProductCacheHours(hours) {
  if (hours == null) {
    console.log('Clearing product cache');
    if (productDetailsCache) productDetailsCache.reset();
  } else if (hours > 0) {
    console.log('Clearing product cache and setting hours to', hours);
    productDetailsCache = new LRUCache({
      // The JSON size of the result is about 20kb. Let's allocate some 100 Mb for this cache.
      max: 5000,
      maxAge: hours * 60 * 60 * 1000,
    });
  } else {
    console.log('Disabling product cache');
    productDetailsCache = undefined;
  }
}

// Product enable/disable needs to be reflected almost immediately,
// so let us set the product cache to 1 hour.
setProductCacheHours(1);

export async function getProductDetails(id, lang, state) {
  const source = getAppSource(state);
  const cacheKey = `${id}:${lang}:source`;
  if (productDetailsCache) {
    const cacheSize = productDetailsCache.length;
    const result = productDetailsCache.get(cacheKey);
    if (result) {
      console.log(`Product details cache hit: ${cacheKey} size: ${cacheSize}`);
      return result;
    }
    console.log(`Product details cache miss: ${cacheKey} size: ${cacheSize}`);
  }
  const api = `${getEnv('FWA_API_URL')}/catalogservice/v2/product/${id}`;
  const url = new URL(api);
  url.searchParams.append('language', lang);
  url.searchParams.append('limit', 5);
  const result = await apiCall(url.href, { Source: source });
  if (productDetailsCache) {
    // Cache it only if the fetch succeeds.
    if (result.responseData && result.responseData.productName) {
      productDetailsCache.set(cacheKey, result);
    }
  }
  return result;
}

export async function searchProductsBySearchTerm(searchTerm, lang, state) {
  const api = `${getEnv(
    'FWA_API_URL',
  )}/shopaggregator/v2/products?language=${lang}&searchString=${searchTerm}&pageNo=1`;
  return apiCall(api, { Source: getAppSource(state) });
}

export async function getProductsListBySKUs(skus, lang, state) {
  const source = getAppSource(state);
  const api = `${getEnv(
    'FWA_API_URL',
  )}/catalogservice/v2/products/?language=${lang}&sku=${skus}`;
  const url = new URL(api);
  const result = await apiCall(url.href, { Source: source });
  return result;
}

export const getAllBrands = (language, state) => {
  const api = `${getEnv('FWA_API_URL')}/catalogservice/v2/brand/`;
  const url = new URL(api);
  url.searchParams.append('language', language);
  return apiCall(url.href, {
    Source: getAppSource(state),
  });
};

// This is a permanent cache. If any brand is added, we need to restart the server.
const brandCache = {};

export const getBrandInfo = async (id, language) => {
  if (!brandCache[language]) {
    brandCache[language] = {};
    const response = await getAllBrands(language, 'APP');
    response.responseData.brands.forEach((brand) => {
      brandCache[language][brand.brandId] = brand;
    });
  }
  return brandCache[language][id];
};

export const getAllCrops = (language, state, limit = 100) => {
  const api = `${getEnv('FWA_API_URL')}/contentservice/v1/allcrops`;
  const url = new URL(api);
  url.searchParams.append('offset', 0);
  url.searchParams.append('limit', limit);
  url.searchParams.append('language', language);

  return apiCall(url.href, { Source: getAppSource(state) });
};

export const getCropInfo = (id, language) => {
  const api = `${getEnv(
    'FWA_API_URL',
  )}/shopaggregator/v2/cropconfiguration/${id}`;
  const url = new URL(api);
  url.searchParams.append('language', language);
  return apiCall(url.href, { Source: 'APP' });
};

export const getArticle = (id, language) => {
  const api = `${getEnv(
    'FWA_API_URL',
  )}/contentservice/v1/article/${id}/details`;
  const url = new URL(api);
  url.searchParams.append('language', language);
  return apiCall(url.href, { Source: 'APP' });
};

export const getProductDetailsWithRatings = (id, language, state) => {
  const api = `${getEnv('FWA_API_URL')}/shopaggregator/v1/productdetail/${id}`;
  const url = new URL(api);
  url.searchParams.append('language', language);
  return apiCall(url.href, { Source: getAppSource(state) });
};

export const getPaginatedStoreInventory = ({
  match,
  search,
  retailerId,
  offset = 0,
  limit = 10,
  prioritySkuCode,
}) => {
  const { lang, state, farmerId } = getRouteParams(match, search);
  let api = `${getEnv(
    'SHOPAGGREGATOR_SERVICE',
  )}/shopaggregator/saathi/productsinstore/?saathi_id=${retailerId}&limit=${limit}&offset=${offset}`;
  if (farmerId !== 'null' && farmerId !== null && farmerId?.length) {
    api += `&farmer_id=${farmerId}`;
  }
  if (
    prioritySkuCode !== 'null' &&
    prioritySkuCode !== null &&
    prioritySkuCode?.length
  ) {
    api += `&prioritySkuCode=${prioritySkuCode}`;
  }
  console.log(api);
  const url = new URL(api);
  url.searchParams.append('language', lang);
  return apiCall(url.href, {
    Source: getAppSource(state),
  });
};
