class AndroidService {
  static checkIfAndroid() {
    /*global Android */
    try {
      if (Android) {
        return true;
      } else {
        return false;
      }
    } catch (error) {
      console.log(`no android found.`);
      return false;
    }
  }

  static getAppVersion() {
    /*global Android */
    try {
      if (Android) {
        if (Android.getAppVersionCode()) {
          return Android.getAppVersionCode();
        } else {
          console.log(`error while finding android version`);
        }
      }
    } catch (error) {
      console.log(`no android found.`);
    }
  }

  static checkIfLatestVersion = () => {
    return AndroidService.getAppVersion() >= 183;
  };

  static shareOnWhatsApp(content, imageUrl) {
    /*global Android */
    try {
      if (Android) {
        if (AndroidService.checkIfLatestVersion()) {
          Android.shareOnWhatsApp(content, imageUrl);
        } else {
          console.log(`error while sharing on WhatsApp`);
        }
      }
    } catch (error) {
      console.log(error, `no android found.`);
    }
  }

  static shareOnWhatsAppWithoutVersionCheck(content, imageUrl) {
    /*global Android */
    try {
      if (Android) {
        Android.shareOnWhatsApp(content, imageUrl);
      }
    } catch (error) {
      console.log(`no android found.`, error);
    }
  }
}

export default AndroidService;
