/* eslint-disable no-underscore-dangle */
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Title } from '../components/Heading';
import store from '../utilities/store';
import addArticleSlugs from '../utilities/addArticleSlugs';
import apiCall from '../utilities/apiCall';
import { codeToTag } from '../utilities/tagsData';
import { getArticle } from '../services';

import Article from './Article';
import {
  getRouteParams,
  makeArticleHref,
  normalizeState,
} from '../utilities/links';
import slugify from '../utilities/slugify';
import { languages } from '../utilities/languages';
import { getStateBySlug, allStates } from '../utilities/states';
import SmallArticlePreview from './SmallArticlePreview';
import PageNotFound from '../misc/NotFoundPage';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { getEnv } from '../utilities/getEnv';

function ArticlePage({ setHeaderURLs, intl }) {
  const { data, loadingStatus, match, search } = useFetchData(
    ArticlePage,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;

  if (!data || !data.articleDetails || !data.relatedArticles) {
    return <PageNotFound />;
  }

  const { articleDetails, relatedArticles } = data;

  const { language, userstate } = getRouteParams(match, search);

  return (
    <>
      <Article
        article={articleDetails.article}
        likes={articleDetails.likes}
        lang={language}
        userstate={userstate}
      />
      {relatedArticles.length > 0 && (
        <>
          <Title>
            <FormattedMessage id="label_related_article" />
          </Title>
          {relatedArticles.map((post, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <SmallArticlePreview post={post} key={index} />
          ))}
        </>
      )}
    </>
  );
}

async function getRelatedArticles(article, lang) {
  const url = new URL(`${getEnv('FWA_API_URL')}/communicationservice/v2/post`);

  url.searchParams.append('entityType', 'article');
  url.searchParams.append('tags', await codeToTag(article.tag_list[0]));
  url.searchParams.append('offset', 0);
  url.searchParams.append('limit', 5);
  url.searchParams.append('language', lang);
  url.searchParams.append('curated', 'false');

  const result = await apiCall(url.href, { Source: 'APP' });
  const articles = result.responseData.posts || [];
  const articlesExcludingSelf = articles.filter(
    (a) => a.parent_article_id !== article._id,
  );
  return articlesExcludingSelf;
}

ArticlePage.fetchData = async (match, search) => {
  const { id, language, state } = getRouteParams(match, search);
  const result = await getArticle(id, language);

  const articleDetails = result.responseData;
  const { article } = articleDetails;

  if (!article) {
    return {
      titleId: 'post_article_not_found',
      languageURLs: 'home',
      stateURLs: 'home',
    };
  }

  // We populate the tags values here so that we don't need do it during render
  for (let i = 0; i < article.tags.length; i += 1) {
    // codeToTag is really not an async function except the first time it's called
    // eslint-disable-next-line no-await-in-loop
    article.tags[i].value = await codeToTag(article.tags[i].code);
    article.tags[i].label = article.tags[i].heading;
  }

  const relatedArticles = await getRelatedArticles(article, language);
  await addArticleSlugs(relatedArticles);

  const initialData = { articleDetails, relatedArticles };

  // State and language URLs for the menus
  // Get all supported languages' slugs and links
  const languageSlugs = {};
  const jobs = [];
  if (articleDetails.languageWiseHeadings) {
    languages.forEach((lang) => {
      if (articleDetails.languageWiseHeadings[lang]) {
        languageSlugs[lang] = slugify(
          articleDetails.languageWiseHeadings[lang],
        );
      }
    });
  } else {
    languages.forEach((lang) => {
      if (article[`flag_${lang}`]) {
        const p = (async (l) => {
          const a = await getArticle(id, l);
          if (a.responseData.article) {
            languageSlugs[l] = slugify(a.responseData.article.heading);
          }
        })(lang);
        jobs.push(p);
      }
    });
    await Promise.all(jobs);
  }

  // Language URLs - for all languages that this state supports, there will be a language link
  const languageURLs = {};
  getStateBySlug(state).supportedLanguages.forEach((lang) => {
    const slug = languageSlugs[lang];
    if (slug) {
      languageURLs[lang] = makeArticleHref(match, search, slug, id, lang);
    } else {
      languageURLs[lang] = 'languageNa';
    }
  });

  // State URLs - for all states, there will be a state link.
  let stateURLs = {};
  if (language === 'en') {
    // If current language is en, change only the state, nothing more to do
    // This is because en is applicable to all states.
    stateURLs = null;
  } else {
    // For each state, find the first supported language that is available for this article
    allStates.forEach((s) => {
      const stateLang = s.supportedLanguages.find(
        (l) => languageSlugs[l] != null,
      );
      if (stateLang) {
        stateURLs[s.slug] = makeArticleHref(
          match,
          search,
          languageSlugs[stateLang],
          id,
          stateLang,
          s.slug,
        );
      } else {
        stateURLs[s.slug] = 'languageNa';
      }
    });
  }

  const titleId = 'title_article';
  const titleParams = { heading: article.heading };
  const canonical = normalizeState(match, search);
  const imageMedia = article.media.find((m) => m.type === 'image');
  const imageUrl = imageMedia ? imageMedia.url : undefined;
  const ogTagData = {
    description: article.synopsis,
    imageUrl,
    type: 'article',
  };

  return {
    initialData,
    titleId,
    titleParams,
    seoData: { ogTagData },
    canonical,
    languageURLs,
    stateURLs,
  };
};

export default injectIntl(ArticlePage);
