import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import Heading from '../components/Heading';
import { makeCategoryHref } from '../utilities/links';
import slugify from '../utilities/slugify';

const CategoryCardWrapper = styled(Link)({
  position: 'relative',
  minWidth: '50px',
  flex: '1',
  padding: '2%',
  marginLeft: '1%',
  marginBottom: '1%',
  border: '1px solid #cccccc',
  borderRadius: '4px',
  background: '#ffffff',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  '&:nth-child(1)': {
    marginLeft: '0',
  },
});

export const ImageWrapper = styled('div')({
  position: 'relative',
  width: '100%',
  img: {
    width: '100%',
  },
});

export const CategoryListWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
});

const CategoryCard = ({
  match,
  location: { search },
  category: { label, value, url },
}) => {
  const id = slugify(value);
  const href = makeCategoryHref(match, search, slugify(label), id);
  return (
    <>
      <CategoryCardWrapper to={href}>
        {url && (
          <ImageWrapper>
            <img src={url} alt={value} />
          </ImageWrapper>
        )}
        <Heading sx={{ padding: 0, whiteSpace: 'nowrap' }}>{label}</Heading>
      </CategoryCardWrapper>
    </>
  );
};

export default withRouter(CategoryCard);
