import React from 'react';
import { injectIntl } from 'react-intl';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { useFetchData } from '../services/useFetchData';
import { getEnv } from '../utilities/getEnv';
import store from '../utilities/store';

import ListPage from './ProductListBase';

function TrendingListPage({ setHeaderURLs, intl }) {
  const { data, loadingStatus } = useFetchData(
    TrendingListPage,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;
  return <ListPage data={data} titleId="label_featured_in_region" />;
}

TrendingListPage.fetchData = async (match, search) => {
  return ListPage.fetchData(
    match,
    search,
    `${getEnv('FWA_API_URL')}/shopaggregator/v1/featuredproducts`,
  );
};

export default injectIntl(TrendingListPage);
