import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
} from '@mui/material';
import { Box } from '@mui/system';

import store from '../utilities/store';
import apiCall from '../utilities/apiCall';
import ProductHeader from './ProductHeader';
import useQuery from '../utilities/useQuery';
import { getEnv } from '../utilities/getEnv';
import { Loader } from '../components/Loader';
import ProductCarousel from './ProductCarousel';
import { getRouteParams } from '../utilities/links';
import ServerError from '../components/ServerError';
import { useFetchData } from '../services/useFetchData';
import { getProductDetailsWithRatings } from '../services';
import BenefitUseTestimonial from './BenefitUseTestimonial';
import { getSaathiStore } from '../services/saathiStoreService';
import { getStateBySlug } from '../utilities/states';

const PageWrapper = styled('div')({ background: '#e5e5e5' });
const ProductKeyPoints = styled('div')({ background: '#fff' });
const ProductInformation = styled('div')({
  marginBottom: '12px',
  background: '#fff',
});

function ProductQRScannedPage({ setHeaderURLs, intl }) {
  const { data, loadingStatus, match, search } = useFetchData(
    ProductQRScannedPage,
    store,
    setHeaderURLs,
    intl,
  );
  const query = useQuery();
  const history = useHistory();
  const { barcode } = getRouteParams(match, search);
  const [productInformation, setProductInformation] = useState(null);

  useMemo(() => {
    try {
      const pathname = match?.pathname;
      const slug = data.store.state.split(' ')?.join('-');
      const lang = getStateBySlug(slug).language;
      if (
        pathname.toLowerCase().includes('/pcode') &&
        query.get('state') !== slug &&
        query.get('language') !== lang
      ) {
        query.set('state', slug);
        query.set('showHeader', 'false');
        query.set('language', lang);
        const redirectToURL = pathname + '?' + query.toString();
        history.replace(redirectToURL);
      }
    } catch (e) {
      console.log('Failed to Redirect: ', e?.message);
    }
  }, []);

  useEffect(() => {
    if (match?.pathname.toLowerCase().includes('/pcode')) {
      const attributes = [
        {
          key: intl.formatMessage({ id: `lable_product_name` }),
          value: data?.productName,
        },
        {
          key: intl.formatMessage({ id: `lable_serial_no` }),
          value: barcode,
        },
        {
          key: intl.formatMessage({ id: `lable_lot_no` }),
          value: data?.lotNumber,
        },
        {
          key: intl.formatMessage({ id: `lable_expiry_date` }),
          value: moment(new Date(data?.expiryDate).getTime()).format(
            'DD-MM-YYYY',
          ),
        },
        {
          key: intl.formatMessage({ id: `lable_manufacturing_date` }),
          value: data?.manufacturingDate,
        },
      ];

      setProductInformation(attributes);
    }
  }, []);

  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error' || !data) return <ServerError />;

  return (
    <PageWrapper>
      <Box sx={{ mb: 1, p: 2, background: '#fff' }}>
        <img src="/congratulations.png" alt="congratulations" width="100%" />
        <Typography sx={{ p: '0 20px', textAlign: 'center' }}>
          {data?.store ? (
            <FormattedMessage
              id="label_bought_genuine_product_from"
              values={{ storeName: data?.store?.storeName }}
            />
          ) : (
            <FormattedMessage id="label_bought_genuine_product" />
          )}
        </Typography>
      </Box>

      <ProductHeader product={data} />
      <ProductCarousel media={data?.media} />
      <ProductInformation>
        <TableContainer component={'div'}>
          <Table aria-label="simple table">
            <TableBody>
              {productInformation?.map(({ key, value }, index) =>
                value ? (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      <b style={{ fontSize: '12px' }}>{key}</b>
                    </TableCell>
                    <TableCell
                      sx={{
                        borderLeft: '1px solid #F3F3F3;',
                        fontSize: '12px',
                        lineHeight: '16px',
                      }}
                    >
                      {value}
                    </TableCell>
                  </TableRow>
                ) : null,
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </ProductInformation>
      <Box sx={{ padding: '0 8px' }}>
        <BenefitUseTestimonial product={data} />
      </Box>
      <ProductKeyPoints>
        <Typography sx={{ fontWeight: '700', fontSize: '14px', p: 2, pb: 0 }}>
          {data?.details?.[0]?.label}
        </Typography>
        <TableContainer component={'div'}>
          <Table aria-label="simple table">
            <TableBody>
              {data?.details?.[0]?.attributes.map((attribute, index) => (
                <TableRow key={index}>
                  <TableCell component="th" scope="row">
                    <b style={{ fontSize: '12px' }}>{attribute.key}</b>
                  </TableCell>
                  <TableCell
                    sx={{
                      borderLeft: '1px solid #F3F3F3;',
                      fontSize: '12px',
                      lineHeight: '16px',
                    }}
                  >
                    {attribute.value}
                  </TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell component="th" scope="row">
                  <b style={{ fontSize: '12px' }}>
                    <FormattedMessage id="other" />
                  </b>
                </TableCell>
                <TableCell
                  sx={{
                    fontSize: '12px',
                    lineHeight: '16px',
                    textAlign: 'center',
                    borderLeft: '1px solid #F3F3F3;',
                  }}
                >
                  {data?.toxicityColour && (
                    <img
                      src={`/images/Toxicity_${data?.toxicityColour}.svg`}
                      alt="toxicity"
                      width="100px"
                    />
                  )}
                  <p style={{ textAlign: 'left' }}>
                    <FormattedMessage id="label_for_batch_and_expiry" />
                  </p>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </ProductKeyPoints>
    </PageWrapper>
  );
}

ProductQRScannedPage.fetchData = async (match, search) => {
  const { id, hash, barcode, language, state } = getRouteParams(match, search);

  let initialData = {};
  if (hash && barcode) {
    const result = await apiCall(
      `${getEnv(
        'ONLINE_ECOMMERCE_SERVICE',
      )}/onlineecommerceservice/v2/barcodescan/productandretailerdetails/`,
      { source: 'APP' },
      { hashcode: Number(hash), barcode },
    );

    if (!result?.responseData) {
      return 'invalid hashcode and barcode';
    }

    initialData = { ...result?.responseData };

    if (initialData?.isB2BOrder) {
      const storeResponse = await getSaathiStore(
        initialData?.retailerId,
        state,
      );

      if (storeResponse?.responseData?.[0]) {
        initialData = {
          ...initialData,
          store: storeResponse?.responseData?.[0],
        };
      }
    }
  }

  const productResponse = await getProductDetailsWithRatings(
    initialData?.skuCode || id,
    language,
    state,
  );
  if (
    !productResponse?.responseData ||
    !productResponse?.responseData?.productName
  ) {
    return {
      titleId: 'label_no_product_available',
      languageURLs: 'home',
      stateURLs: 'home',
    };
  }

  return {
    initialData: { ...initialData, ...productResponse?.responseData },
    titleId: 'app_name',
  };
};

export default injectIntl(ProductQRScannedPage);
