import React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';

const CorporateBarWrapper = styled('div')({
  backgroundColor: '#902123',
  color: '#fff',
  fontSize: '1.4em',
  padding: '10px 25px',
  display: 'flex',
  alignItems: 'center',
  '@media (max-width: 375px)': {
    fontSize: '1.1em',
  },
});

function CorporateBar() {
  return (
    <a href="https://corporate.agrostar.in" target="_blank">
      <CorporateBarWrapper>
        Click here for our corporate website&nbsp;
        <ArrowForwardRoundedIcon />
      </CorporateBarWrapper>
    </a>
  );
}

export default CorporateBar;
