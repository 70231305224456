import React from 'react';
import { styled } from '@mui/material/styles';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import ReactPlayer from 'react-player';
import { Link } from 'react-router-dom';

/*
 * To maintain aspect ratio and also to fill the width of the screen, we need
 * to create a div which is of needed dimensions, and then, create the img
 * inside it which fills the container and also has a style object-fit: cover.
 * This ensures that the size of the container doesn't change even after loading
 * the image.
 */

const PostImageContainer = styled('div')({
  position: 'relative',
  width: '100%',
  height: '400px',
  img: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
});

// Articles are typically 16:9, but aspectRatio is not supported
// in all browsers. So we try other tricks.
const ArticleImageContainer = styled('div')({
  position: 'relative',
  width: '100%',
  height: '56vw',
  maxHeight: '340px', // on a desktop, viewport is too large.
  img: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
});

const PlayIcon = styled(PlayArrowRoundedIcon)({
  position: 'absolute',
  zIndex: '2',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  opacity: '0.9',
  fontSize: 100,
  color: 'white',
});

function PostImage({
  imageUrl,
  youtubeId,
  href,
  playIcon,
  alt = '',
  entityType = 'post',
}) {
  if (!imageUrl && !youtubeId) return null;
  const Container =
    entityType === 'article' ? ArticleImageContainer : PostImageContainer;
  let image;
  if (youtubeId) {
    image = (
      <ReactPlayer
        url={`https://www.youtube.com/watch?v=${youtubeId}`}
        width="100%"
        height="100%"
      />
    );
  } else {
    image = <img src={imageUrl} alt={alt} />;
    if (playIcon) {
      image = (
        <>
          <PlayIcon />
          {image}
        </>
      );
    }
  }

  const content = <Container>{image}</Container>;

  return href ? <Link to={href}>{content}</Link> : content;
}

export default PostImage;
