import React from 'react';
import { styled } from '@mui/material/styles';
import { Link, withRouter } from 'react-router-dom';

import { makeListingHref } from '../utilities/links';
import { tagToSlug } from '../utilities/tagsData';

const TagsContainer = styled('div')({
  margin: '15px 0px',
  display: 'flex',
  flexWrap: 'wrap',
  a: {
    margin: '5px 0px',
    cursor: 'pointer',
  },
  span: {
    border: '1px solid silver',
    borderRadius: '12px',
    color: '#666',
    fontWeight: 'bold',
    padding: '2px 7px',
    margin: '0px 2px',
  },
});

function Tags({ location: { search }, match, tags }) {
  return (
    <TagsContainer>
      {tags.map((tag) => (
        <Link
          key={tagToSlug(tag.value)}
          to={makeListingHref(match, search, 'articles', tagToSlug(tag.value))}
        >
          <span>{tag.label}</span>
        </Link>
      ))}
    </TagsContainer>
  );
}

export default withRouter(Tags);
