import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import apiCall from '../utilities/apiCall';
import store from '../utilities/store';
import { getRouteParams, makeShopNotAvailableHref } from '../utilities/links';
import { getBanners } from '../services';
import ProductCard, { ProductListWrapper } from './ProductCard';
import LayoutWrapper from '../components/Layout';
import { Title } from '../components/Heading';
import { isAgriShopEnabled, getAppSource } from '../utilities/states';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { getEnv } from '../utilities/getEnv';

const BannerProductListPage = ({ setHeaderURLs, intl }) => {
  const { data, loadingStatus } = useFetchData(
    BannerProductListPage,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;
  const { products } = data;

  if (!products || products.length === 0) {
    return <FormattedMessage id="no_products_found_title" />;
  }

  return (
    <LayoutWrapper>
      <Title />
      <ProductListWrapper>
        {products.map((p) => (
          <ProductCard key={p.skuCode} product={p} />
        ))}
      </ProductListWrapper>
    </LayoutWrapper>
  );
};

BannerProductListPage.fetchData = async (match, search) => {
  const { id, language, state } = getRouteParams(match, search);
  if (!isAgriShopEnabled(state)) {
    return { redirectURL: makeShopNotAvailableHref(match, search, state) };
  }
  const titleId = 'shop_home';
  const [bannerCode, index] = id.split('.');

  // We don't seem to have a single banner API.
  const banners = await getBanners(match, search);
  const banner = banners.responseData.banners.find(
    (b) => b.code === bannerCode,
  );
  if (!banner) {
    console.error('Banner not found:', bannerCode);
    return { initialData: { products: [] }, titleId, seoData: {} };
  }

  const skus = banner.data[index].data.skus.join(',');
  const api = `${getEnv('FWA_API_URL')}/shopaggregator/v1/products?sku=${skus}`;
  const url = new URL(api);
  url.searchParams.append('language', language);
  const response = await apiCall(url.href, { Source: getAppSource(state) });

  const initialData = { products: response.responseData.productGists };

  return {
    initialData,
    titleId,
    stateURLs: 'shop',
    seoData: {},
    canonical: 'nstate',
  };
};

export default injectIntl(BannerProductListPage);
