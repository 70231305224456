export const TAB_ORDER_MAPPING = {
  Articles: {
    labelKey: 'label_agri_updates',
    key: 'articles',
    icon: '/category-images/seed.png',
  },
  Seeds: {
    labelKey: 'label_seeds',
    key: 'seeds',
    icon: '/category-images/seed.png',
  },
  Issues: {
    labelKey: 'label_issue',
    key: 'issues',
    icon: '/category-images/cp.png',
  },
};

export const TAB_KEY_MAPPING = {
  articles: 'Articles',
  seeds: 'Seeds',
  issues: 'Issues',
};
