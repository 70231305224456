import React from 'react';
import { styled } from '@mui/material/styles';
import { Link, withRouter } from 'react-router-dom';

import Author from './Author';
import Tags from './Tags';
import PostImage from './PostImage';
import ActivityCountBar from './ActivityCountBar';
import { makePostHref, makeArticleHref } from '../utilities/links';
import PreviewText from './PreviewText';

const PreviewContents = styled('div')({
  padding: '15px',
  header: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '12px',
  },
  '.type': {
    fontSize: '16px',
    marginTop: '8px',
  },
});

const PostPreviewWrapper = styled('div')({
  backgroundColor: '#ffffff',
  marginBottom: '10px',
});

function PostPreview({ post, match, location: { search } }) {
  const dataValues = {};
  post.data.forEach((d) => {
    dataValues[d.type] = d.value;
  });

  const href =
    post.entityType === 'article'
      ? makeArticleHref(match, search, post.slug, post.parent_article_id)
      : makePostHref(match, search, post._id); // eslint-disable-line no-underscore-dangle
  // todo: Newlines need to be maintained within the text

  let articleType = dataValues.article_type;
  if (articleType) {
    articleType = `${articleType} \u00A0| \u00A0${dataValues.article_source}`;
  }

  const { likes_count: likes, shareCount: shares } = post;
  const { dislikes_count: dislikes, comments_count: comments } =
    post.entityType === 'article' ? {} : post;

  let imageUrl = dataValues.image;
  let playIcon = false;
  if (dataValues.youtube) {
    const youtubeId = dataValues.youtube.substr(
      dataValues.youtube.indexOf('=') + 1,
    );
    imageUrl = `https://img.youtube.com/vi/${youtubeId}/0.jpg`;
    playIcon = true;
  }

  return (
    <PostPreviewWrapper>
      <PreviewContents>
        <Author author={post.author} date={post.server_epoch} />
        <Tags tags={post.tags} />
      </PreviewContents>
      <PostImage
        alt={dataValues.heading || post.author}
        imageUrl={imageUrl}
        href={href}
        playIcon={playIcon}
        entityType={post.entityType}
      />
      <Link to={href}>
        <PreviewContents>
          {dataValues.heading ? <header>{dataValues.heading}</header> : null}
          <PreviewText text={dataValues.synopsis || dataValues.text} />
          {articleType ? <div className="type">{articleType}</div> : null}
          <ActivityCountBar
            likes={likes}
            dislikes={dislikes}
            comments={comments}
            shares={shares}
          />
        </PreviewContents>
      </Link>
    </PostPreviewWrapper>
  );
}
export default withRouter(PostPreview);
