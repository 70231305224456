import React, { useState } from 'react';

import { styled } from '@mui/system';
import { ArrowForwardIosSharp } from '@mui/icons-material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

const IconWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '32px',
  minWidth: '32px',
  height: '32px',
  background: 'rgb(157, 33, 35, 0.2)',
  borderRadius: '50%',
});
const TitleWrapper = styled(Typography)({
  fontSize: '15px',
  fontWeight: 500,
  marginLeft: '12px',
});
const SubTextWrapper = styled('div')({
  fontSize: '14px',
  fontWeight: 500,
  marginLeft: '44px',
  color: '#666',
});
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: '5px',
  '&:before': {
    display: 'none',
  },
}));
const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={<ArrowForwardIosSharp sx={{ fontSize: '0.9rem' }} />}
  />
))({
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
});
const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function AccordionTemplate({ title, subText, styles }) {
  const [expanded, setExpanded] = useState(false);
  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      sx={styles}
    >
      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        {title}
      </AccordionSummary>
      <AccordionDetails>{subText}</AccordionDetails>
    </Accordion>
  );
}

export default AccordionTemplate;

export function TaskDetailAccordianSection({ icon, title, subText, other }) {
  return (
    <MuiAccordion
      sx={{
        width: '100%',
        boxShadow: 'none',
        '&::before': { backgroundColor: 'transparent' },
        '&.Mui-expanded': {
          margin: '0px !important',
        },
      }}
    >
      <MuiAccordionSummary
        id="panel1a-header"
        aria-controls="panel1a-content"
        expandIcon={<img src="/icons/expandMoreIcon.svg" />}
        sx={{
          minHeight: '50px !important',
          maxHeight: '50px',
          borderTop: '0.4px solid #B1B4B4',
          '.MuiAccordionSummary-content': {
            display: 'flex',
            alignItems: 'center',
          },
        }}
      >
        <IconWrapper>{icon}</IconWrapper>
        <TitleWrapper>{title}</TitleWrapper>
      </MuiAccordionSummary>

      <MuiAccordionDetails sx={{ padding: '8px 16px 8px' }}>
        <SubTextWrapper>{subText}</SubTextWrapper>
        {other ? other : ''}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
}
