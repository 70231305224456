import React from 'react';
import { styled } from '@mui/material/styles';
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import WhatsappOutlinedIcon from '@mui/icons-material/WhatsappOutlined';

const ActivityCountBarDiv = styled('div')({
  position: 'relative',
  width: '100%',
  marginTop: '12px',
});

const ActivityCountBox = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  span: {
    marginLeft: '5px',
    verticalAlign: 'middle',
  },
});

function ActivityCount({ icon, count, width }) {
  if (count === undefined) return <ActivityCountBox />;
  return (
    <ActivityCountBox sx={{ width }}>
      {icon}
      <span>{count}</span>
    </ActivityCountBox>
  );
}

function ActivityCountBar({
  likes,
  comments,
  shares,
  display,
  justify,
  width = '33%',
  align,
}) {
  return (
    <ActivityCountBarDiv
      sx={{ displayType: display, justify, alignItems: align }}
    >
      <ActivityCount
        icon={<ThumbUpOutlinedIcon sx={{ color: '#ff8b00' }} />}
        count={likes}
        width={width}
      />
      <ActivityCount
        icon={<ChatBubbleOutlineOutlinedIcon sx={{ color: '#006699' }} />}
        count={comments}
        width={width}
      />
      <ActivityCount
        icon={<WhatsappOutlinedIcon sx={{ color: '#00733e' }} />}
        count={shares}
        width={width}
      />
    </ActivityCountBarDiv>
  );
}

export default ActivityCountBar;
