import React from 'react';
import { styled } from '@mui/material/styles';

import Author from './Author';
import Comments from './Comments';
import Tags from './Tags';
import PostImage from './PostImage';
import ActivityCountBar from './ActivityCountBar';
// import Share from '../../../components/Share';

const PostContents = styled('div')({
  padding: '15px 5px',
  p: {
    margin: '15px 0px',
  },
});

const PostTitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
});

function Post({ post, comments }) {
  const dataValues = {};
  post.data.forEach((d) => {
    dataValues[d.type] = d.value;
  });

  const { likes_count: numLikes, dislikes_count: numDislikes } = post;
  const { comments_count: numComments, shareCount: numShares } = post;
  const youtubeId = dataValues.youtube
    ? dataValues.youtube.substr(dataValues.youtube.indexOf('=') + 1)
    : null;

  return (
    <div>
      <PostImage
        alt={dataValues.author}
        imageUrl={dataValues.image}
        youtubeId={youtubeId}
      />
      <PostContents>
        <PostTitleWrapper>
          <Author author={post.author} date={post.server_epoch} />
          {/* <Share /> TODO */}
        </PostTitleWrapper>
        <p>{dataValues.text}</p>
        <Tags tags={post.tags} />
        <ActivityCountBar
          likes={numLikes}
          dislikes={numDislikes}
          comments={numComments}
          shares={numShares}
        />
      </PostContents>
      <Comments comments={comments} />
    </div>
  );
}

export default Post;
