import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Footer from '../components/Footer';
import { styled } from '@mui/material/styles';

import {
  makeListingHref,
  makeTopicsHref,
  getRouteParams,
  makeShopHref,
} from '../utilities/links';
import { isAgriShopEnabled } from '../utilities/states';

const ContentWrapper = styled('div')({
  height: 'calc(100vh - 250px)',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  textAlign: 'center',
});

const Header = styled('div')({
  fontSize: '18px',
  color: '#000000',
  textAlign: 'center',
});

const SubHeader = styled('div')({
  fontSize: '16px',
  color: '#000000',
  textAlign: 'center',
});

const BottomFixedFooter = styled('div')({
  position: 'fixed',
  bottom: '0',
  maxWidth: '600px',
});

function MessagePage({ match, location: { search }, image, messageId }) {
  const { language, state } = getRouteParams(match, search);
  return (
    <div>
      <ContentWrapper>
        <img
          height="129"
          width="161"
          alt="Unavailable"
          layout="fixed"
          src={image}
        />
        <br />
        <div
          style={{
            width: '70%',
          }}
        >
          <Header>
            <FormattedMessage id={messageId} />
          </Header>
        </div>
        <br />

        <SubHeader>
          <FormattedMessage id="label_view_more" />
        </SubHeader>
        <br />
        <div style={{ textAlign: 'left' }}>
          <li style={{ color: 'green' }}>
            <Link to={makeListingHref(match, search, 'articles')}>
              <FormattedMessage id="label_agri_updates" />
            </Link>
          </li>
          <li style={{ color: 'green' }}>
            <Link to={makeListingHref(match, search, 'posts', null, 'popular')}>
              <FormattedMessage id="label_popular_posts" />
            </Link>
          </li>
          <li style={{ color: 'green' }}>
            <Link to={makeTopicsHref(match, search)}>
              <FormattedMessage id="label_popular_topics" />
            </Link>
          </li>
          {isAgriShopEnabled(state) ? (
            <li style={{ color: 'green' }}>
              <Link to={makeShopHref(match, search)}>
                <FormattedMessage id="shop_home" />
              </Link>
            </li>
          ) : null}
        </div>
      </ContentWrapper>

      <BottomFixedFooter>
        <Footer lang={language} />
      </BottomFixedFooter>
    </div>
  );
}

export default withRouter(MessagePage);
