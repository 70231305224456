import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import Heading from '../components/Heading';
import { makeIssueHref } from '../utilities/links';
import slugify from '../utilities/slugify';

const IssueCardWrapper = styled(Link)({
  position: 'relative',
  padding: '1%',
  marginRight: '1%',
  marginBottom: '1%',
  border: '1px solid #cccccc',
  borderRadius: '4px',
  background: '#ffffff',
  flex: '1 0 45%',
  img: {
    objectFit: 'contain',
  },
  '&:nth-last-child(1):nth-child(odd)': {
    flex: '0 0 45%',
  },
  '&:nth-child(even)': {
    marginLeft: '1%',
  },
});

export const IssueListWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  marginRight: '-1%',
});

export const Title = styled(Heading)({
  textAlign: 'center',
});

const IssueCard = ({
  match,
  location: { search },
  issue: { image, associationId, cropName, issue = 'no issue' },
}) => {
  // Attach the crop name to the issue because it is relevant. Issues belong
  // to crops even though similar issues may appear in different crops.
  const slug = slugify(`${cropName} ${issue}`);
  const href = makeIssueHref(match, search, slug, associationId);
  return (
    <IssueCardWrapper to={href}>
      <img src={image} alt={issue} width="100%" />
      <Title>{issue}</Title>
    </IssueCardWrapper>
  );
};

export default withRouter(IssueCard);
