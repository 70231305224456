import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid, Typography } from '@mui/material';

function ServerError({
  text,
  image = '/notFound.svg',
  textId = 'error_all_api_fail',
}) {
  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      sx={{ minHeight: '80vh', padding: '20px 40px', textAlign: 'center' }}
    >
      <Grid item xs={8}>
        <img src={`${image}`} alt="empty" />
      </Grid>
      <Grid item xs={8}>
        <Typography variant="subtitle2" fontSize={14} fontWeight={700}>
          {text ? text : <FormattedMessage id={textId} />}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ServerError;
