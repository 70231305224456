import React from 'react';
import { styled } from '@mui/material/styles';

const LayoutWrapper = styled('div')({
  margin: '10px',
  backgroundColor: '#f1f1f1',
});

function Layout({ children }) {
  return <LayoutWrapper>{children}</LayoutWrapper>;
}

export default Layout;
