import strings from './strings';
import { createIntl, createIntlCache } from 'react-intl';

export function getRegionalAppString(id, language) {
  const intlCache = createIntlCache();
  const intl = createIntl(
    {
      locale: 'en',
      messages: strings[language],
    },
    intlCache,
  );

  return intl?.formatMessage({ id });
}
