import React from 'react';
import { withRouter } from 'react-router-dom';

import apiCall from '../utilities/apiCall';
import { getAppSource } from '../utilities/states';
import addArticleSlugs from '../utilities/addArticleSlugs';

import ShowMore from '../components/ShowMore';
import PostPreview from '../charcha/PostPreview';
import { getRouteParams, changePage } from '../utilities/links';
import { getEnv } from '../utilities/getEnv';

const ArticlesTab = ({ match, location: { search }, tabData, page }) => {
  const { response } = tabData;

  if (!response || !response?.posts) {
    return [];
  }
  return (
    <>
      {response.posts.slice(0, 20).map((post) => (
        <PostPreview post={post} key={post._id} />
      ))}
      <ShowMore
        href={changePage(match, search, page + 1)}
        hasMore={response.posts.length > 20 && page < 5}
      />
    </>
  );
};

async function getArticles(tagId, page, userState, lang) {
  const offset = (page - 1) * 20;
  const url = new URL(`${getEnv('FWA_API_URL')}/communicationservice/v2/post`);
  url.searchParams.append('entityType', 'article');
  url.searchParams.append('tags', tagId);
  url.searchParams.append('offset', offset);
  url.searchParams.append('limit', 21);
  url.searchParams.append('language', lang);
  url.searchParams.append('curated', 'false');

  return apiCall(url.href, { Source: getAppSource(userState) });
}

ArticlesTab.fetchData = async (match, search, response) => {
  const {
    articleData: { tagId },
  } = response;

  const { language, state, page } = getRouteParams(match, search);
  let result = {};

  result = await getArticles(tagId, page, state, language);
  const {
    responseData: { posts },
  } = result;
  await addArticleSlugs(posts);

  return { response: result.responseData || [] };
};

export default withRouter(ArticlesTab);
