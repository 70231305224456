// Policies - applicable to all states
import PolicyPage from '../misc/PolicyPage';

// // Articles and posts - applicable to all states
import ListingPage from '../charcha/ListingPage';
import ProfilePage from '../charcha/ProfilePage';
import PostPage from '../charcha/PostPage';
import ArticlePage from '../charcha/ArticlePage';

// // Shop related list pages - applicable to shop states only
import ShopPage from '../shop/ShopPage';
import BrandListPage from '../shop/BrandListPage';
import CropListPage from '../crop/CropListPage';
import ProductListPage from '../shop/ProductListPage';
import TrendingProductsPage from '../shop/TrendingProductsPage';
import FeaturedProductsPage from '../shop/FeaturedProductsPage';
import BannerProductsPage from '../shop/BannerProductsPage';
import ProductQRScannedPage from '../product/ProductQRScannedPage';

// // Shop related item pages - applicable to shop states only, with slug
import ProductPage from '../product/ProductPage';
import CategoryPage from '../shop/CategoryProductsPage';
import BrandPage from '../shop/BrandProductsPage';
import CropPage from '../crop/CropPage';
import IssuePage from '../crop/IssuePage';
import BuyNowPage from '../product/BuyNowPage';
import IssueListingPage from '../crop/IssueListingPage';
import SearchResults from '../components/SearchResults';

// Checkout related pages
import Cart from '../cart/Cart';
import Checkout from '../checkout/Checkout';
import SignIn from '../checkout/SignIn';
import AddressForm from '../checkout/AddressForm';

// Agrostar Store related pages
import StoreFront from '../saathi-store/StoreFront';

// Crop Schedule related pages
import CropAttributes from '../crop/CropAttributes';
import SelectYourCrops from '../crop/SelectYourCrops';
import CropInfoPage from '../crop-schedule/CropInfoPage';
import ExpertVideos from '../crop-schedule/ExpertVideos';
import TaskListingPage from '../crop-schedule/TaskListingPage';

// Error and Redirect pages
import PageNotFound from '../misc/NotFoundPage';
import ArticleNotAvailable from '../misc/ArticleNaPage';
import ShopNotAvailable from '../misc/ShopNaPage';
import SuccessPage from '../misc/SuccessPage';

export const routes = [
  // Policies
  {
    path: '/policy/:type(return-refund|terms-conditions)',
    component: PolicyPage,
  },
  // Charcha - lists
  { path: '/:type(articles|posts|postlist|stories)', component: ListingPage },
  // Charcha - entities
  { path: '/post/:id', component: PostPage },
  { path: '/article/:slug/:id', component: ArticlePage },
  { path: '/profile/:id', component: ProfilePage },
  // Shop - lists
  { path: `/shop`, component: ShopPage },
  { path: `/search`, component: SearchResults },
  { path: `/brands`, component: BrandListPage },
  { path: `/crops`, component: CropListPage },
  { path: `/products`, component: ProductListPage },
  { path: `/bannerproducts/:id`, component: BannerProductsPage },
  { path: '/trending-products', component: TrendingProductsPage },
  { path: '/featured-products', component: FeaturedProductsPage },
  // Shop - entities
  { path: `/product/:slug/:id`, component: ProductPage },
  { path: `/buynow/:slug/:id`, component: BuyNowPage },
  { path: `/brand/:slug/:id`, component: BrandPage },
  { path: `/crop/:slug/:id/:tab?`, component: CropPage },
  { path: `/issue/:slug/:id`, component: IssuePage },
  { path: `/issues/:slug/:id`, component: IssueListingPage },
  { path: `/category/:slug/:id`, component: CategoryPage },
  // Crop Schedules
  { path: `/select-your-crops`, component: SelectYourCrops },
  { path: `/crop-info/attributes/:slug`, component: CropAttributes },
  { path: `/crop-info/tasks/:slug`, component: TaskListingPage },
  { path: `/crop-info`, component: CropInfoPage },
  { path: `/videos/:slug`, component: ExpertVideos },
  // Cart, Checkout, SignIn
  { path: `/checkout/cart`, component: Cart },
  { path: '/checkout/checkout', component: Checkout },
  { path: '/checkout/signin', component: SignIn },
  { path: '/checkout/address', component: AddressForm },
  // Store Front
  { path: '/saathi-store/:slug/:id', component: StoreFront },
  { path: '/saathi-store', component: StoreFront },
  { path: '/ppage/:slug/:id', component: ProductQRScannedPage },
  { path: '/pcode/:hash/:barcode', component: ProductQRScannedPage },
  // Errors and redirects
  { path: `/shop-na`, component: ShopNotAvailable },
  { path: `/article-na`, component: ArticleNotAvailable },
  { path: '/buynow-success', component: SuccessPage },
  { path: '*', component: PageNotFound, notFound: true },
];

export default routes;
