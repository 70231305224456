import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Box } from '@mui/system';
import { Button, Grid, styled, Typography } from '@mui/material';

import Search from './Search';
import { Loader } from './Loader';
import store from '../utilities/store';
import ServerError from './ServerError';
import ProductCard from '../shop/ProductCard';
import { useFetchData } from '../services/useFetchData';
import { searchProductsBySearchTerm } from '../services';
import { allStates, getStateBySlug } from '../utilities/states';
import { getRouteParams, makeSearchPageHref } from '../utilities/links';
import { Link } from 'react-router-dom';
import { LocalPhoneRounded } from '@mui/icons-material';
import RecentSearches from './RecentSearches';

const SearchPageWrapper = styled('div')({
  minHeight: '90vh',
  background: '#F3F3F3',
});

function SearchResults({ setHeaderURLs, intl }) {
  const { data, loadingStatus, match, search } = useFetchData(
    SearchResults,
    store,
    setHeaderURLs,
    intl,
  );
  const [products, setProducts] = useState(null);

  useEffect(() => {
    setProducts(data?.products);
  }, [data?.products]);

  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;

  return (
    <SearchPageWrapper>
      <Search match={match} search={search} />

      <Box sx={{ p: 2 }}>
        {!products?.length && <RecentSearches search={search} />}

        {loadingStatus === 'loaded' && products?.length ? (
          <>
            <Typography sx={{ mb: 0.5, fontSize: '16px', fontWeight: '700' }}>
              <FormattedMessage id="label_products" />
            </Typography>
            <Grid container spacing={2}>
              {products?.map((product, index) => (
                <Grid item key={index} xs={6}>
                  <ProductCard product={product} />
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <Button
            variant="contained"
            href={`tel:9503095030`}
            startIcon={<LocalPhoneRounded />}
            sx={{
              width: '100%',
              mt: 2,
              color: '#fff',
              background: 'green',
              '&:hover': { background: 'green' },
            }}
          >
            <FormattedMessage id="label_need_expert_help" />
          </Button>
        )}
      </Box>
    </SearchPageWrapper>
  );
}

SearchResults.fetchData = async (match, search) => {
  const { language, state } = getRouteParams(match, search);
  const query = new URLSearchParams(search);
  const searchTerm = query.get('searchTerm') || '';

  let products = [];
  if (searchTerm?.length > 2) {
    const result = await searchProductsBySearchTerm(
      searchTerm,
      language,
      state,
    );
    products = result?.responseData?.productGists;
  }

  const languageURLs = {};
  getStateBySlug(state).supportedLanguages.forEach((lang) => {
    languageURLs[lang] = makeSearchPageHref(match, search, lang);
  });

  const stateURLs = {};
  allStates.forEach((state) => {
    if (state.shop) {
      const lang = language === 'en' ? 'en' : state.language;
      stateURLs[state.slug] = makeSearchPageHref(
        match,
        search,
        lang,
        state.slug,
      );
    } else {
      stateURLs[state.slug] = 'stateNa';
    }
  });

  return {
    initialData: { products },
    seoData: {},
    languageURLs,
    stateURLs,
  };
};

export default SearchResults;
