import React from 'react';
import { styled } from '@mui/material/styles';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';

import { pallette } from '../constants/pallette';
import { makeProfileHref, makeShopHref } from '../utilities/links';
import getAddress from '../utilities/getAddress';

const AuthorContainer = styled(Link)({
  marginBottom: '15px',
});

const AuthorPicContainer = styled('div')({
  position: 'relative',
  width: '50px',
  height: '50px',
  display: 'inline-block',
  marginRight: '10px',
  borderRadius: '25px',
  overflow: 'hidden',
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: '35px',
  lineHeight: '52px',
  img: {
    objectFit: 'fill',
    height: '100%',
    width: '100%',
  },
});

const AuthorText = styled('div')({
  display: 'inline-block',
  '.name': {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  '.date, .location': {
    fontSize: '13px',
    color: '#666',
  },
});

function Author({ match, location: { search }, author, date }) {
  const dateString = moment(date).format('DD MMM YY, hh:mm A');
  // A background colour depending on the first char of the name
  const color = pallette[author.name.charCodeAt(0) % 16];

  const AgroStarKrishiGyaanId = 558020;

  const ProfileHref =
    author.id !== AgroStarKrishiGyaanId &&
    author.id !== undefined &&
    makeProfileHref(match, search, author.id);

  let [authorPic] = author.name;
  if (author.pic) {
    let pic = author.pic;
    // if pic contains static.agrostar.in then it means it is being served via imagekit.
    // We can then add a transform to it for a quicker loading. fo-auto focuses automatically on the face
    if (pic.includes('static.agrostar.in')) {
      pic += '?tr=w-50,h-50,fo-auto';
    }
    authorPic = <img src={pic} layout="fill" alt={author.name} />;
  }

  return (
    <AuthorContainer to={ProfileHref || makeShopHref(match, search)}>
      <AuthorPicContainer sx={{ backgroundColor: color }}>
        {authorPic}
      </AuthorPicContainer>
      <AuthorText>
        <div className="name">{author.name}</div>
        <div className="location">{getAddress(author.location)}</div>
        <div className="date">{dateString}</div>
      </AuthorText>
    </AuthorContainer>
  );
}

export default withRouter(Author);
