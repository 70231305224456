import React, { useEffect, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { makeFarmerAppProductHref, makeProductHref } from '../utilities/links';
import slugify from '../utilities/slugify';
import AndroidService from '../services/AndroidService';
import Clamp from 'react-multiline-clamp';
import { Card, CardMedia } from '@mui/material';
import StarsProductHeader from '../components/StarsProductHeader';
import useQuery from '../utilities/useQuery';
import { getEnv } from '../utilities/getEnv';

export const ProductListWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: '8px',
  rowGap: '8px',
});
const ProductName = styled('div')({
  fontSize: '14px',
  color: '#333',
  paddingTop: 5,
});
const SellingPrice = styled('span')({
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#212121',
  marginRight: '8px',
});
const MrpPrice = styled('span')({
  fontSize: '14px',
  color: '#a8a8a8',
  textDecorationLine: 'line-through',
});
const DiscountRatingWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexDirection: 'row-reverse',
  marginBottom: '5px',
});
const ProductDiscount = styled('div')({
  color: 'white',
  background: 'green',
  padding: '0px 3px',
  borderRadius: '5px',
});
export const ImageWrapper = styled('div')({
  minWidth: '130px',
  textAlign: 'center',
  img: {
    objectFit: 'contain',
    width: '100px',
    height: '125px',
  },
});
const PriceWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export function ProductPrice({ sellingPrice, mrp }) {
  return (
    <PriceWrapper>
      {!!(sellingPrice && sellingPrice > 0) && (
        <SellingPrice>{`₹${sellingPrice}`}</SellingPrice>
      )}
      {!!(mrp && mrp > 0) && <MrpPrice>{`₹${mrp}`}</MrpPrice>}
    </PriceWrapper>
  );
}

function ProductCard({
  match,
  location: { search },
  product: {
    skuCode,
    productName,
    mrp,
    sellingPrice,
    rating,
    productImages,
    enabledForSaathi,
  },
}) {
  const query = useQuery();
  const appLinks = query.get('appLinks');
  const isB2B = query.get('source')?.toLowerCase()?.includes('b2b');
  const [isAndroid, setIsAndroid] = useState(null);
  const discount = -Math.round(((mrp - sellingPrice) / mrp) * 100);
  const href = makeProductHref(match, search, slugify(productName), skuCode);
  const [showProductPrices, setShowProductPrices] = useState(false);

  useEffect(() => {
    if (AndroidService.checkIfAndroid()) {
      setIsAndroid(true);
    }

    const showProductPrices =
      localStorage?.getItem(`showProductPrices`) === 'false' ? false : true;
    setShowProductPrices(showProductPrices);
  }, []);

  const productCardUI = (
    <Card
      elevation={0}
      sx={{ minHeight: '220px', height: '100%', borderRadius: '8px', p: 1 }}
    >
      <DiscountRatingWrapper>
        <StarsProductHeader stars={rating?.average} size="1rem" />
        {showProductPrices && discount <= 0 ? (
          <ProductDiscount>{discount}%</ProductDiscount>
        ) : null}
      </DiscountRatingWrapper>

      <ImageWrapper>
        <img
          src={
            productImages?.[0]?.name
              ? productImages[0].name
              : 'icons/productImageDummy.jpeg'
          }
          alt={productName}
        />
      </ImageWrapper>

      <Clamp lines={2} maxLines={2}>
        <ProductName>{productName}</ProductName>
      </Clamp>
      {showProductPrices ? (
        <ProductPrice mrp={mrp} sellingPrice={sellingPrice} />
      ) : null}
    </Card>
  );

  const handleSaathiProductClick = () => {
    const role = localStorage.getItem(`role`);
    const profile = localStorage.getItem(`profile`);
    if (profile !== 'true' && role === 'FAG') return;

    window.parent.location.href = `${getEnv(
      'APP_URL',
    )}/products/${skuCode}?from=${match?.path}`;
  };

  if (!productName || (isB2B && !enabledForSaathi)) {
    return null;
  }

  return appLinks === 'saathi' ? (
    <div onClick={handleSaathiProductClick}>{productCardUI}</div>
  ) : isAndroid ? (
    <a href={makeFarmerAppProductHref(skuCode)}>{productCardUI}</a>
  ) : (
    <Link to={href}>{productCardUI}</Link>
  );
}

export default withRouter(ProductCard);
