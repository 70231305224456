import React from 'react';

import ListPage from './ProductListBase';
import { getRouteParams } from '../utilities/links';
import store from '../utilities/store';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { injectIntl } from 'react-intl';
import { getEnv } from '../utilities/getEnv';

function ProductListPage({ setHeaderURLs, intl }) {
  const { data, loadingStatus } = useFetchData(
    ProductListPage,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;
  return <ListPage data={data} />;
}

ProductListPage.fetchData = async (match, search) => {
  const { ids } = getRouteParams(match, search);
  return ListPage.fetchData(
    match,
    search,
    `${getEnv('FWA_API_URL')}/shopaggregator/v1/products`,
    { sku: ids },
  );
};

export default injectIntl(ProductListPage);
