import {
  getLanguageForState,
  isAgriShopEnabled,
  defaultUserState,
  defaultStateForLanguage,
} from './states.js';

/*
 * todo
 * - See if hreflangs can be part of page itself
 * - State name in header should be in the selected language (en or local)
 * - Think about partner= ... do we need to deal with it in canonicals?
 *
 * Overall cleanups
 * - Remove global store, switch to context API (cleaner code, no global thread
 *   issues, also can get rid of withRouter by passing match and search also)
 * - Latest react-intl for non-component translations (part of fetchData)
 */

function makeQueryString({
  language,
  state,
  partner,
  sort,
  page,
  topic,
  ids,
  postIds,
}) {
  const params = new URLSearchParams();
  // The convention is to skip the param for the default value
  if (language) params.append('language', language);
  if (state) params.append('state', state);
  if (partner) params.append('partner', partner);
  if (sort && sort !== 'latest') params.append('sort', sort);
  if (page && page !== 1) params.append('page', page);
  if (topic && topic !== 'all') params.append('topic', topic);
  if (ids) params.append('ids', ids);
  if (postIds) params.append('postIds', postIds);
  const query = params.toString();
  return query ? `?${query}` : '';
}

export function getRouteParams(match, search) {
  const {
    params: { slug, id, type, tab },
  } = match;

  const query = new URLSearchParams(search);
  const sort = query.get('sort') || 'latest';
  const page = parseInt(query.get('page'), 10) || 1;
  const ids = query.get('ids') || '';
  const postIds = query.get('postIds') || ''; // To be compatible with app deeplink
  const language = query.get('language') || 'en';
  const state = query.get('state') || defaultUserState;
  const partner = query.get('partner');
  const topic = query.get('topic') || 'all';
  const farmerId = query.get('farmerId');
  const farmerAuthToken = query.get('farmerAuthToken');
  const showHeader = query.get('showHeader');
  const idFromQueryParams = query.get('id');
  return {
    ...match?.params,
    type,
    id: id || idFromQueryParams,
    slug,
    tab,
    // Query params
    language,
    lang: language, // todo: get rid of lang everywhere in routeParams
    state,
    userstate: state, // todo: get rid of userstate everywhere in routeParams
    partner,
    topic,
    ids,
    postIds,
    sort,
    page,
    farmerId,
    farmerAuthToken,
    showHeader,
  };
}

// Paramters common to all pages, we need to carry these across navigations.
function getCommonParams(match, search, langParam, stateParam) {
  const query = new URLSearchParams(search);
  const language = langParam || query.get('language') || 'en';
  const state = stateParam || query.get('state') || defaultUserState;
  const partner = query.get('partner');

  return {
    language,
    state,
    partner,
  };
}

function getCommonQueryString(match, search, language, state) {
  return makeQueryString(getCommonParams(match, search, language, state));
}

export function getDefaultPageForState(state, lang, path) {
  const language = lang || getLanguageForState(state);
  const query = getCommonQueryString(null, null, language, state);

  if (isAgriShopEnabled(state)) {
    if (path === '/') {
      return `/shop${query}`;
    }

    const [pathname, params] = path?.split('?') || [];
    if (params) {
      const urlSearchParams = new URLSearchParams(params);
      let queryParams = '';
      urlSearchParams.forEach((value, key) => {
        if (key !== 'language' && key !== 'state') {
          queryParams += `&${key}=${value}`;
        }
      });
      return `${pathname}${query}${queryParams}`;
    }

    return `${pathname}${query}`;
  }
  return `/articles${query}`;
}

export function changeLanguage(match, search, language) {
  const params = getRouteParams(match, search);
  // Recreate the query string so that we are consistent in its order and param skipping
  const query = makeQueryString({ ...params, language });
  return `${match.pathname ? match.pathname : match.url}${query}`;
}

export function changeState(match, search, state) {
  const params = getRouteParams(match, search);
  // To avoid invalid state-language combination, we need to change language also
  const language = params.language === 'en' ? 'en' : getLanguageForState(state);
  // Recreate the query string so that we are consistent in its order and param skipping
  const query = new URLSearchParams(search);
  query.set('state', state);
  query.set('language', language);
  // const query = makeQueryString({ ...params, state, language });
  return `${match.pathname ? match.pathname : match.url}?${query.toString()}`;
}

// Used to generate a canonical - different states in same language should
// point back to one state's content so that it is not considered a duplicate page.
export function normalizeState(match, search) {
  const params = getRouteParams(match, search);
  const state = defaultStateForLanguage[params.language];
  const query = makeQueryString({ ...params, state });
  return `${match.pathname ? match.pathname : match.url}${query}`;
}

// Used to generate a canonical - where the contents are identical (not translated)
// regardless of state and language, eg, user posts
export function normalizeStateLanguage(match, search) {
  const params = getRouteParams(match, search);
  const query = makeQueryString({
    ...params,
    state: defaultUserState,
    language: 'en',
  });
  return `${match.pathname ? match.pathname : match.url}${query}`;
}

// Used to generate a clean url of the current page
export function getCurrentUrl(match, search) {
  const params = getRouteParams(match, search);
  const query = makeQueryString(params);
  return `${match.pathname ? match.pathname : match.url}${query}`;
}

export function changePage(match, search, page) {
  const params = getRouteParams(match, search);
  const query = makeQueryString({ ...params, page });
  return `${match.pathname ? match.pathname : match.url}${query}`;
}

/*
 * For all the following make href functions, please pass the match and search
 * so that any parameters that need to be carried through can be automatically
 * figured out here: language, state, partner (and maybe more in the future).
 * These are for making links throughout the app.
 */

export function makePolicyHref(match, search, type) {
  const query = getCommonQueryString(match, search);
  return `/policy/${type}${query}`;
}

export function makeListingHref(match, search, type, topic, sort) {
  const params = getCommonParams(match, search);
  return `/${type}${makeQueryString({ ...params, topic, sort })}`;
}

export function makeArticleHref(match, search, slug, id, language, state) {
  const params = getCommonParams(match, search);
  if (language) params.language = language;
  if (state) params.state = state;
  const query = makeQueryString(params);
  const href = `/article/${slug}/${id}${query}`;
  return encodeURI(href);
}

export function makePostHref(match, search, id) {
  const query = getCommonQueryString(match, search);
  return `/post/${id}${query}`;
}

export function makeTopicsHref(match, search) {
  const query = getCommonQueryString(match, search);
  return `/topics${query}`;
}

export function makeProfileHref(match, search, id) {
  const query = getCommonQueryString(match, search);
  return `/profile/${id}${query}`;
}

export function makeProductHref(match, search, slug, skuCode, language, state) {
  const params = getCommonParams(match, search);
  if (language) params.language = language;
  if (state) params.state = state;
  const query = makeQueryString(params);
  if (typeof skuCode !== 'string') {
    console.log('Received object as SKU code while making producthref');
    console.trace();
    return `/shop-na${query}`;
  }
  return encodeURI(`/product/${slug}/${skuCode}${query}`);
}

export function makeFarmerAppProductHref(skuCode) {
  return encodeURI(
    `ulink://android.agrostar.in/productdetails?skuCode=${skuCode}`,
  );
}

export function makeBuyNowHref(match, search, slug, skuCode, language, state) {
  const params = getCommonParams(match, search);
  if (language) params.language = language;
  if (state) params.state = state;
  const query = makeQueryString(params);
  return encodeURI(`/buynow/${slug}/${skuCode}${query}`);
}

export function makeShopHref(match, search) {
  const params = getCommonParams(match, search);
  const query = makeQueryString(params);
  if (!isAgriShopEnabled(params.state)) {
    return `/shop-na${query}`;
  }
  return `/shop${query}`;
}

export function makeBrandHref(match, search, slug, id, language, state) {
  const query = getCommonQueryString(match, search, language, state);
  return encodeURI(`/brand/${slug}/${id}${query}`);
}

export function makeCropHref(match, search, slug, id, tab, language, state) {
  const query = getCommonQueryString(match, search, language, state);
  return encodeURI(`/crop/${slug}/${id}/${tab}${query}`);
}

export function makeCategoryHref(match, search, slug, id, language, state) {
  const query = getCommonQueryString(match, search, language, state);
  return encodeURI(`/category/${slug}/${id}${query}`);
}

export function makeIssueListingPageHref(match, search, slug, id) {
  const query = getCommonQueryString(match, search);
  return encodeURI(`/issues/${slug}/${id}${query}`);
}

export function makeIssueHref(match, search, slug, id, language, state) {
  const query = getCommonQueryString(match, search, language, state);
  return encodeURI(`/issue/${slug}/${id}${query}`);
}

export function makeAndroidIssueHref(slug, id) {
  return encodeURI(
    `ulink://android.agrostar.in/issuedetails?issueAssociationId=${id}&cropName=${slug}`,
  );
}

export function makeBannerProductListHref(match, search, id) {
  const query = getCommonQueryString(match, search);
  return `/bannerproducts/${id}${query}`;
}

export function makeProductListHref(match, search, ids) {
  const query = getCommonQueryString(match, search);
  return query ? `/products${query}&ids=${ids}` : `/products?ids=${ids}`;
}

export function makeAndroidProductListHref(ids, cropName) {
  return encodeURI(
    `ulink://android.agrostar.in/productlist?sku_list=${ids}&pageName=${cropName}`,
  );
}

export function makeAllBrandsHref(match, search) {
  const query = getCommonQueryString(match, search);
  return `/brands${query}`;
}

export function makeFeaturedProductsHref(match, search) {
  const query = getCommonQueryString(match, search);
  return `/featured-products${query}`;
}

export function makeTrendingProductsHref(match, search) {
  const query = getCommonQueryString(match, search);
  return `/trending-products${query}`;
}

export function makeCheckoutPagesHref(match, search, page) {
  const query = getCommonQueryString(match, search);
  return `/checkout/${page}${query}`;
}

export function makeShareableStoreHref(search, storeName, storeCode) {
  storeName = encodeURIComponent(storeName);
  const searchParams = new URLSearchParams(search);
  let query = '';
  if (searchParams.get('language')) {
    query += `&language=${searchParams.get('language')}`;
  }
  if (searchParams.get('state')) {
    query += `&state=${searchParams.get('state')}`;
  }
  if (searchParams.get('showHeader')) {
    query += `&showHeader=${searchParams.get('showHeader')}`;
  }
  if (searchParams.get('showBackButton')) {
    query += `&showBackButton=${searchParams.get('showBackButton')}`;
  }
  return `/saathi-store?id=${storeCode}${query}&showToolbar=true&title=${storeName}`;
}

export function makeSaathiStoreHref(match, search, storeName, id, lang, state) {
  storeName = encodeURIComponent(storeName);
  const query = getCommonQueryString(match, search, lang, state);
  return `/saathi-store?id=${id}${query}&showToolbar=true&title=${storeName}`;
}

export function makeSelectYourCropsHref(match, search, lang, state) {
  const query = getCommonQueryString(match, search, lang, state);
  return `/select-your-crops${query}`;
}

export function makeCropInfoHref(match, search, lang, state) {
  const query = getCommonQueryString(match, search, lang, state);
  return `/crop-info${query}`;
}

export function makeCropAttributePageHref({
  match,
  search,
  lang,
  state,
  crop,
}) {
  const query = getCommonQueryString(match, search, lang, state);
  return `/crop-info/attributes/${crop}${query}`;
}

export function makeTaskListingPageHref({
  match,
  search,
  cropName,
  language,
  state,
  task,
  mockDate,
  mockAllTasks,
  farmerAuthToken,
  mockScheduleName,
}) {
  const params = new URLSearchParams(search);
  params.delete('state');
  params.delete('language');
  if (task) params.set('task', task);
  if (mockDate) params.set('mockDate', mockDate);
  if (mockAllTasks) params.set('mockAllTasks', mockAllTasks);
  if (farmerAuthToken) params.set('farmerAuthToken', farmerAuthToken);
  if (mockScheduleName) params.set('mockScheduleName', mockScheduleName);

  const query = getCommonQueryString(match, search, language, state);
  return `/crop-info/tasks/${cropName}${query}&${params}`;
}

export function makeExpertVideosHref(match, search, slug, lang, state) {
  const query = getCommonQueryString(match, search, lang, state);
  return `/videos/${slug}${query}`;
}

export function makeArticleNotAvailableHref(match, search, state, language) {
  // Recreate the query string so that we are consistent in its order and param skipping
  const query = makeQueryString({ state, language });
  return `/article-na${query}`;
}

export function makeShopNotAvailableHref(match, search, state) {
  const params = getRouteParams(match, search);
  const language = params.language === 'en' ? 'en' : getLanguageForState(state);
  const query = makeQueryString({ state, language });
  return `/shop-na${query}`;
}

export function makeBuyNowSuccessHref(match, search) {
  const query = getCommonQueryString(match, search);
  return `/buynow-success${query}`;
}

/*
 * These are for generating URLs where the current state of the URL does not
 * matter: sitemap, canonical etc. Therefore, we also don't have the match and
 * search parameters. For links within the page, prefer to use the above, because
 * there may be parameters like partner that will be retained when making the URL.
 */
export function makeArticleLangHref(language, slug, id) {
  const url = `/article/${slug}/${id}`;
  const state = defaultStateForLanguage[language];
  const query = makeQueryString({ state, language });
  return encodeURI(`${url}${query}`);
}

export function makeProductStateLangHref(state, language, slug, skuCode) {
  const url = `/product/${slug}/${skuCode}`;
  const query = makeQueryString({ state, language });
  return encodeURI(`${url}${query}`);
}

export function makeIssueLangHref(language, slug, id) {
  const url = `/issue/${slug}/${id}`;
  const state = defaultStateForLanguage[language];
  const query = makeQueryString({ state, language });
  return encodeURI(`${url}${query}`);
}

export function makeAndroidPostHref(id) {
  return encodeURI(`ulink://android.agrostar.in/postdetails?id=${id}`);
}

export function makeAndroidProblemSolvedPostHref(language, farmerId, tag) {
  const createdOnLt = new Date();
  const createdOnGt = new Date(new Date().setDate(createdOnLt.getDate() - 30));

  return encodeURI(
    `ulink://android.agrostar.in/postlist?language=${language}&farmerId=${farmerId}&limit=5&offset=0&curated=false&sortBy=rank&smart_filters=true&filters=Problem%20Solved%20Posts&blockTagSeenPosts=true&tags=${tag}&createdOnLt=${createdOnLt.getTime()}&createdOnGt=${createdOnGt.getTime()}`,
  );
}

export function makeSearchPageHref(match, search, lang, state) {
  const query = getCommonQueryString(match, search, lang, state);
  return `/search${query}`;
}
