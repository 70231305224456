import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import _, { debounce } from 'lodash';

import { Close, Search as SearchIcon } from '@mui/icons-material';
import { TextField, InputAdornment, Typography, styled } from '@mui/material';

import slugify from '../utilities/slugify';
import { ProductPrice } from '../shop/ProductCard';
import { searchProductsBySearchTerm } from '../services';
import { getRouteParams, makeProductHref } from '../utilities/links';
import { getRegionalAppString } from '../utilities/getRegionalAppString';

const SearchWrapper = styled('div')({
  padding: '16px',
  background: '#FFFFFF',
  position: 'relative',
  transition: 'all .1s ease-out',
});
const ProductsContainer = styled('div')({
  position: 'absolute',
  width: 'calc(100% - 32px)',
  left: '16px',
  zIndex: 2,
  boxShadow: '0 8px 16px 1px rgba(0,0,0,.7)',
});
const ProductWrapper = styled(Link)({
  gap: '8px',
  width: '100%',
  display: 'flex',
  padding: '5px 8px',
  background: '#fff',
  borderBottom: '1px solid #eee',
});

function Search({ match, search }) {
  const history = useHistory();
  const query = new URLSearchParams(search);
  const { state, language } = getRouteParams(match, search);
  const [searchSuggestion, setSearchSuggestion] = useState(null);
  const [searchTermDebounced, setSearchTermDebounced] = useState('');
  const [searchTerm, setSearchTerm] = useState(query.get('searchTerm') || '');
  const searchPlaceholder = getRegionalAppString(
    'label_search_bar_message',
    language,
  );

  useEffect(() => {
    if (searchTermDebounced?.length > 2) {
      searchProductsBySearchTerm(searchTermDebounced, language, state)
        .then((response) => {
          setSearchSuggestion(
            response?.responseData?.productGists?.slice(0, 5),
          );
        })
        .catch((e) => {
          console.log('Failed to get products by searchTerm: ', e);
        });
    } else {
      setSearchSuggestion(null);
    }
  }, [searchTermDebounced]);

  const handleChange = (event) => {
    const { value } = event?.target;
    setSearchTermDebounced(value);
  };

  const handleChangeDebounced = useCallback(debounce(handleChange, 1000), []);

  const handleChangeInput = (event) => {
    handleChangeDebounced(event);
    setSearchTerm(event?.target?.value);
  };

  const handleSubmit = (event) => {
    event?.preventDefault();

    if (searchTerm?.length) {
      let recentSearches =
        JSON.parse(localStorage?.getItem('recentSearches')) || [];
      _.pull(recentSearches, searchTerm);
      recentSearches?.unshift(searchTerm);
      recentSearches = recentSearches?.slice(0, 5);
      localStorage?.setItem(`recentSearches`, JSON.stringify(recentSearches));
    }

    query.set('searchTerm', searchTerm || '');
    if (history?.location?.pathname !== `/search`) {
      history.push(`/search?${query.toString()}`);
    } else {
      history.replace(`/search?${query.toString()}`);
    }
  };

  useEffect(() => {
    function addStyles() {
      const styles = {
        position: 'sticky',
        top: 0,
        left: '30%',
        width: '70%',
        padding: '6px 16px 6px 8px',
        backgroundColor: 'transparent',
      };
      const searchWrapper = document.getElementById('search-wrapper');
      if (searchWrapper) {
        const top = searchWrapper?.getBoundingClientRect().top;
        if (top < 50) {
          if (top < 5) styles['zIndex'] = 10000;
          if (top > 5) styles['zIndex'] = '';
          Object.entries(styles).forEach(([key, value]) => {
            searchWrapper.style[key] = value;
          });
        } else {
          searchWrapper.style.zIndex = '';
          Object.entries(styles).forEach(([key]) => {
            searchWrapper.style[key] = '';
          });
        }
      }
    }
    window.addEventListener('scroll', addStyles);
  }, []);

  return (
    <SearchWrapper id="search-wrapper" className="search-wrapper">
      <form onSubmit={handleSubmit}>
        <TextField
          size={`small`}
          fullWidth={true}
          variant="outlined"
          autoComplete="off"
          className="search-term-input-box"
          onChange={handleChangeInput}
          placeholder={searchPlaceholder}
          value={searchTerm || ''}
          autoFocus={history?.location?.pathname === `/search`}
          sx={{
            border: 'none',
            '.MuiOutlinedInput-root': { height: '35px', background: '#fff' },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ cursor: 'default' }}
                onClick={(event) => {
                  event?.nativeEvent?.stopImmediatePropagation();
                  event?.stopPropagation();
                  event?.preventDefault();
                }}
              >
                {searchTerm && searchTerm?.length && (
                  <Close
                    sx={{ mr: 1, color: '#949494' }}
                    onClick={(event) => {
                      event?.nativeEvent?.stopImmediatePropagation();
                      event?.stopPropagation();
                      event?.preventDefault();
                      setSearchTerm(``);
                      setSearchSuggestion(null);
                    }}
                  />
                )}
                <SearchIcon
                  onClick={handleSubmit}
                  sx={{ color: 'rgb(157, 33, 35)' }}
                />
              </InputAdornment>
            ),
          }}
        />
      </form>
      {searchSuggestion?.length ? (
        <ProductsContainer>
          {searchSuggestion?.map((product, index) => (
            <ProductWrapper
              key={index}
              to={makeProductHref(
                match,
                search,
                slugify(product?.productName || ''),
                product?.skuCode,
              )}
            >
              <img
                src={
                  product?.productImages?.[0]?.name
                    ? product?.productImages?.[0]?.name
                    : '/icons/productImageDummy.jpeg'
                }
                alt="product"
                width="60px"
                height="75px"
              />
              <div>
                <Typography fontSize={16}>{product?.productName}</Typography>
                <ProductPrice
                  mrp={product?.mrp}
                  sellingPrice={product?.sellingPrice}
                />
              </div>
            </ProductWrapper>
          ))}
          <ProductWrapper
            to={() => {
              query.set('searchTerm', searchTerm || '');
              return `/search?${query.toString()}`;
            }}
            replace={history?.location?.pathname === `/search`}
            style={{
              display: 'block',
              textAlign: 'center',
              color: 'rgb(155,31,36)',
            }}
          >
            <FormattedMessage id="label_view_all_products" />
          </ProductWrapper>
        </ProductsContainer>
      ) : (
        ''
      )}
    </SearchWrapper>
  );
}

export default Search;
