import React from 'react';
import { styled } from '@mui/material/styles';

import CategoryCard from './CategoryCard';

const ListWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  fontSize: '14px',
  overflowX: 'auto',
});

const Categories = ({ categories }) => {
  return (
    <>
      <ListWrapper>
        {categories.map((c) => (
          <CategoryCard key={c.id} category={c} />
        ))}
      </ListWrapper>
    </>
  );
};

export default Categories;
