import React from 'react';
import { injectIntl } from 'react-intl';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { useFetchData } from '../services/useFetchData';
import store from '../utilities/store';
import MessagePage from './MessagePage';

function ArticleNotAvailable({ setHeaderURLs, intl }) {
  const { loadingStatus } = useFetchData(
    ArticleNotAvailable,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;
  return (
    <MessagePage
      messageId="article_in_language_not_found"
      image="/language_unavailable.png"
    />
  );
}

ArticleNotAvailable.fetchData = () => ({
  titleId: 'article_in_language_not_found',
  canonical: 'nstate',
  stateURLs: 'home',
  languageURLs: 'home',
  initialData: {},
});

export default injectIntl(ArticleNotAvailable);
