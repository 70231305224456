/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable camelcase */
import React from 'react';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

import Heading from '../components/Heading';
import { Typography } from '@mui/material';

const DetailsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  marginBottom: '12px',
  background: '#ffffff',
  lineHeight: '24px',
  ul: {
    margin: '0',
    padding: '0px 11px',

    li: {
      lineHeight: '1.5em',
      listStyleType: '"- "',
    },
  },
});
const Boldfonts = styled('span')({
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#000',
  opacity: '0.8',
});

const Label = styled('h4')({
  color: '#000',
  fontSize: '15px',
  fontWeight: '700',
  margin: '0',
});

function AdditionalDetails({ details }) {
  if (
    details === null ||
    details[0] === undefined ||
    details[0].attributes == null
  ) {
    return null;
  }
  return (
    <DetailsWrapper>
      <Heading>
        <Label>
          <FormattedMessage id="label_key_points" />
        </Label>
      </Heading>

      {details &&
        details[0].attributes &&
        details[0].attributes.map(({ key, value, attr_id }) => (
          <React.Fragment key={attr_id}>
            <Boldfonts>{key}</Boldfonts>
            <Typography variant="subtitle2" sx={{ marginBottom: '10px' }}>
              {value}
            </Typography>
          </React.Fragment>
        ))}
    </DetailsWrapper>
  );
}

export default AdditionalDetails;
