import { styled } from '@mui/material/styles';

const Heading = styled('div')({
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#000',
  padding: '10px 0',
});

export const Title = styled('div')({
  fontSize: '18px',
  fontWeight: 'bold',
  color: '#000',
  padding: '10px 0',
});

export const CenteredTitle = styled(Title)({
  textAlign: 'center',
});

export const Text = styled('div')({
  fontSize: '14px',
  fontWeight: 'normal',
  color: '#000',
  padding: '10px 0',
});

export const SubText = styled('div')({
  fontSize: '12px',
  fontWeight: 'normal',
  color: '#999999',
  padding: '10px 0',
});

export default Heading;
