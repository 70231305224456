import React from 'react';
import { styled } from '@mui/material/styles';

import Heading from '../components/Heading';
import PestControlIcon from '@mui/icons-material/PestControl';
import { Card, Container, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { getRouteParams, makeIssueHref } from '../utilities/links';

const IssueWrapper = styled('div')({
  padding: '8px',
  marginBottom: '12px',
  background: '#ffffff',
  lineHeight: '24px',
});

const Label = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: '#000',
  fontSize: '16px',
  fontWeight: '400',
  margin: '0',
});

const IssueDetail = styled('div')({
  padding: '8px 10px',
});

const CropWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  div: {
    width: '30px',
    height: '30px',
    marginRight: '8px',
    img: {
      width: '100%',
      height: '100%',
      borderRadius: '50%',
    },
  },
});

function ProductIssue({
  product: {
    issues: { title, totalCount, items },
  },
  match,
  search,
}) {
  if (totalCount === 0 || items.length === 0) {
    return null;
  }
  const { slug, lang, state } = getRouteParams(match, search);
  return (
    <IssueWrapper>
      <Heading>
        <Label>
          <PestControlIcon sx={{ mr: '5px' }} />
          {title}
        </Label>
      </Heading>
      <Container
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: `repeat(${items.length}, 250px)`,
          gap: '10px',
          padding: '5px 0 !important',
          margin: '0',
          overflowX: 'scroll',
        }}
      >
        {items.map((issue) => (
          <Link
            key={issue.issueAssociationId}
            to={makeIssueHref(
              match,
              search,
              slug,
              issue.issueAssociationId,
              lang,
              state,
            )}
          >
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: '220px',
                boxShadow: 'rgba(0, 0, 0, 0.16)',
                borderRadius: '10px',
              }}
            >
              <Typography
                variant="span"
                sx={{
                  width: '100%',
                  height: '150px',
                  img: { objectFit: 'cover', width: '100%', height: '100%' },
                }}
              >
                <img src={issue.imageURL} alt="issue image" />
              </Typography>
              <IssueDetail>
                <Typography variant="span">{issue.name}</Typography>
                <CropWrapper>
                  <div>
                    <img src={issue.crop.imageUrl} alt={issue.crop.imageURL} />
                  </div>
                  <span>{issue.crop.label}</span>
                </CropWrapper>
              </IssueDetail>
            </Card>
          </Link>
        ))}
      </Container>
    </IssueWrapper>
  );
}

export default ProductIssue;

