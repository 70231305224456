import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import styled from '@emotion/styled';
import { Button, Card, Container, Typography } from '@mui/material';

import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { useFetchData } from '../services/useFetchData';
import store from '../utilities/store';
import BagItems from './BagItems';
import { removeFromCart } from '../services/cartService';
import EmptyCart from './EmptyCart';
import { ArrowForwardTwoTone } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { getRouteParams, makeCheckoutPagesHref } from '../utilities/links';
import { shopLanguages } from '../utilities/languages';
import { getProductDetails } from '../services';

const SectionTitle = styled(Typography)({
  lineHeight: `1.3`,
  fontWeight: `600`,
});
const BillDetailChildWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: '5px 0',
});

function Cart({ setHeaderURLs, intl }) {
  const [cart, setCart] = useState({});
  const [isAnyCartChange, setIsAnyCartChange] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const localCart = JSON.parse(localStorage?.getItem(`cart`));

      if (!isMounted && localCart && localCart.products) {
        localCart?.products?.forEach((product, index) => {
          shopLanguages.forEach((lang) =>
            (async (l) => {
              const details = await getProductDetails(product.skuCode, l);
              if (details && details.responseData) {
                localCart.products[index].productName = {
                  [lang]: details.responseData.productName,
                  ...localCart.products[index].productName,
                };
                localStorage?.setItem(`cart`, JSON.stringify(localCart));
              }
            })(lang),
          );
        });
      }
      setIsMounted(true);
      setCart(localCart);
    }
  }, [isAnyCartChange]);

  const { loadingStatus, match, search } = useFetchData(
    Cart,
    store,
    setHeaderURLs,
    intl,
    true,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;

  const handleRemove = (product) => {
    removeFromCart(product);
    setIsAnyCartChange(!isAnyCartChange);
  };

  return (
    <div>
      {cart && cart.products && cart.products.length > 0 ? (
        <>
          <Container maxWidth={'xl'} sx={{ padding: '10px 10px 5px' }}>
            <SectionTitle variant="h6">
              <FormattedMessage id="label_cart_items_in_cart" /> (
              {cart.products.length})
            </SectionTitle>
          </Container>

          <BagItems
            match={match}
            search={search}
            cartProducts={cart.products}
            handleRemove={handleRemove}
            isAnyCartChange={isAnyCartChange}
            setIsAnyCartChange={setIsAnyCartChange}
          />

          <Container maxWidth={'xl'} sx={{ margin: '15px 0 10px' }}>
            <SectionTitle variant="h6">
              <FormattedMessage id="label_bill_details" />
            </SectionTitle>
          </Container>
          <Card
            variant="outlined"
            sx={{ marginBottom: '50px', padding: '10px' }}
          >
            <BillDetailChildWrapper>
              <Typography>
                <FormattedMessage id="label_subtotal" />
              </Typography>
              <Typography>₹{cart.subTotal || 0}</Typography>
            </BillDetailChildWrapper>
            <BillDetailChildWrapper>
              <Typography sx={{ color: '#00733E' }}>
                <FormattedMessage id="label_discount" />
              </Typography>
              <Typography sx={{ color: '#00733E' }}>
                -₹{cart.discount || 0}
              </Typography>
            </BillDetailChildWrapper>
            <BillDetailChildWrapper>
              <Typography>
                <FormattedMessage id="label_total_amount" />
              </Typography>
              <Typography variant={'h6'}>
                <b>₹{cart.grandTotal || 0}</b>
              </Typography>
            </BillDetailChildWrapper>
          </Card>
          <Button
            size={`large`}
            variant={'contained'}
            color={'primary'}
            onClick={() =>
              history.push(makeCheckoutPagesHref(match, search, 'checkout'))
            }
            sx={{
              position: 'fixed',
              maxWidth: '600px',
              width: '100%',
              bottom: 0,
              borderRadius: '0',
              zIndex: 1,
            }}
            disabled={!cart.products || cart.products.length === 0}
            endIcon={<ArrowForwardTwoTone />}
          >
            <FormattedMessage id="label_checkout" />
          </Button>
        </>
      ) : (
        <EmptyCart image="/empty_cart.svg" match={match} search={search} />
      )}
    </div>
  );
}

Cart.fetchData = () => ({
  initialData: ' ',
  titleId: 'title_cart',
});

export default injectIntl(Cart);
