import React from 'react';
import { FormattedMessage } from 'react-intl';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { addToCart } from '../services/cartService';
import { getStateBySlug } from '../utilities/states';
import { makeCheckoutPagesHref } from '../utilities/links';

const AddToCartButton = styled('button')({
  borderRadius: '0',
  fontSize: '14px',
  width: '100%',
  height: '100%',
  border: '0',
  color: '#fff',
  background: 'rgb(155,31,36)',
});

function AddToCart({
  product,
  skuCode,
  quantity,
  offerAppliedOnProduct,
  slug,
  match,
  search,
  lang,
}) {
  const history = useHistory();

  const handleAddToCart = () => {
    let productToAdd = {
      skuCode: skuCode,
      quantity: quantity,
      image: product?.productImages?.[0]?.name,
      productName: { [lang]: product?.productName },
      inventory: product?.inventory,
      mrp: product.mrp,
      sellingPrice: product.sellingPrice,
      offers: product.offers,
      offersDetail: product.offersDetail,
      appliedOffer: offerAppliedOnProduct,
    };
    const state = getStateBySlug(slug).name;
    addToCart(productToAdd, state);
    history.push(makeCheckoutPagesHref(match, search, 'cart'));
  };
  return (
    <AddToCartButton onClick={handleAddToCart}>
      <FormattedMessage id="label_add_to_bag_2" />
    </AddToCartButton>
  );
}

export default AddToCart;
