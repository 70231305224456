import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import apiCall from '../utilities/apiCall';
import store from '../utilities/store';
import ProductCard, { ProductListWrapper } from './ProductCard';
import LayoutWrapper from '../components/Layout';
import { Title } from '../components/Heading';
import { shopLanguages } from '../utilities/languages';
import strings from '../utilities/strings';
import slugify from '../utilities/slugify';
import { categoryDetails } from '../constants/categories';
import {
  getRouteParams,
  makeCategoryHref,
  makeShopNotAvailableHref,
} from '../utilities/links';
import {
  getAppSource,
  getStateBySlug,
  allStates,
  isAgriShopEnabled,
} from '../utilities/states';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { getEnv } from '../utilities/getEnv';

function CategoryPage({ setHeaderURLs, intl }) {
  const { data, loadingStatus } = useFetchData(
    CategoryPage,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;
  const { response } = data;

  if (
    !(
      response.products &&
      response.products.items &&
      response.products.items.length > 0
    )
  ) {
    return <FormattedMessage id="no_products_found_title" />;
  }

  const {
    products: { items, title },
  } = response;

  return (
    <LayoutWrapper>
      <Title>{title.label}</Title>
      <ProductListWrapper>
        {items.map((p) => (
          <ProductCard key={p.skuCode} product={p} />
        ))}
      </ProductListWrapper>
    </LayoutWrapper>
  );
}

async function getCategoryDetails(id, userState, lang) {
  const categoryName = categoryDetails[id].value;
  const contentService = getEnv('FWA_API_URL');
  const api = `${contentService}/contentservice/v1/categorydetail?categoryName=${categoryName}`;

  const url = new URL(api);
  url.searchParams.append('language', lang);
  const source = getAppSource(userState);
  url.searchParams.append('source', source);

  return apiCall(url.href);
}

CategoryPage.fetchData = async (match, search) => {
  const { id, language, state } = getRouteParams(match, search);
  if (!isAgriShopEnabled(state)) {
    return { redirectURL: makeShopNotAvailableHref(match, search, state) };
  }
  const titleId = 'title_shop';
  const titleParams = { heading: id };
  const seoData = {};
  let result = {};

  result = await getCategoryDetails(id, state, language);

  if (
    !result.responseData ||
    !result.responseData.products ||
    !result.responseData.products.items
  ) {
    return {
      titleId: 'no_products_found_title',
      initialData: [],
      languageURLs: 'home',
      stateURLs: 'home',
    };
  }

  const languageSlugs = {};
  shopLanguages.forEach((lang) => {
    const categoryName = strings[lang][categoryDetails[id].labelKey];
    languageSlugs[lang] = slugify(categoryName);
  });

  const languageURLs = {};
  getStateBySlug(state).supportedLanguages.forEach((l) => {
    const sl = languageSlugs[l];
    // Change only the language if it is supported
    // (All India will list all languages - we don't want to honour non-shop languages)
    languageURLs[l] = sl
      ? makeCategoryHref(match, search, sl, id, l)
      : 'languageNa';
  });

  const stateURLs = {};
  allStates.forEach((s) => {
    if (s.shop) {
      // Change state and use the state's language.
      const lang = language === 'en' ? 'en' : s.language;
      const sl = languageSlugs[lang];
      stateURLs[s.slug] = makeCategoryHref(match, search, sl, id, lang, s.slug);
    } else {
      stateURLs[s.slug] = 'stateNa';
    }
  });

  const initialData = { response: result.responseData };

  // Cannonical: the list of products shown for a category changes by state.
  // so we have to use self as the canonical.
  return {
    initialData,
    languageURLs,
    stateURLs,
    titleId,
    titleParams,
    canonical: 'self',
    seoData,
  };
};

export default injectIntl(CategoryPage);
