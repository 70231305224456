import React from 'react';
import { FormattedMessage } from 'react-intl';

import styled from '@emotion/styled';
import { Typography } from '@mui/material';
import StraightenIcon from '@mui/icons-material/Straighten';
import { Link } from 'react-router-dom';

const VariantWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  padding: '8px',
  marginBottom: '12px',
  background: '#ffffff',
  lineHeight: '24px',
});

function ProductVariants({ product: { variants } }) {
  if (!variants || variants.length === 0) {
    return null;
  }

  return (
    <VariantWrapper>
      <Typography
        variant="span"
        sx={{ display: 'flex', alignItems: 'center', opacity: '0.6' }}
      >
        <StraightenIcon sx={{ transform: 'rotate(270deg)' }} />
        <FormattedMessage id="other_variants" />
      </Typography>
      {variants &&
        variants.map((variant) => (
          <Link key={variant.sku_code} to={variant.link}>
            <Typography
              variant="span"
              sx={{
                display: 'inline-block',
                fontWeight: '700',
                padding: '2px 3px',
                margin: '0 5px',
                border: '1px solid #aaa',
                borderRadius: '5px',
                opacity: '0.6',
              }}
            >
              {variant.label}
            </Typography>
          </Link>
        ))}
    </VariantWrapper>
  );
}

export default ProductVariants;
