import React from 'react';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import { Link, withRouter } from 'react-router-dom';

import { TAB_ORDER_MAPPING } from '../constants/crops';
import Heading from '../components/Heading';

const ListWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  fontSize: '14px',
  overflowX: 'auto',
  marginBottom: '15px',
  background: '#fff',
  borderRadius: '4px',
  border: '1px solid #cccccc',
});

const TabWrapper = styled(Link)({
  position: 'relative',
  minWidth: '50px',
  flex: '1',
  marginLeft: '1%',
  color: '#fff',
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  cursor: 'pointer',

  '&:nth-child(1)': {
    marginLeft: '0',
  },

  '&:hover': {
    borderBottom: '3px solid #902123',
  },
});

export const ImageWrapper = styled('div')({
  position: 'relative',
  width: '100%',
  height: '60px',
  img: {
    objectFit: 'none',
  },
});

const Tab = withRouter(({ tab, activeTab, location: { search } }) => {
  const tabObj = TAB_ORDER_MAPPING[tab];
  if (!tabObj) {
    return null;
  }
  const { labelKey, key } = tabObj;

  return (
    <TabWrapper
      to={`${key}${search}`}
      sx={activeTab === key ? { borderBottom: '3px solid #902123' } : {}}
    >
      <Heading>
        <FormattedMessage id={labelKey} />
      </Heading>
    </TabWrapper>
  );
});

const TabsView = ({ order, activeTab }) => {
  return (
    <ListWrapper>
      {order.map((o) => (
        <Tab key={o} tab={o} activeTab={activeTab} />
      ))}
    </ListWrapper>
  );
};

export default TabsView;
