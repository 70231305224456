import React from 'react';
import {
  Link,
  useHistory,
  useLocation,
  useParams,
  withRouter,
} from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import LanguageSelect from './LanguageSelect';
import StateSelect from './StateSelect';
import { getRouteParams, makeCheckoutPagesHref, makeShopHref } from '../utilities/links';
import { IconButton } from '@mui/material';
import NavigationDiscovery from '../product/NavigationDiscovery';

function Header({ match, location: { search }, headerURLs }) {
  const { state } = getRouteParams(match, search);
  const { stateURLs, languageURLs } = headerURLs;
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  return (
    <>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          maxWidth: 600,
          right: 'auto',
        }}
      >
        <Toolbar variant="dense">
          <Typography
            variant="h6"
            component="div"
            sx={{
              flexGrow: 1,
              height: '40px',
              img: { height: '30px', margin: '5px 0' },
            }}
          >
            <Link to={makeShopHref(match, search)}>
              <img src="/AgrostarLogo.png" alt="AgroStar" />
            </Link>
          </Typography>
          <IconButton
            size={'medium'}
            color={'primary'}
            onClick={() =>
              history.push(makeCheckoutPagesHref(match, search, 'cart'))
            }
          >
            <ShoppingCartIcon sx={{ color: 'white' }} />
          </IconButton>
          <LanguageSelect
            state={state}
            languageURLs={languageURLs}
            match={match}
            search={search}
          />
          <StateSelect stateURLs={stateURLs} match={match} search={search} />
        </Toolbar>
      </AppBar>
      <NavigationDiscovery
        match={{ ...location, params }}
        search={location.search}
      />
      {/* Just to give space to the content. There is no toolbar here. */}
      <Toolbar variant="dense" />
    </>
  );
}

export default withRouter(Header);
