import React from 'react';
import { injectIntl } from 'react-intl';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { useFetchData } from '../services/useFetchData';
import store from '../utilities/store';
import MessagePage from './MessagePage';

function PageNotFound({ setHeaderURLs, intl }) {
  const { loadingStatus } = useFetchData(
    PageNotFound,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;
  return <MessagePage messageId="page_not_found" image="/page_not_found.png" />;
}

PageNotFound.fetchData = () => ({
  titleId: 'page_not_found',
  canonical: 'nstate',
  stateURLs: 'home',
  languageURLs: 'home',
});

export default injectIntl(PageNotFound);
