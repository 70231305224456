import { getEnv } from './getEnv';

/*
 * The structured data format is specified for Google and Facebook in these websites:
 * Google:
 *   https://support.google.com/merchants/answer/7538732?hl=en
 *   https://support.google.com/merchants/answer/6386198?hl=en
 *   https://developers.google.com/search/docs/appearance/structured-data/product#merchant-listing-experiences
 * Facebook:
 *   https://developers.facebook.com/docs/marketing-api/catalog/guides/microdata-tags#json-ld
 * To debug:
 *   https://business.facebook.com/ads/microdata/debug
 *   https://search.google.com/test/rich-results
 */

export const prefixCategories = {
  'AGS-S-': 'Home & Garden > Plants > Seeds',
  'AGS-CP': 'Home & Garden > Household Supplies > Pest Control > Pesticides',
  'AGS-CN': 'Home & Garden > Lawn & Garden > Gardening > Fertilizers',
  'AGS-HW': 'Home & Garden > Lawn & Garden > Outdoor Power Equipment',
};

const prefixes = Object.keys(prefixCategories);

export const getGoogleStructuredData = ({
  name = '',
  type = 'Agriculture',
  url = getEnv('FWA_BASE_URL'),
  ...rest
}) => {
  return {
    '@context': 'http://schema.org/',
    '@type': type,
    name,
    url,
    ...rest,
  };
};

export default function getProductStructuredData(productResponse, url) {
  if (!productResponse) {
    return '';
  }

  const {
    productName,
    brand,
    skuCode,
    productImages,
    sellingPrice,
    details,
    weight,
    rating,
    inventory,
  } = productResponse;
  const primaryImage = productImages.find((p) => p.type === 'primary');

  const sData = {
    '@context': 'http://schema.org/',
    '@type': 'Product',
    sku: skuCode,
    name: productName,
    description: productName,
    url: `${getEnv('FWA_BASE_URL')}${url}`,
    image: (primaryImage && primaryImage.name) || '',
    brand: {
      '@type': 'Brand',
      name: brand,
    },
    offers: [
      {
        '@type': 'Offer',
        price: sellingPrice,
        priceCurrency: 'INR',
        availability:
          inventory > 0
            ? 'https://schema.org/InStock'
            : 'https://schema.org/OutOfStock',
      },
    ],
  };

  for (let i = 0; i < prefixes.length; i++) {
    if (skuCode.startsWith(prefixes[i])) {
      sData.category = prefixCategories[prefixes[i]];
      break;
    }
  }

  // Override the description with chemical composition (15) or Extra Description
  if (details && details.attributes) {
    for (let j = 0; j < details.length; j++) {
      for (let i = 0; i < details[j].attributes.length; i++) {
        const detail = details[j].attributes[i];
        if (detail.attr_id === 15 || detail.attr_id === 14) {
          sData.description = detail.value;
          break;
        }
      }
    }
  }

  if (weight) {
    sData.weight = {
      '@type': 'QuantitativeValue',
      unitText: weight.unit,
      value: weight.value,
    };
  }

  if (rating && rating.totalCount !== 0) {
    sData.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: rating.average,
      reviewCount: rating.totalCount,
      bestRating: 5,
      worstRating: 1,
    };
  }

  return sData;
}
