import React from 'react';
import { styled } from '@mui/material/styles';

// TODO: import Share from '../../../components/Share';
import Author from './Author';
import Tags from './Tags';
import PostImage from './PostImage';
import ActivityCountBar from './ActivityCountBar';

const agrostar = {
  name: 'AgroStar Krishi Gyaan',
  farmer_id: 557064,
  pic: 'https://static.agrostar.in/appimages/557064/PROFILE_PIC/17112017%2011:10:34?tr=w-50,h-50',
  location: {
    city: 'Pune',
    district: '',
    state: 'Maharashtra',
    longitude: 73.79378509521484,
    pincode: '411021',
    taluka: '',
    postoffice: '',
    latitude: 18.549007415771484,
  },
};

const ArticleTitleWrapper = styled('div')({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
});

const ArticleContents = styled('div')({
  padding: '15px 5px',
  background: '#ffffff',
  '.type, .source': {
    fontSize: '13px',
    marginTop: '15px',
  },
  '.type': {
    paddingRight: '10px',
    borderRight: '2px solid #ccc',
  },
  '.source': {
    paddingLeft: '10px',
    fontWeight: 'bold',
  },
  '.heading': {
    fontSize: '18px',
    fontWeight: 'bold',
    margin: '15px 0px',
  },
  '.synopsis, .description': {
    marginTop: '8px',
    overflow: 'hidden',
  },
});

function Article({ article, likes }) {
  const image = article.media.find((m) => m.type === 'image') || {};
  const youtube = article.media.find((m) => m.type === 'youtube') || {};

  return (
    <div>
      <PostImage
        alt={article.heading}
        imageUrl={image.url}
        youtubeId={youtube.url}
        entityType="article"
      />
      <ArticleContents>
        <ArticleTitleWrapper>
          <Author author={agrostar} date={article.epoch} />
          {/* <Share /> TODO */}
        </ArticleTitleWrapper>

        <div>
          <span className="type">{article.type.heading}</span>
          <span className="source">{article.source.heading}</span>
        </div>
        <div className="heading">{article.heading}</div>
        <div className="synopsis">{article.synopsis}</div>
        <div className="description">{article.description}</div>
        <Tags tags={article.tags} />
        <ActivityCountBar likes={likes} shares={article.sharedByIds.length} />
      </ArticleContents>
    </div>
  );
}

export default Article;
