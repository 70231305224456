import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

export default function Toast(props) {
  return (
    <Snackbar
      open={props?.open}
      autoHideDuration={2000}
      onClose={() => props?.setOpen(false)}
      anchorOrigin={
        props?.anchorOrigin
          ? props.anchorOrigin
          : { vertical: 'top', horizontal: 'right' }
      }
    >
      <Alert
        icon={false}
        onClose={() => props?.setOpen(false)}
        sx={{
          background: '#666666',
          color: '#ffffff',
          borderRadius: '6px',
          textAlign: 'center',
        }}
      >
        {props?.message ? (
          props?.message
        ) : (
          <FormattedMessage id="label_select_atleast_1_crop" />
        )}
      </Alert>
    </Snackbar>
  );
}
