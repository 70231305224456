import React from 'react';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

import { ProductPrice } from '../shop/ProductCard';
import { Typography } from '@mui/material';

const DetailsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '14px',
  padding: '8px',
  background: '#ffffff',
  marginBottom: '12px',
  lineHeight: '24px',
});

const ProductPriceWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const ProductPriceInfo = styled('div')({
  display: 'flex',
  alignItems: 'center',
  span: { opacity: '0.5' },
  'span:nth-child(2)': { display: 'list-item', marginLeft: '20px' },
});

function ProductDetails({ product: { sellingPrice, mrp, displayMessage } }) {
  return (
    <DetailsWrapper>
      <ProductPriceWrapper>
        <ProductPrice mrp={mrp} sellingPrice={sellingPrice} />
        <Typography
          variant="span"
          sx={{ fontWeight: 'bold', color: '#00733E', marginLeft: '5px' }}
        >
          ( {Math.round(((mrp - sellingPrice) / mrp) * 100)}%{' '}
          <FormattedMessage id="label_off" /> )
        </Typography>
      </ProductPriceWrapper>
      <ProductPriceInfo>
        <Typography variant="span">
          <FormattedMessage id="price_per_unit" />
        </Typography>
        <Typography variant="span">
          <FormattedMessage id="inclusive_of_all_taxes_gst_included" />
        </Typography>
      </ProductPriceInfo>
      {displayMessage}
    </DetailsWrapper>
  );
}

export default ProductDetails;
