import axios from 'axios';
import apiCall from '../utilities/apiCall';
import { getEnv } from '../utilities/getEnv';

export function createCart() {
  const cart = {};
  cart.products = [];
  localStorage?.setItem(`cart`, JSON.stringify(cart));
  return getCart();
}

export function updateCartWithId(cartId) {
  let cartFromLocal = getCart();
  if (cartFromLocal) {
    cartFromLocal.cartId = cartId;
  } else {
    cartFromLocal = createCart();
    cartFromLocal.cartId = cartId;
  }
  localStorage?.setItem(`cart`, JSON.stringify(cartFromLocal));
  return cartFromLocal;
}

export function updateCartWithShippingAddressId(shippingAddressId) {
  let cartFromLocal = getCart();
  if (cartFromLocal) {
    cartFromLocal.shippingAddressId = shippingAddressId;
  } else {
    cartFromLocal = createCart();
    cartFromLocal.shippingAddressId = shippingAddressId;
  }
  localStorage?.setItem(`cart`, JSON.stringify(cartFromLocal));
  return cartFromLocal;
}

export function getCart() {
  let cartFromLocalStorage = localStorage?.getItem(`cart`);
  if (cartFromLocalStorage && cartFromLocalStorage !== null) {
    return JSON.parse(cartFromLocalStorage);
  } else {
    return null;
  }
}

export function getProductFromCart(skuCode) {
  let currentCart = getCart();
  if (currentCart && currentCart.products) {
    let product = currentCart.products.find((product) => {
      return skuCode === product.skuCode;
    });
    if (product) {
      return product;
    }
  }
  return null;
}

export function removeFromCart(product) {
  if (product.skuCode) {
    let cartFromLocal = getCart();
    if (cartFromLocal && cartFromLocal !== null) {
      let productsFromCart = cartFromLocal.products;
      if (productsFromCart && productsFromCart.length > 0) {
        let newProducts = productsFromCart.filter((productFromCart) => {
          return productFromCart.skuCode !== product.skuCode;
        });

        let subTotal = 0,
          discount = 0;
        newProducts.forEach(
          (product) => (subTotal += product.sellingPrice * product.quantity),
        );

        let newCart = cartFromLocal;
        newCart.products = newProducts;
        newCart.subTotal = subTotal;
        newCart.discount = discount;
        newCart.grandTotal = subTotal - discount;
        localStorage?.setItem(`cart`, JSON.stringify(newCart));
      }
    }
  }
}

export function addToCart(product, state) {
  if (
    product.quantity &&
    product.quantity > 0 &&
    product.skuCode &&
    product.skuCode !== ''
  ) {
    let cartFromLocal = getCart();
    if (
      !cartFromLocal ||
      cartFromLocal === null ||
      cartFromLocal.state !== state
    ) {
      cartFromLocal = createCart();
    }
    if (cartFromLocal && cartFromLocal !== null) {
      let newProducts = cartFromLocal.products || [];
      let updatedFlag = false;
      newProducts.forEach((newProduct) => {
        if (newProduct.skuCode === product.skuCode) {
          newProduct.quantity = product.quantity;
          newProduct.appliedOffer = product.appliedOffer;
          updatedFlag = true;
        }
      });
      if (updatedFlag === false) {
        newProducts.push(product);
      }

      let subTotal = 0,
        grandTotal = 0;
      newProducts.forEach((product) => {
        subTotal += product.mrp * product.quantity;
        grandTotal += product.sellingPrice * product.quantity;
      });

      let newCart = cartFromLocal;
      newCart.products = newProducts;
      newCart.subTotal = subTotal;
      newCart.grandTotal = grandTotal;
      newCart.discount = subTotal - grandTotal;
      newCart.state = state;
      localStorage?.setItem(`cart`, JSON.stringify(newCart));
    } else {
      console.log(`could not find or create a cart`);
    }
  } else {
    console.log(`invalid quantity or sku`);
  }
}

export function flushCart() {
  localStorage.removeItem(`cart`);
}

export function createCartAtBackend() {
  let cartFromLocal = getCart();

  const cartToCreate = {
    products: cartFromLocal?.products?.map((product) => ({
      skuCode: product.skuCode,
      quantity: parseInt(product.quantity),
      appliedOffer: product.appliedOffer,
    })),
  };

  return axios.put(`/checkout/create-cart-at-backend`, { cartToCreate });
}

export function getCartFromBackend() {
  return apiCall(`/checkout/get-cart-from-backend`);
}

export function createFarmer(farmerInfo) {
  return apiCall(`/checkout/create-farmer`, {}, { farmerInfo });
}

export function getFarmerData() {
  return apiCall(`/checkout/get-farmer-detail`);
}

export async function sendOtp(mobile) {
  const anonEndpoint = `${getEnv('USER_SERVICE')}/userservice/anonauthtoken/`;
  const otpEndpoint = `${getEnv('USER_SERVICE')}/userservice/otp/`;
  const deviceId = Math.random().toString(36).substring(3)

  const resp = await apiCall(anonEndpoint, {}, { androidId: deviceId });
  const anonAuthToken = resp.responseData.authToken;

  return apiCall(`${otpEndpoint}?phoneNumber=${mobile}`, {
    'X-Authorization-Token': anonAuthToken,
    deviceId: deviceId,
  });
}

export function verifyOtp(otp, otpToken) {
  return apiCall(
    `/checkout/verify-otp`,
    {},
    {
      otp: otp,
      otpToken: otpToken,
    },
  );
}

export class AddressLocalisation {
  static getDistricts(source, lang, pincode) {
    return apiCall(
      `/checkout/address-localisation/districts`,
      {},
      { lang, source, pincode },
    );
  }

  static getTalukas(source, lang, pincode, district) {
    return apiCall(
      `/checkout/address-localisation/talukas`,
      {},
      { source, lang, pincode, district },
    );
  }

  static getVillages(source, lang, pincode, district, taluka) {
    return apiCall(
      `/checkout/address-localisation/villages`,
      {},
      { source, lang, pincode, district, taluka },
    );
  }
}

export class Address {
  static addAddress(address, source) {
    return apiCall(`/checkout/add-farmer-address`, {}, { address, source });
  }

  static getFarmerAddress() {
    return apiCall(`/checkout/get-farmer-address`);
  }

  static updateAddress(addressToUpdate) {
    return axios.put(`/checkout/update-farmer-address`, { addressToUpdate });
  }
}

export function createPurchaseRequest() {
  const cartFromLocal = getCart();
  const purchaseRequest = {
    shippingAddressId: cartFromLocal.shippingAddressId,
    shipAddrSameAsBillAddr: true,
    cartId: cartFromLocal.cartId,
  };
  return apiCall(`/checkout/place-order`, {}, { purchaseRequest });
}
