import React, { useEffect, useState } from 'react';
import moment from 'moment';
import Clamp from 'react-multiline-clamp';
import { FormattedMessage } from 'react-intl';

import { East, ShareOutlined } from '@mui/icons-material';
import { Card, Grid, styled, Typography } from '@mui/material';

import useQuery from '../utilities/useQuery';
import pallette from '../constants/pallette';
import { CropInfoSkeleton } from './CropsTabs';
import {
  makeAndroidPostHref,
  makeAndroidProblemSolvedPostHref,
} from '../utilities/links';
import ActivityCountBar from '../charcha/ActivityCountBar';
import ClevertapService from '../services/ClevertapService';
import { SubHeadingWrapper, ViewAllButton } from './ExpertVideos';
import { WhatsappShareButton } from 'react-share';
import AndroidService from '../services/AndroidService';

const PreviewContents = styled('div')({
  padding: '10px',
  header: {
    fontSize: '16px',
    fontWeight: 'bold',
    marginBottom: '12px',
  },
  '.type': {
    fontSize: '16px',
    marginTop: '8px',
  },
});
const AuthorPicContainer = styled('div')({
  position: 'relative',
  minWidth: '50px',
  width: '50px',
  height: '50px',
  display: 'inline-block',
  marginRight: '10px',
  borderRadius: '50%',
  overflow: 'hidden',
  color: 'white',
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: '35px',
  lineHeight: '52px',
  img: {
    objectFit: 'fill',
    height: '100%',
    width: '100%',
  },
});
const AuthorText = styled('div')({
  display: 'inline-block',
  '.name': {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  '.date, .location': {
    fontSize: '13px',
    color: '#666',
  },
});

export default function CropPosts({ loading, cropSchedule }) {
  const query = useQuery();
  const appLinks = query.get('appLinks');
  const farmerId = query.get('farmerId');
  const language = query.get('language');
  const [isAndroid, setIsAndroid] = useState(false);

  useEffect(() => {
    if (AndroidService.checkIfAndroid()) {
      setIsAndroid(true);
    }
  }, []);

  if (loading === 'skeleton') return <CropInfoSkeleton />;
  if (!cropSchedule?.posts?.length) return null;

  return (
    <>
      <SubHeadingWrapper>
        <FormattedMessage id="label_problem_solved_posts" />
      </SubHeadingWrapper>

      <Grid
        container
        flexWrap="nowrap"
        gap="10px"
        sx={{
          mt: 1,
          p: '1px 16px 2px',
          overflowX: 'scroll',
          overflowY: 'hidden',
        }}
      >
        {cropSchedule?.posts?.map((post, index) => {
          const dataValues = {};
          let imageUrl = '';
          post.data.forEach((d) => {
            if (d.type === 'image') {
              imageUrl = d.original_value;
            }
            dataValues[d.type] = d.value;
          });

          let articleType = dataValues.article_type;
          if (articleType) {
            articleType = `${articleType} \u00A0| \u00A0${dataValues.article_source}`;
          }

          const { likes_count: likes, shareCount: shares } = post;
          const { dislikes_count: dislikes, comments_count: comments } =
            post.entityType === 'article' ? {} : post;

          let playIcon = false;
          if (dataValues.youtube) {
            const youtubeId = dataValues.youtube.substr(
              dataValues.youtube.indexOf('=') + 1,
            );
            imageUrl = `https://img.youtube.com/vi/${youtubeId}/default.jpg`;
            playIcon = true;
          }

          const color = pallette[post?.author?.name.charCodeAt(0) % 16];
          let [authorPic] = post?.author?.name;
          if (post?.author?.pic) {
            authorPic = (
              <img
                src={post?.author?.pic}
                layout="fill"
                alt={post?.author?.name}
              />
            );
          }

          return appLinks === 'saathi' ? (
            <Card
              key={index}
              elevation={0}
              sx={{ height: '100%', minWidth: '240px', borderRadius: '8px' }}
              onClick={() =>
                ClevertapService.pushEvent(`Card Clicked`, {
                  Type: 'Post',
                  'Crop Name': cropSchedule?.category,
                  'Post ID': post?._id,
                })
              }
            >
              <PreviewContents>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <AuthorPicContainer sx={{ backgroundColor: color }}>
                    {authorPic}
                  </AuthorPicContainer>
                  <AuthorText>
                    <Clamp lines={1} maxLines={1}>
                      <div className="name">{post?.author.name}</div>
                    </Clamp>
                    <div className="date">
                      {moment(post?.server_epoch).format('DD MMM YY, hh:mm A')}
                    </div>
                  </AuthorText>

                  {isAndroid ? (
                    <div
                      onClick={() => {
                        ClevertapService.pushEvent('Button Clicked', {
                          type: 'Share Post',
                        });
                        AndroidService.shareOnWhatsAppWithoutVersionCheck(
                          dataValues.synopsis || dataValues.text,
                          imageUrl || '',
                        );
                      }}
                    >
                      <ShareOutlined sx={{ color: '#00733E' }} />
                    </div>
                  ) : (
                    <WhatsappShareButton
                      url={`
👉 ${imageUrl || ''}`}
                      title={dataValues.synopsis || dataValues.text}
                      onClick={() => {
                        ClevertapService.pushEvent('Button Clicked', {
                          type: 'Share Post',
                        });
                      }}
                    >
                      <ShareOutlined sx={{ color: '#00733E' }} />
                    </WhatsappShareButton>
                  )}
                </div>
              </PreviewContents>
              <img
                src={imageUrl ? imageUrl + '?tr=h-180' : '/icons/agrostar.png'}
                alt="Post"
                width="240px"
                height="180px"
                style={{ objectFit: 'cover' }}
              />
              <PreviewContents>
                <Clamp
                  lines={2}
                  maxLines={10}
                  withToggle
                  showMoreElement={({ toggle }) => (
                    <span
                      onClick={toggle}
                      style={{
                        fontSize: '12px',
                        fontWeight: '700',
                        color: '#00733E',
                        textDecoration: 'underline',
                      }}
                    >
                      <FormattedMessage id="label_read_more" />
                    </span>
                  )}
                  showLessElement={({ toggle }) => (
                    <span
                      onClick={toggle}
                      style={{
                        fontSize: '12px',
                        fontWeight: '700',
                        color: '#00733E',
                        textDecoration: 'underline',
                      }}
                    >
                      <FormattedMessage id="label_read_less" />
                    </span>
                  )}
                >
                  <Typography fontSize={13}>
                    {dataValues.synopsis || dataValues.text}
                  </Typography>
                </Clamp>
              </PreviewContents>
            </Card>
          ) : (
            <a
              key={index}
              href={makeAndroidPostHref(post?._id)}
              onClick={() =>
                ClevertapService.pushEvent(`Card Clicked`, {
                  Type: 'Post',
                  'Crop Name': cropSchedule?.category,
                  'Post ID': post?._id,
                })
              }
            >
              <Card
                elevation={0}
                sx={{ height: '100%', minWidth: '240px', borderRadius: '8px' }}
              >
                <PreviewContents>
                  <div style={{ display: 'flex' }}>
                    <AuthorPicContainer sx={{ backgroundColor: color }}>
                      {authorPic}
                    </AuthorPicContainer>
                    <AuthorText>
                      <Clamp lines={1} maxLines={1}>
                        <div className="name">{post?.author.name}</div>
                      </Clamp>
                      <div className="date">
                        {moment(post?.server_epoch).format(
                          'DD MMM YY, hh:mm A',
                        )}
                      </div>
                    </AuthorText>
                  </div>
                </PreviewContents>
                <img
                  src={
                    imageUrl ? imageUrl + '?tr=h-180' : '/icons/agrostar.png'
                  }
                  alt="Post"
                  width="240px"
                  height="180px"
                  style={{ objectFit: 'cover' }}
                />
                <PreviewContents>
                  <Clamp lines={1} maxLines={1}>
                    <Typography fontSize={13}>
                      {dataValues.synopsis || dataValues.text}
                    </Typography>
                  </Clamp>

                  <ActivityCountBar
                    likes={likes}
                    dislikes={dislikes}
                    comments={comments}
                    shares={shares}
                  />
                </PreviewContents>
              </Card>
            </a>
          );
        })}
      </Grid>

      {appLinks === 'saathi' ? null : (
        <ViewAllButton
          variant="text"
          endIcon={<East />}
          href={makeAndroidProblemSolvedPostHref(
            language,
            farmerId,
            cropSchedule?.category,
          )}
          onClick={() =>
            ClevertapService.pushEvent(`Button Clicked`, {
              Type: 'View All Solved Posts',
              'Crop Name': cropSchedule?.category,
            })
          }
        >
          <FormattedMessage id="label_view_all_posts" />
        </ViewAllButton>
      )}
    </>
  );
}
