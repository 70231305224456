import React from 'react';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';

const ShowMoreDiv = styled('div')({
  margin: '10px 10px 40px 10px',
  a: {
    border: '1px solid #526cd0',
    padding: '11px 0',
    display: 'block',
    borderRadius: '4px',
    textAlign: 'center',
    fontWeight: 'bold',
    textDecoration: 'none',
    color: '#526cd0',
    marginBottom: '55px',
  },
});

function ShowMore({ href, hasMore }) {
  if (hasMore) {
    return (
      <ShowMoreDiv>
        <Link to={href}>
          <FormattedMessage id="label_view_more" />
        </Link>
      </ShowMoreDiv>
    );
  }
  return null;
}

export default ShowMore;
