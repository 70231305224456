import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage, injectIntl } from 'react-intl';

import {
  Alert,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Edit as EditIcon, ArrowForwardTwoTone } from '@mui/icons-material';
import styled from '@emotion/styled';

import {
  Address,
  createFarmer,
  getFarmerData,
  sendOtp,
  verifyOtp,
} from '../services/cartService';
import { Loader } from '../components/Loader';
import {
  makeShopHref,
  getRouteParams,
  makeCheckoutPagesHref,
} from '../utilities/links';
import { useFetchData } from '../services/useFetchData';
import ServerError from '../components/ServerError';
import store from '../utilities/store';

const Form = styled('form')({
  display: 'flex',
  flexDirection: 'column',
});

function SignIn({ setHeaderURLs, intl }) {
  const [otp, setOtp] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [isOtpAsked, setIsOtpAsked] = useState(false);
  const [otpToken, setOtpToken] = useState('');
  const [otpVerified, setOtpVerified] = useState('not_verified');
  const [isContinue, setIsContinue] = useState(false);
  const [loading, setLoading] = useState('not_started');
  const history = useHistory();

  const { loadingStatus, match, search } = useFetchData(
    SignIn,
    store,
    setHeaderURLs,
    intl,
    true,
  );
  const { lang } = getRouteParams(match, search);

  useEffect(() => {
    const otpVerified = localStorage?.getItem('otpVerified');
    if (otpVerified) {
      history.push(makeShopHref(match, search));
    }
  }, []);

  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;

  const handleChangeMobileNumber = (event) => {
    const value = event.target.value;
    if ((!Number(value) && value !== '') || value.length > 10) return;
    setMobileNumber(value);
  };
  const handleContinueClick = () => {
    setIsContinue(true);
  };

  const handleChangeOtp = async (event) => {
    setOtp(event.target.value);
    setOtpVerified('entered');

    if (event.target.value && event.target.value.length === 4) {
      setLoading('started');
      try {
        const response = await verifyOtp(event.target.value, otpToken);
        if (
          response?.status &&
          response?.message &&
          response.status === true &&
          response.message === 'Otp verified for user'
        ) {
          setOtpVerified('verified');
        }

        if (response && response.message === 'OTP expired or invalid') {
          setOtpVerified('invalid');
        }
        setLoading('done');
      } catch (e) {
        setLoading('done');
        console.log(e.message);
      }
    }
  };
  const handleCompleteClick = async () => {
    if (otpVerified === 'verified') {
      localStorage?.setItem(`otpVerified`, true);
      setIsContinue(false);
      setLoading('started');

      try {
        const response = await getFarmerData();
        if (response?.status === 400) {
          localStorage.removeItem('otpVerified');
          return history.push(makeCheckoutPagesHref(match, search, 'signin'));
        }

        let farmerId, userId;
        if (response.message === 'Farmer not found') {
          const farmerInfo = {
            contactInformation: {
              languages: [lang],
              numbers: [mobileNumber],
              smartphones: [null],
            },
            farmerType: 'Farmer',
            projectType: [],
            firstName: '',
            middleName: '',
            lastName: '',
            heardAbout: 'Website',
            referralCode: null,
          };

          const farmerCreationResponse = await createFarmer(farmerInfo);
          if (farmerCreationResponse?.status === 400) {
            localStorage.removeItem('otpVerified');
            return history.push(makeCheckoutPagesHref(match, search, 'signin'));
          }
          farmerId = farmerCreationResponse.responseData.farmerId;
          userId = farmerCreationResponse.responseData.userId;
        } else {
          farmerId = response.responseData.farmerId;
          userId = response.responseData.userId;
        }

        if (farmerId) {
          const farmerDataResponse = await Address.getFarmerAddress();
          const { billingAddresses } = farmerDataResponse.responseData;

          setLoading('done');
          if (
            billingAddresses &&
            billingAddresses.length > 0 &&
            billingAddresses[0] &&
            billingAddresses[0]?.village &&
            billingAddresses[0]?.district &&
            billingAddresses[0]?.pinCode &&
            billingAddresses[0]?.state
          ) {
            localStorage?.setItem('pinCode', billingAddresses[0]?.pinCode);
            history.push(makeCheckoutPagesHref(match, search, 'checkout'));
          } else {
            history.push(makeCheckoutPagesHref(match, search, 'address'));
          }
        }
      } catch (e) {
        setLoading('done');
        console.log(e.message);
      }
    } else {
      console.log('otp not verified');
    }
  };

  const handleEditMobileClick = () => {
    setIsOtpAsked(false);
    setOtpVerified('not_verified');
    setOtp('');
    setIsContinue(false);
  };

  const handleSendOtp = async () => {
    setLoading('started');
    try {
      const response = await sendOtp(mobileNumber);
      if (response?.responseData?.otpToken) {
        setOtpToken(response.responseData.otpToken);
      } else {
        setOtpToken('');
      }
      setLoading('done');
    } catch (e) {
      setLoading('done');
      console.log(e.message);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (isContinue && mobileNumber && mobileNumber.length === 10) {
      setLoading('started');
      setIsOtpAsked(true);
      handleSendOtp();
    }
  };

  return (
    <>
      <Container
        sx={{
          width: '100%',
          height: '95vh',
          background: 'white',
          padding: '50px 20px',
        }}
      >
        {otpVerified === 'invalid' && (
          <Alert
            variant="filled"
            severity="error"
            sx={{
              position: 'relative',
              top: '-30px',
            }}
          >
            OTP expired or invalid!
          </Alert>
        )}
        <Form onSubmit={handleSubmit}>
          <Grid item xs={12}>
            <Typography variant={'h6'}>
              {isOtpAsked ? (
                <>
                  <FormattedMessage
                    id="please_enter_otp_sent_to_s"
                    values={{
                      mobileNumber: (
                        <Typography
                          variant="span"
                          sx={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            textDecoration: 'underline',
                            color: '#00733E',
                            cursor: 'pointer',
                          }}
                          onClick={handleEditMobileClick}
                        >
                          {mobileNumber}{' '}
                          <EditIcon
                            sx={{
                              marginLeft: '3px',
                              padding: '3px',
                              color: '#ffffff',
                              background: '#00733E',
                              borderRadius: '50%',
                            }}
                          />
                        </Typography>
                      ),
                    }}
                  />
                </>
              ) : (
                <FormattedMessage id="label_enter_your_mobile_number" />
              )}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {isOtpAsked ? (
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                required={true}
                onChange={handleChangeOtp}
                name="otp"
                value={otp}
                sx={{ margin: '10px 0' }}
                inputProps={{
                  type: 'tel',
                  placeholder: 'e.g. 1010',
                  autoFocus: true,
                  minLength: '4',
                  maxLength: '4',
                  pattern: '[0-9]+',
                  title: 'e.g. 1010',
                }}
              />
            ) : (
              <TextField
                id="outlined-basic"
                variant="outlined"
                fullWidth
                required={true}
                onChange={handleChangeMobileNumber}
                name="mobileNumber"
                value={mobileNumber}
                sx={{ margin: '10px 0' }}
                inputProps={{
                  type: 'tel',
                  placeholder: 'e.g. 9999999999',
                  autoFocus: true,
                  minLength: '10',
                  maxLength: '10',
                  pattern: '[0-9]+',
                  title: 'e.g. 9999999999',
                  autoComplete: 'tel',
                }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {isOtpAsked ? (
              <Button
                size="large"
                variant="contained"
                color={`primary`}
                type={'submit'}
                sx={{ width: '100%', margin: '16px 0' }}
                endIcon={<ArrowForwardTwoTone />}
                onClick={() => handleCompleteClick()}
                disabled={
                  !otp ||
                  (otp && otp.length !== 4) ||
                  ((otpVerified === 'not_verified' ||
                    otpVerified === 'verification_failed') &&
                    otpVerified !== 'entered')
                }
              >
                <FormattedMessage id="label_complete" />
              </Button>
            ) : (
              <Button
                size="large"
                variant="contained"
                color={`primary`}
                type={'submit'}
                sx={{ width: '100%', margin: '16px 0' }}
                endIcon={<ArrowForwardTwoTone />}
                onClick={() => handleContinueClick()}
                disabled={!(mobileNumber.length === 10)}
              >
                <FormattedMessage id="label_continue" />
              </Button>
            )}
          </Grid>
        </Form>
        {loading === 'started' && <Loader bgColor="rgb(0,0,0,0.4)" />}
      </Container>
    </>
  );
}

SignIn.fetchData = () => ({
  initialData: ' ',
  titleId: 'label_signIn',
});

export default injectIntl(SignIn);
