import React from 'react';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

import Heading from '../components/Heading';
import ScienceIcon from '@mui/icons-material/Science';
import SchoolIcon from '@mui/icons-material/School';
import SpaIcon from '@mui/icons-material/Spa';
import ReactPlayer from 'react-player';

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  marginBottom: '12px',
  background: '#ffffff',
  lineHeight: '24px',
});

const Label = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: '#000',
  fontSize: '16px',
  fontWeight: '600',
  margin: '0',
});

function BenefitUseTestimonial({ product: { additionalDetails } }) {
  if (!additionalDetails || !additionalDetails.length) {
    return null;
  }
  const videos = [];

  additionalDetails?.forEach((detail) => {
    if (!detail?.data?.media) return null;

    const videoCode = detail?.data?.media[0]?.url?.split('=')[1];
    detail['videoCode'] = videoCode;
    if (detail['value'] === 'BENEFITS') {
      detail['labelHeading'] = 'label_benefits';
      detail['icon'] = <SpaIcon sx={{ mr: '5px' }} />;
      videos[0] = detail;
    } else if (detail['value'] === 'HOW_TO_USE') {
      detail['labelHeading'] = 'label_uses';
      detail['icon'] = <ScienceIcon sx={{ mr: '5px' }} />;
      videos[1] = detail;
    } else {
      detail['labelHeading'] = 'label_testimonial';
      detail['icon'] = <SchoolIcon sx={{ mr: '5px' }} />;
      videos[2] = detail;
    }
  });

  return videos?.map((video) => {
    if (!video?.data?.media) return null;

    return (
      <Wrapper key={video?.value}>
        <Heading>
          <Label>
            {video?.icon}
            <FormattedMessage id={video?.labelHeading} />
          </Label>
        </Heading>
        <ReactPlayer
          width="100%"
          height="250px"
          url={`https://www.youtube.com/embed/${video?.videoCode}`}
        />
      </Wrapper>
    );
  });
}

export default BenefitUseTestimonial;
