/* eslint-disable no-undef */
export function getEnv(envName) {
  let env;
  // __isBrowser__ is set in webpack.config.js using DefinePlugin.
  // The ternary ? : doesn't work well with DefinePlugin for some reason,
  // so we use if else.
  if (__isBrowser__) {
    env = window.__ENV__;
  } else {
    env = process.env;
  }
  return env[envName];
}
