import slugify from './slugify';

/* eslint no-param-reassign: ["error", { "props": false }] */

/*
 * This was originally getting the English headings for an article and adding that
 * as the slug. On further research, it appears that using the local language words
 * in the slug is a better approach, and that is what is recommended.
 * (https://searchengineland.com/google-using-non-english-urls-non-english-websites-fine-294758)
 */

async function addArticleSlugs(posts) {
  const articles = posts.filter((post) => post.entityType === 'article');
  articles.forEach((article) => {
    article.enHeading = article.data.find((d) => d.type === 'heading').value;
  });

  articles.forEach((article) => {
    const heading = article.enHeading || 'Agrostar information article';
    article.slug = slugify(heading);
  });
}

export default addArticleSlugs;
