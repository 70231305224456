import React from 'react';
import { useHistory } from 'react-router-dom';

import { Button, Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { makeShopHref } from '../utilities/links';

function EmptyCart({ image, match, search }) {
  let history = useHistory();

  const handleBackToShop = () => {
    history.push(makeShopHref(match, search));
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: 'calc(100vh - 4rem)' }}
    >
      <Grid item justifyContent="center" xs={12}>
        <img src={image} alt="empty" />
      </Grid>
      <Grid item xs={8} justifyContent="center">
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '1.125rem',
            color: '#000000',
            lineHeight: '1.563rem',
            marginTop: '0.875rem',
          }}
        >
          <FormattedMessage id="label_empty_cart_text" />
        </Typography>
      </Grid>
      <Grid item xs={8} justifyContent="center">
        <Typography
          sx={{
            textAlign: 'center',
            fontSize: '0.875rem',
            color: '#666666',
            lineHeight: '1.188rem',
          }}
        >
          <FormattedMessage id="label_empty_cart_subText" />
        </Typography>
      </Grid>
      <Grid item xs={6} justifyContent="center">
        <Button variant={`text`} onClick={handleBackToShop} color={`primary`}>
          <img
            src={`/activeShopTab.svg`}
            style={{ height: '28px', marginTop: '-2px' }}
            alt="empty"
          />
          <FormattedMessage id="label_empty_cart_shop" />
        </Button>
      </Grid>
    </Grid>
  );
}

export default EmptyCart;
