import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';

import Page from '../src/components/Page';
import store from '../src/utilities/store';

/* eslint-disable no-underscore-dangle */
store.initialData = window.__STORE_DATA__.initialData;
store.languageURLs = window.__STORE_DATA__.languageURLs;
store.stateURLs = window.__STORE_DATA__.stateURLs;
const language = window.__LANGUAGE__;

const element = (
  <Router>
    <Page initialLanguage={language} />
  </Router>
);

ReactDOM.hydrate(element, document.getElementById('root'));
