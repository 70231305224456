import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { Container, Grid, Typography, styled } from '@mui/material';
import { NorthWest } from '@mui/icons-material';

const RecentSearchItem = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '8px',
  background: '#FFF',
  padding: '8px 10px',
  marginBottom: '6px',
  boxShadow: '0px 0px 6px rgba(28, 28, 28, 0.1)',
});

function RecentSearches({ search }) {
  const history = useHistory();
  const query = new URLSearchParams(search);
  const [recentSearches, setRecentSearches] = useState([]);

  useEffect(() => {
    if (localStorage?.getItem(`recentSearches`)) {
      setRecentSearches(JSON.parse(localStorage?.getItem(`recentSearches`)));
    }
  }, []);

  const clearAllRecentSearches = () => {
    localStorage.removeItem(`recentSearches`);
    setRecentSearches([]);
  };

  const recentSearchTermClick = (recentSearch) => {
    query.set('searchTerm', recentSearch);
    history.replace(`/search?${query.toString()}`);
  };

  if (!recentSearches?.length) return null;

  return (
    <Container sx={{ p: 0 }}>
      <Grid
        container
        alignItems={`center`}
        justifyContent={`space-between`}
        sx={{ mb: 1 }}
      >
        <Typography sx={{ color: '#000', fontSize: '16px', fontWeight: 700 }}>
          Recent Searches
        </Typography>
        <Typography
          sx={{
            fontSize: '14px',
            fontWeight: 700,
            color: '#4F4F4F',
            opacity: '0.5',
          }}
          onClick={clearAllRecentSearches}
        >
          Clear All
        </Typography>
      </Grid>

      {recentSearches?.map((recentSearch, index) => (
        <RecentSearchItem
          key={index}
          onClick={() => recentSearchTermClick(recentSearch)}
        >
          <Typography sx={{ pr: '8px', fontSize: '14px', fontWeight: 700 }}>
            {recentSearch}
          </Typography>
          <NorthWest sx={{ opacity: 0.6 }} />
        </RecentSearchItem>
      ))}
    </Container>
  );
}

export default RecentSearches;
