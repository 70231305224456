import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

export const useFetchData = (page, store, setHeaderURLs, intl, noHead) => {
  const [data, setData] = useState(store.initialData);
  const [loadingStatus, setLoadingStatus] = useState(
    data !== null && data !== undefined ? 'loaded' : 'loading',
  );
  const params = useParams();
  const location = useLocation();
  const match = { ...location, params };
  const { pathname, search } = location;

  useEffect(() => {
    (async function () {
      if (store.initialData == null) {
        try {
          setLoadingStatus('loading');

          const {
            initialData,
            titleId,
            title,
            titleParams,
            stateURLs,
            languageURLs,
          } = await page.fetchData({ params }, location.search);

          if (!title && !titleId) {
            document.title =
              intl?.formatMessage({ id: 'app_name' }) || 'Agrostar';
          } else {
            document.title = title
              ? title
              : intl?.formatMessage({ id: titleId }, titleParams);
          }

          if (!noHead) {
            setHeaderURLs({ stateURLs, languageURLs });
          }

          setData(initialData);
          setLoadingStatus('loaded');
        } catch (e) {
          document.title =
            intl?.formatMessage({ id: 'label_lal_dukan' }) || 'AgroStar';
          console.log(`Fail to fetch page: `, e.message);
          setLoadingStatus('error');
        }
      } else {
        store.initialData = null;
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, search]);

  return { data, loadingStatus, match, search };
};
