import React from 'react';
import { styled } from '@mui/material/styles';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { Link, withRouter } from 'react-router-dom';
import moment from 'moment';
import ActivityCountBar from './ActivityCountBar';
import { makeArticleHref } from '../utilities/links';

const SmallPostPreviewWrapper = styled('div')({
  backgroundColor: '#ffffff',
  margin: '0 10px 10px 10px',
  borderRadius: '5px',
});

const GridWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: '0.2fr 5fr',
});

const ImageContainer = styled('div')({
  position: 'relative',
  height: '8.5rem',
  width: '6rem',
  img: {
    objectFit: 'cover',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    width: '100%',
    height: '100%',
  },
});

const PlayIcon = styled(PlayArrowRoundedIcon)({
  position: 'absolute',
  zIndex: '2',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  opacity: '0.9',
  color: 'white',
  fontSize: '2rem',
});

const InfoWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '0 1rem',
  flexWrap: 'wrap',
});

const ArticleType = styled('div')({
  color: 'grey',
});

const ArticleSource = styled('div')({
  color: 'green',
});

const PostDate = styled('div')({
  color: 'grey',
  margin: '0.3rem 0 0.3rem 0.3rem',
  fontSize: '0.8rem',
});

const ActivityCountBarWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

function SmallArticlePreview({ post, match, location: { search } }) {
  const dataValues = {};
  post.data.forEach((d) => {
    dataValues[d.type] = d.value;
  });

  const href = makeArticleHref(
    match,
    search,
    post.slug,
    post.parent_article_id,
  );
  // todo: Newlines need to be maintained within the text

  let articleType = dataValues.article_type;
  if (articleType) {
    articleType = `${articleType} \u00A0| \u00A0${dataValues.article_source}`;
  }

  const { likes_count: likes, shareCount: shares } = post;
  const { comments_count: comments } = post;

  let imageUrl = dataValues.image;
  let playIcon = false;
  if (dataValues.youtube) {
    const youtubeId = dataValues.youtube.substr(
      dataValues.youtube.indexOf('=') + 1,
    );
    imageUrl = `https://img.youtube.com/vi/${youtubeId}/0.jpg`;
    playIcon = true;
  }
  const postDateObj = new Date(post.client_epoch);
  const postDate = moment(postDateObj).format('DD MMM YY, hh:mm A');

  return (
    <SmallPostPreviewWrapper>
      <GridWrapper>
        <Link to={href}>
          <ImageContainer>
            {playIcon ? <PlayIcon /> : null}
            <img alt={dataValues.heading || post.author} src={imageUrl} />
          </ImageContainer>
        </Link>
        <InfoWrapper>
          <Link to={href}>
            <ArticleType>{dataValues.article_type}</ArticleType>
            <b>
              {dataValues.heading ? (
                <header>{dataValues.heading}</header>
              ) : null}
            </b>
            <PostDate>{postDate}</PostDate>
            <ArticleSource>{dataValues.article_source}</ArticleSource>
            <ActivityCountBarWrapper>
              <ActivityCountBar
                likes={likes}
                comments={comments}
                shares={shares}
                display="flex"
                justify="space-between"
                width="auto"
                align="center"
              />
            </ActivityCountBarWrapper>
          </Link>
        </InfoWrapper>
      </GridWrapper>
    </SmallPostPreviewWrapper>
  );
}

export default withRouter(SmallArticlePreview);
