import React from 'react';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { Container } from '@mui/material';
import { makePolicyHref } from '../utilities/links';
import styled from '@emotion/styled';

const Separator = styled('span')({
  margin: '0 10px',
});

function ProductFooter({ match, search }) {
  return (
    <Container
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '20px 0 70px',
        a: { fontSize: '.7rem' },
      }}
    >
      <Link to={makePolicyHref(match, search, 'terms-conditions')}>
        <FormattedMessage id="label_agrostar_tandc" />
      </Link>
      <Separator>|</Separator>
      <Link to={makePolicyHref(match, search, 'return-refund')}>
        <FormattedMessage id="label_return-refund_policy" />
      </Link>
      <Separator>|</Separator>
      <a href="https://www.corporate.agrostar.in">Corporate Website</a>
    </Container>
  );
}

export default ProductFooter;
