export const languages = ['en', 'hi', 'gu', 'mr', 'te', 'kn'];
export const shopLanguages = ['en', 'hi', 'gu', 'mr'];

export const languageLabels = {
  en: 'English',
  hi: 'हिन्दी (Hindi)',
  mr: 'मराठी (Marathi)',
  gu: 'ગુજરાતી (Gujarati)',
  kn: 'ಕನ್ನಡ (Kannada)',
  te: 'తెలుగు (Telugu)',
};

export const languagesExceptEn = languages.slice(1);

export function getLanguageLabel(code) {
  return languageLabels[code];
}

export function isLanguageSupported(code) {
  return languageLabels[code] != null;
}

export function getLocalDateString(date, lang, type) {
  switch (type) {
    case 'day':
    case 'year':
      return new Date(date).toLocaleDateString('en', { [type]: 'numeric' });
    case 'month':
      return new Date(date).toLocaleDateString(lang, { [type]: 'long' });
    default:
      return '';
  }
}
