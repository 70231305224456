import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import { makeBrandHref } from '../utilities/links';
import slugify from '../utilities/slugify';

const BrandCardWrapper = styled(Link)({
  position: 'relative',
  width: '100%',
  border: '1px solid #cccccc',
  borderRadius: '4px',
  background: '#ffffff',
  img: {
    objectFit: 'contain',
    height: '100%',
    width: '100%',
  },
});

export const BrandListWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'repeat(3, 1fr)',
  gridGap: 5,
});

const BrandCard = ({
  match,
  location: { search },
  brand: { brandImage, brandName, brandId },
}) => {
  const href = makeBrandHref(match, search, slugify(brandName), brandId);
  return (
    <BrandCardWrapper to={href}>
      <img src={brandImage} alt={brandName} width="100px" height="100px" />
    </BrandCardWrapper>
  );
};

export default withRouter(BrandCard);
