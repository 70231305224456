import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import apiCall from '../utilities/apiCall';
import store from '../utilities/store';
import Post from './Post';
import PageNotFound from '../misc/NotFoundPage';
import { getRouteParams } from '../utilities/links';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { getEnv } from '../utilities/getEnv';

function PostPage({ setHeaderURLs, intl }) {
  const { data, loadingStatus, match, search } = useFetchData(
    PostPage,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;

  if (!data || !data.post) {
    return <PageNotFound />;
  }

  const { post, comments } = data;
  const { language, userstate } = getRouteParams(match, search);

  if (post === undefined) {
    return <FormattedMessage id="post_article_not_found" />;
  }

  return (
    <Post
      post={post}
      comments={comments}
      lang={language}
      userstate={userstate}
    />
  );
}

PostPage.fetchData = async (match, search) => {
  const { id, language } = getRouteParams(match, search);

  const postAPIUrl = new URL(
    `${getEnv('FWA_API_URL')}/communicationservice/v2/post/${id}/details`,
  );
  postAPIUrl.searchParams.append('language', language);
  const postResult = await apiCall(postAPIUrl.href);

  if (!postResult || !postResult.responseData.post) {
    return {
      titleId: 'post_article_not_found',
    };
  }

  const { post } = postResult.responseData;
  const commentsUrl = new URL(
    `${getEnv(
      'FWA_API_URL',
    )}/communicationservice/v2/comment?topic_id=${id}&topic_type=post`,
  );
  commentsUrl.searchParams.append('language', language);
  commentsUrl.searchParams.append('order', 0);
  commentsUrl.searchParams.append('offset', 0);
  commentsUrl.searchParams.append('limit', 10);
  const commentsResult = await apiCall(commentsUrl.href);

  const { comments } = commentsResult.responseData;

  for (let i = 0; i < comments.length; i += 1) {
    const comment = comments[i];
    if (comment.comments_count > 0) {
      const commentId = comment._id; // eslint-disable-line no-underscore-dangle
      const subCommentsUrl = new URL(
        `${getEnv(
          'FWA_API_URL',
        )}/communicationservice/v2/comment?topic_id=${commentId}&topic_type=comment`,
      );

      subCommentsUrl.searchParams.append('offset', 0);
      subCommentsUrl.searchParams.append('limit', 5);
      // eslint-disable-next-line no-await-in-loop
      const subCommentsResult = await apiCall(subCommentsUrl.href);
      comment.subComments = subCommentsResult.responseData.comments;
    }
  }

  const titleId =
    post.author.location.district && post.author.location.taluka
      ? 'title_post_details'
      : 'title_post';
  const titleParams = {
    tag_list: post.tags[0].label,
    taluka: post.author.location.taluka,
    district: post.author.location.district,
    farmer: post.author.name,
  };

  const dataValues = [];
  post.data.forEach((d) => {
    dataValues[d.type] = d.value;
  });
  const ogTagData = {
    description: dataValues.text,
    imageUrl: dataValues.image,
    type: 'post',
  };

  const initialData = { post, comments };
  // The content of a post is the same regardless of state and language, so the canonical
  // must point to the stateless and default language version.
  const canonical = 'nstatelang';
  return {
    initialData,
    titleId,
    titleParams,
    seoData: { ogTagData },
    canonical,
  };
};

export default injectIntl(PostPage);
