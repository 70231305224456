import React from 'react';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import StarsProductHeader from '../components/StarsProductHeader';

const HeaderWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  fontSize: '14px',
  padding: '0 15px',
  background: '#ffffff',
  lineHeight: '24px',
});

const BrandRatingWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const BrandWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '50%',
  div: {
    width: '80px',
    height: '80px',
    margin: '0 10px 0 0',
    img: {
      width: '100%',
    },
  },
});

const RatingWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'right',
  span: {
    color: '#4caf50',
    padding: '0 5px 0 0',
  },
});

const ProductName = styled('h1')({
  fontSize: '18px',
  fontWeight: 'bold',
  padding: '0px',
  margin: '0px',
  color: '#000',
});

function ProductHeader({
  product: {
    productName,
    brand,
    brandImage,
    rating: { average: avgRating, totalCount },
  },
}) {
  return (
    <HeaderWrapper>
      <BrandRatingWrapper>
        <BrandWrapper>
          <div>
            <img src={brandImage} alt="" />
          </div>
          <span>{brand}</span>
        </BrandWrapper>
        {avgRating !== 0 && totalCount !== 0 && (
          <RatingWrapper>
            <StarsProductHeader stars={avgRating} />
            <span>
              {totalCount} <FormattedMessage id="label_header_farmers" />
            </span>
          </RatingWrapper>
        )}
      </BrandRatingWrapper>
      <ProductName>{productName}</ProductName>
    </HeaderWrapper>
  );
}

export default ProductHeader;
