import React from 'react';

import apiCall from '../utilities/apiCall';
import store from '../utilities/store';

import ShowMore from '../components/ShowMore';
import { getStateBySlug } from '../utilities/states';
import { slugToTag } from '../utilities/tagsData';
import { getRouteParams, changePage } from '../utilities/links';
import addArticleSlugs from '../utilities/addArticleSlugs';

import CorporateBar from '../components/CorporateBar.js';

import PostPreview from './PostPreview';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { injectIntl } from 'react-intl';
import { getEnv } from '../utilities/getEnv';

function PostsListing({ setHeaderURLs, intl }) {
  const { data, loadingStatus, match, search } = useFetchData(
    PostsListing,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;
  const { posts } = data;
  const { partner, page } = getRouteParams(match, search);

  let bannerImg;
  if (partner === 'sbi') {
    bannerImg = '/Banner-SBIYono-2.png';
  } else if (partner === 'bob') {
    bannerImg = '/Banner-BoB-3.png';
  }

  const banner = bannerImg ? (
    <img width="600" height="300" src={bannerImg} alt="SBI Yono" />
  ) : null;

  return (
    <>
      <CorporateBar />
      {banner}
      {posts?.slice(0, 20).map((post, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <PostPreview post={post} key={index} />
      ))}
      <ShowMore
        href={changePage(match, search, page + 1)}
        hasMore={posts?.length > 20 && page < 5}
      />
    </>
  );
}

PostsListing.fetchData = async (match, search) => {
  const { language, state, type, topic, sort, page, postIds } = getRouteParams(
    match,
    search,
  );

  const titleId = `title_${sort}_posts`;

  // todo: keep a js file since fs is not available in SPA
  // if (sort === 'test') {
  //   // For quick testing mock data, also if API server is slow, this is quick
  //   const postString = fs.readFileSync('assets/mockdata/feed.json', 'utf-8');
  //   const result = JSON.parse(postString);
  //   return { initialData: result.responseData, title };
  // }

  const offset = (page - 1) * 20;
  const url = new URL(`${getEnv('FWA_API_URL')}/communicationservice/v2/post`);
  url.searchParams.append('offset', offset);
  url.searchParams.append('limit', 21);
  url.searchParams.append('language', language);
  url.searchParams.append('curated', 'false');

  if (type === 'postlist') {
    // A list of posts, given the IDs
    url.searchParams.append('postIds', postIds);
  }

  if (sort === 'popular') {
    const popularEvalDays = getEnv('FWA_POPULAR_EVAL_DAYS') || 15;
    url.searchParams.append('sortBy', 'rank');
    url.searchParams.append('order', 1);
    // limit it to 30 days' worth of posts
    const day = 24 * 60 * 60 * 1000;
    const today = new Date();
    const startDate = new Date(today.getTime() - popularEvalDays * day);
    url.searchParams.append('createdOnGt', startDate.getTime());
  }

  if (topic && topic !== 'all') {
    url.searchParams.append('tags', await slugToTag(topic));
  }

  if (type === 'articles') {
    url.searchParams.append('entityType', 'article');
  } else if (type === 'posts') {
    url.searchParams.append('entityType', 'post');
  }

  const source = state === 'all' ? 'APP' : getStateBySlug(state).source;

  const response = await apiCall(url.href, { Source: source });
  if (!response.responseData) {
    console.error('No response from API call to', url.href, '\n', response);
    throw new Error('Empty response');
  }
  const {
    responseData: { posts },
  } = response;
  await addArticleSlugs(posts);

  // Canonical is self because the list of articles can change depending on the state
  return { initialData: { posts }, titleId, canonical: 'self' };
};

export default injectIntl(PostsListing);
