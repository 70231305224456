import React, { useEffect, useState } from 'react';
import Clamp from 'react-multiline-clamp';
import { FormattedMessage } from 'react-intl';

import {
  Badge,
  Button,
  Grid,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';

import styles from './styles';
import { Box } from '@mui/system';
import { GoldVideo, SubHeadingWrapper } from './ExpertVideos';
import useQuery from '../utilities/useQuery';
import CropIssues from './CropIssues';
import CropPosts from './CropPosts';
import CropProducts from './CropProducts';
import CropTasks from './CropTasks';
import { Link } from 'react-router-dom';
import ClevertapService from '../services/ClevertapService';
import AndroidService from '../services/AndroidService';
import { Edit } from '@mui/icons-material';

export function CropsTabs({ value, handleChange, cropSchedules }) {
  if (!cropSchedules?.length) return null;

  return (
    <Tabs
      value={value}
      variant="scrollable"
      onChange={handleChange}
      aria-label="tabs"
      sx={{ borderBottom: '0.4px solid #C7CAC9', background: '#fff' }}
    >
      {cropSchedules?.map((cropSchedule, index) => (
        <Tab
          key={index}
          value={index}
          label={
            <Box>
              {cropSchedule?.tasks?.length ? (
                <Badge
                  badgeContent={cropSchedule?.totalCurrentTask}
                  color="primary"
                  sx={{ position: 'absolute', top: '20px', right: '20px' }}
                ></Badge>
              ) : (
                ''
              )}
              <img
                src={
                  cropSchedule?.imageUrl
                    ? cropSchedule?.imageUrl
                    : '/icons/agrostar.png'
                }
                alt={cropSchedule?.cropName}
                width="60px"
                height="60px"
                style={{ borderRadius: '50%' }}
              />
              <Clamp lines={1} maxLines={1}>
                <Typography fontSize={12}>{cropSchedule?.cropName}</Typography>
              </Clamp>
            </Box>
          }
          sx={
            value === index
              ? styles.selectedCrop
              : { maxWidth: '100px', textTransform: 'none' }
          }
        />
      ))}
    </Tabs>
  );
}

export function TabPanel({
  match,
  search,
  value,
  loading,
  toggleDrawer,
  cropSchedules,
}) {
  const query = useQuery();
  const showTasks = query.get('showTasks') === 'false' ? false : true;
  const cropSchedule = cropSchedules?.[value];
  const [isAndroid, setIsAndroid] = useState(false);
  useEffect(() => {
    if (AndroidService.checkIfAndroid()) {
      setIsAndroid(true);
    }
  }, []);

  return (
    <div id="tab-panel">
      {showTasks && cropSchedule?.sowingDateMillis ? (
        <>
          <SubHeadingWrapper>
            <FormattedMessage id="label_crop_info" />
          </SubHeadingWrapper>
          <Box sx={styles.askToUpdateCropDetailsBox}>
            <Typography fontSize={15} fontWeight={700}>
              <FormattedMessage
                id="label_sowing_date"
                values={{
                  sowingDate: (
                    <b
                      style={{
                        color: cropSchedule?.datesAreAssumed
                          ? '#9D2123'
                          : '#00733E',
                      }}
                    >
                      {cropSchedule?.sowingDate + ' '}
                      {cropSchedule?.datesAreAssumed && (
                        <>
                          (<FormattedMessage id="label_assumed" />)
                        </>
                      )}
                    </b>
                  ),
                }}
              />
            </Typography>

            {cropSchedule?.cropDetails?.map((attribute, index) => (
              <Typography key={index} fontSize={15}>
                <b>{attribute?.name?.display}</b>:{' '}
                <b
                  style={{
                    color: cropSchedule?.inputsComplete ? '#00733E' : '#9D2123',
                  }}
                >
                  {attribute?.value?.display}
                </b>
              </Typography>
            ))}

            {!cropSchedule?.datesAreAssumed && cropSchedule?.inputsComplete ? (
              ''
            ) : (
              <Typography fontSize={13} sx={{ mt: '4px' }}>
                <FormattedMessage id="label_provide_info_about_crop_message" />
              </Typography>
            )}

            <Link
              to={`/crop-info/attributes/${
                cropSchedule?.category
              }?${query.toString()}`}
              onClick={() =>
                ClevertapService.pushEvent(`Button Clicked`, {
                  Type: 'Provide Crop Information',
                  'Crop Name': cropSchedule?.category,
                })
              }
            >
              {!cropSchedule?.datesAreAssumed &&
              cropSchedule?.inputsComplete ? (
                <Typography sx={styles.changeInformation}>
                  <Edit sx={{ mr: '4px', fontSize: '14px' }} />
                  <FormattedMessage id="label_change" />
                </Typography>
              ) : (
                <Button
                  fullWidth
                  variant="contained"
                  sx={styles.enterInformation}
                >
                  <FormattedMessage id="label_enter_information" />
                </Button>
              )}
            </Link>
          </Box>
        </>
      ) : null}
      <GoldVideo
        index={value}
        loading={loading}
        videos={cropSchedule?.videos}
        cropName={cropSchedule?.category}
        displayCropName={cropSchedule?.cropName}
      />

      <CropTasks
        index={value}
        match={match}
        search={search}
        isAndroid={isAndroid}
        cropSchedule={cropSchedule}
      />

      <CropProducts
        match={match}
        search={search}
        loading={loading}
        cropSchedule={cropSchedule}
      />

      <CropIssues
        match={match}
        search={search}
        loading={loading}
        isAndroid={isAndroid}
        toggleDrawer={toggleDrawer}
        cropSchedule={cropSchedule}
      />

      <CropPosts value={value} loading={loading} cropSchedule={cropSchedule} />
    </div>
  );
}

export function CropInfoSkeleton() {
  return (
    <>
      <Skeleton variant="text" />
      <Grid container justifyContent="space-between" sx={{ mt: 1, mb: 1 }}>
        <Grid item xs={5.8}>
          <Skeleton
            variant="rectangular"
            sx={{ height: '150px', borderRadius: '8px' }}
          />
        </Grid>
        <Grid item xs={5.8}>
          <Skeleton
            variant="rectangular"
            sx={{ height: '150px', borderRadius: '8px' }}
          />
        </Grid>
      </Grid>
      <Skeleton variant="text" height={60} />
    </>
  );
}

export function convertToDayStartMillis(millis) {
  const date = new Date(millis);
  return new Date(
    `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()}`,
  ).getTime();
}
