const styles = {
  pageWrapper: {
    paddingBottom: '30px',
    // background: '#fff',
    minHeight: '100vh',
  },
  changeInformation: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    top: '10px',
    right: '10px',
    padding: '1px 8px',
    color: '#00733E',
    borderRadius: '8px',
    background: '#fff',
  },
  enterInformation: {
    marginTop: '8px',
    fontSize: '15px',
    fontWeight: '700',
    color: '#00733E',
    background: '#fff',
    border: '1px solid #00733E',
    borderRadius: '8px',
    '&:hover': { background: '#fff' },
  },
  askToUpdateCropDetailsBox: {
    position: 'relative',
    margin: '6px 16px 0',
    padding: '10px 16px',
    background: 'rgba(0, 115, 62, 0.3)',
    border: '1px solid #00733E',
    borderRadius: '8px',
  },
  subTitleWrapper: {
    fontSize: '18px',
    fontWeight: '700',
    marginLeft: '10px',
  },
  subTextWrapper: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#666',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '32px',
    width: '32px',
    height: '32px',
    background: 'rgb(157, 33, 35, 0.2)',
    borderRadius: '50%',
  },
  iconStyles: { width: '16px', height: '16px', color: '#9D2123' },
  dosAndDonts: {
    fontSize: '14px',
    fontWeight: '500',
    color: '#666',
    padding: '0 0 0 10px',
    margin: 0,
  },
  videoWrapper: {
    background: '#fff',
    textAlign: 'center',
    '.carousel': {
      height: 170,
    },
    '.video': {
      position: 'relative',
      width: '100%',
      height: 170,
      borderRadius: '10px',
      overflow: 'hidden',
    },
  },
  selectCrop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 16px 2px',
    background: '#fff',
  },
  selectedCrop: {
    maxWidth: '100px',
    background: '#fff',
    textTransform: 'none',
    border: '1px solid #D9D9D9',
    borderTopLeftRadius: '10px',
    borderTopRightRadius: '10px',
    boxShadow: 'inset 0px 4px 6px rgb(58 61 63 / 10%)',
  },
  krishiCalendarText: {
    fontSize: '18px',
    padding: '20px 20px 10px',
    borderBottom: '0.4px solid #C7CAC9',
  },
  taskCard: {
    height: '100%',
    borderRadius: '8px',
  },
  titleFlex: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'start',
    padding: '15px 15px 0',
  },
  importantTaskIdentifier: {
    fontSize: '10px',
    fontWeight: '700',
    padding: '5px 12px',
    color: '#ad1321',
    background: '#f0d2d2',
    borderRadius: '8px',
  },
  cardBadge: {
    '.MuiBadge-badge': {
      minWidth: '16px !important',
      top: '8px',
      right: '4px',
      background: '#FFCB5B',
    },
  },
  localMall: { width: '30px', height: '30px', color: '#9D2123' },
  timeLine: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInlineStart: '0',
    paddingBlockStart: '1.5em',
  },
  timeLineDot: {
    width: '40px',
    height: '40px',
    color: '#fff',
    display: 'flex',
    position: 'relative',
    zIndex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    background: '#00733E',
    borderRadius: '50%',
  },
  timeLineContent: {
    width: '100%',
    textAlign: 'center',
    position: 'absolute',
    padding: '2px 0',
    top: '-24px',
  },
  timelineItem: { position: 'relative', listStyle: 'none' },
  timeLineItemLine: {
    listStyle: 'none',
    width: '2px',
    height: '78%',
    margin: 'auto',
    display: 'flex',
    position: 'absolute',
    background: '#00733E',
  },
  readMore: {
    fontSize: '12px',
    fontWeight: '700',
    color: '#00733E',
    textDecoration: 'underline',
  },
  postCreationKisaan: {
    position: 'relative',
    height: '128px',
    margin: '16px',
    padding: '10px 16px',
    background: "url('/icons/postCreationBackground.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '10px',
  },
  technicalKisaan: {
    display: 'flex',
    gap: '20px',
    height: '100px',
    margin: '16px',
    padding: '10px 20px',
    background: "url('/taskPageWeatherBg.svg')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '10px',
  },
  issues: {
    padding: '0 20px 0',
  },
  iconStyles: { width: '20px', height: '20px', color: '#9D2123' },
};

export default styles;
