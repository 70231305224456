import React from 'react';
import { injectIntl } from 'react-intl';

import { Grid, styled, Typography } from '@mui/material';

import store from '../utilities/store';
import { getCropDetails } from './CropPage';
import { getRouteParams } from '../utilities/links';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import strings from '../utilities/strings';
import { IssueCard } from '../crop-schedule/CropIssues';

const IssuesPageWrapper = styled('div')({
  padding: '15px 15px 30px',
  background: '#f1f1f1',
  minHeight: '100vh',
});

function IssueListingPage({ setHeaderURLs, intl }) {
  const { data, loadingStatus, match, search } = useFetchData(
    IssueListingPage,
    store,
    setHeaderURLs,
    intl,
  );

  const label = data?.issues?.data?.label;
  const content = data?.issues?.data?.content;

  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error' || !data) return <ServerError />;
  return (
    <IssuesPageWrapper>
      <Typography variant="h2" fontSize={16} fontWeight={700} sx={{ pb: 1 }}>
        {data?.categoryName} - {label}
      </Typography>

      <Grid container justifyContent="space-between" gap="10px">
        {content?.map((issue, index) => (
          <IssueCard
            key={index}
            issue={issue}
            match={match}
            search={search}
            width="48.3%"
            height=""
          />
        ))}
      </Grid>
    </IssuesPageWrapper>
  );
}

IssueListingPage.fetchData = async (match, search) => {
  const { id, language, state } = getRouteParams(match, search);

  const cropConfigurationResponse = await getCropDetails(id, state, language);

  const cropName = cropConfigurationResponse?.responseData?.categoryName;
  const titleId = 'title_shop';
  const titleParams = {
    heading: `${cropName} - ${strings[language]?.label_issue}`,
  };
  const languageURLs = {};
  const stateURLs = {};
  return {
    initialData: { ...cropConfigurationResponse?.responseData },
    titleId,
    titleParams,
    seoData: {},
    languageURLs,
    stateURLs,
  };
};

export default injectIntl(IssueListingPage);
