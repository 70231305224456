import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import GTranslateIcon from '@mui/icons-material/GTranslate';

import { getStateBySlug } from '../utilities/states';
import { getLanguageLabel } from '../utilities/languages';
import {
  changeLanguage,
  getDefaultPageForState,
  makeArticleNotAvailableHref,
} from '../utilities/links';

function LanguageMenu({ match, search, state, languageURLs }) {
  const { supportedLanguages } = getStateBySlug(state);

  let languageLinks;
  if (!languageURLs) {
    // OK to switch between all supported languages
    languageLinks = supportedLanguages.map((lang) => ({
      lang,
      href: changeLanguage(match, search, lang),
    }));
  } else if (languageURLs === 'home') {
    // Can also use for error cases, eg, article not found.
    languageLinks = supportedLanguages.map((lang) => ({
      lang,
      href: getDefaultPageForState(state, lang),
    }));
  } else {
    languageLinks = supportedLanguages.map((lang) => {
      const url = languageURLs[lang];
      const href =
        url === 'languageNa'
          ? makeArticleNotAvailableHref(match, search, state, lang)
          : url;
      return { lang, href };
    });
  }

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const handleButtonClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <IconButton
        size="large"
        aria-label="Language"
        aria-haspopup="true"
        color="inherit"
        onClick={handleButtonClick}
        sx={{ padding: '8px' }}
      >
        <GTranslateIcon />
      </IconButton>
      <Menu
        id="menu-language"
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        {languageLinks.map((l) => (
          <MenuItem key={l.lang}>
            <Link to={l.href} onClick={handleMenuClose}>
              {getLanguageLabel(l.lang)}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default LanguageMenu;
