/* eslint-disable no-undef */
import axios from 'axios';

let numCalls = 0;

function log(...params) {
  if (!__isBrowser__) {
    console.log(...params);
  }
}

async function apiCall(url, extraHeaders, postObject, method = null) {
  numCalls += 1;
  const callId = numCalls;
  const headers = {
    'Content-Type': 'application/json',
    ...extraHeaders,
  };

  let response;
  const begin = new Date().getTime();
  try {
    log('Calling API', callId, url, headers);
    if (!method) {
      method = postObject ? 'post' : 'get';
    }

    response = await axios({
      url,
      headers,
      method,
      data: postObject,
      validateStatus: () => true,
    });
    if (response?.status !== 200) {
      log(`${response.status} status returned from API: ${url}`);
      log('Response data:', response?.data);
    }
    return response?.data;
  } catch (e) {
    log(
      'Error calling API',
      method,
      callId,
      url,
      e?.response?.status,
      e?.response?.data,
    );
    log('Response:', response);
    throw e;
  } finally {
    const end = new Date().getTime();
    log(`API ${callId} call took ${end - begin} ms`);
  }
}

export default apiCall;
