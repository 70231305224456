import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { injectIntl } from 'react-intl';

import { Container } from '@mui/material';

import {
  createCartAtBackend,
  createPurchaseRequest,
  flushCart,
  getCart,
  updateCartWithId,
  updateCartWithShippingAddressId,
  Address,
} from '../services/cartService';

import { Loader } from '../components/Loader';
import PaymentForm from './PaymentForm';
import Review from './Review';
import {
  makeBuyNowSuccessHref,
  makeCheckoutPagesHref,
} from '../utilities/links';
import { useFetchData } from '../services/useFetchData';
import store from '../utilities/store';
import ServerError from '../components/ServerError';

function Checkout({ setHeaderURLs, intl }) {
  const [isSignedIn, setIsSignedIn] = useState(false);
  const [shippingAddress, setShippingAddress] = useState({});
  const [loading, setLoading] = useState('not_started');
  const history = useHistory();

  const findAddress = async () => {
    setLoading('started');
    try {
      const addressResponse = await Address.getFarmerAddress();
      if (addressResponse?.status === 400) {
        localStorage.removeItem('otpVerified');
        return history.replace(makeCheckoutPagesHref(match, search, 'signin'));
      }
      const billingAddresses = addressResponse?.responseData?.billingAddresses;
      const shippingAddresses =
        addressResponse?.responseData?.shippingAddresses;

      if (shippingAddresses[0]?.SAddressId) {
        setShippingAddress({ ...shippingAddresses[0] });
        setLoading('done');
      } else if (billingAddresses[0]?.SAddressId) {
        if (billingAddresses?.length > 1) {
          setShippingAddress({ ...billingAddresses[1] });
        } else {
          setShippingAddress({ ...billingAddresses[0] });
        }
        setLoading('done');
      } else {
        setLoading('done');
        console.log(`no address found`);
        history.replace(makeCheckoutPagesHref(match, search, 'address'));
      }
    } catch (e) {
      setLoading('done');
      console.log('Fail to find address :', e.message);
      history.replace(makeCheckoutPagesHref(match, search, 'address'));
    }
  };

  useEffect(() => {
    const cartFromLocal = getCart();
    const otpVerified = localStorage?.getItem('otpVerified');
    if (otpVerified && cartFromLocal?.products?.length > 0) {
      setIsSignedIn('signedIn');
      findAddress();
    } else {
      setIsSignedIn('not_signedIn');
      history.replace(makeCheckoutPagesHref(match, search, 'signin'));
    }
  }, []);

  const { loadingStatus, match, search } = useFetchData(
    Checkout,
    store,
    setHeaderURLs,
    intl,
    true,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;

  const handlePlaceOrder = async () => {
    setLoading('started');
    try {
      const cartCreated = await createCartAtBackend();
      if (cartCreated?.status === 400) {
        localStorage.removeItem('otpVerified');
        return history.replace(makeCheckoutPagesHref(match, search, 'signin'));
      }
      updateCartWithId(cartCreated.data.cartId);
      updateCartWithShippingAddressId(shippingAddress.SAddressId);
      await createPurchaseRequest();
      flushCart();
      setLoading('done');
      history.push(makeBuyNowSuccessHref(match, search));
    } catch (e) {
      setLoading('done');
      console.log('Failed to create order: ', e.message);
    }
  };

  return (
    <Container
      sx={{
        position: 'relative',
        minHeight: '95vh',
        background: 'white',
        padding: '30px 20px',
        overflowY: 'scroll',
      }}
    >
      {isSignedIn === 'signedIn' &&
      shippingAddress &&
      shippingAddress.pinCode ? (
        <Container sx={{ padding: '0 0 50px' }}>
          <PaymentForm />
          <Review
            match={match}
            search={search}
            shippingAddress={shippingAddress}
            setShippingAddress={setShippingAddress}
            handlePlaceOrder={handlePlaceOrder}
          />
        </Container>
      ) : loading === 'done' ? (
        <ServerError />
      ) : (
        ''
      )}
      {loading === 'started' && <Loader bgColor="rgb(0,0,0,0.4)" />}
    </Container>
  );
}

Checkout.fetchData = () => ({
  initialData: ' ',
  titleId: 'label_checkout',
});

export default injectIntl(Checkout);
