import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';

function PaymentForm() {
  return (
    <FormControl>
      <FormLabel id="radio-buttons-group-label">
        <FormattedMessage id="label_payment_method" />
      </FormLabel>
      <RadioGroup defaultValue="cash" name="radio-buttons-group">
        <FormControlLabel
          value="online"
          control={<Radio />}
          label={<FormattedMessage id="label_online_payment" />}
          disabled={true}
        />
        <FormControlLabel
          value="cash"
          control={<Radio />}
          label={<FormattedMessage id="label_cash_on_delivery" />}
        />
      </RadioGroup>
    </FormControl>
  );
}

export default PaymentForm;
