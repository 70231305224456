import React from 'react';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

import Author from './Author';
import ActivityCountBar from './ActivityCountBar';

const CommentImage = styled('div')({
  position: 'relative',
  width: '75px',
  height: '75px',
  img: {
    objectFit: 'cover',
    height: '100%',
    width: '100%',
  },
});

const CommentsDiv = styled('div')({
  backgroundColor: '#f9f9f9',
  padding: '15px',
  header: {
    margin: '10px 0px',
  },
});

const CommentDiv = styled('div')({
  margin: '0px 10px 10px 60px',
  padding: '0px 0px 10px 0px',
  borderBottom: '1px solid #ccc',
  wordBreak: 'break-all',
});

function Comment({ comment }) {
  const dataValues = {};
  comment.data.forEach((d) => {
    dataValues[d.type] = d.value;
  });

  let image;
  if (dataValues.image) {
    image = (
      <CommentImage>
        <img src={dataValues.image} layout="fill" alt="" />
      </CommentImage>
    );
  }

  const subComments = comment.subComments ? (
    <Comments comments={comment.subComments} />
  ) : null;

  return (
    <div>
      <Author author={comment.author} date={comment.server_epoch} />
      <CommentDiv>
        {image}
        <p>{dataValues.text}</p>
        <ActivityCountBar
          likes={comment.likes_count}
          dislikes={comment.dislikes_count}
        />
        {subComments}
      </CommentDiv>
    </div>
  );
}

function Comments({ comments }) {
  return (
    <CommentsDiv>
      <header>
        <FormattedMessage
          id="label_comment_count"
          values={{ count: comments.length }}
        />
      </header>
      {comments.map((comment, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Comment comment={comment} key={i} />
      ))}
    </CommentsDiv>
  );
}

export default Comments;
