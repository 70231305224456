const feedMockData = {
  status: true,
  message: 'Okay.',
  responseData: [
    {
      type: 'ORDER_TRACKING',
      data: null,
      section: '',
    },
    {
      type: 'SCROLLABLE_BANNERS',
      data: {
        code: 'Banner46486',
        name: '',
        description: 'scrollable',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/B_Bannerz_MH_Insurance_New.gif',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-S-2699,AGS-HW-1286,AGS-HW-1244,AGS-HW-863,AGS-HW-1311,AGS-HW-1159,AGS-HW-862,AGS-CP-642,AGS-HW-710,AGS-HW-1287,AGS-HW-1305,AGS-CP-471,AGS-S-2427,AGS-HW-1260,AGS-HW-708,AGS-S-2498,AGS-S-2407,AGS-S-2956,AGS-S-2815,AGS-S-2477,AGS-CP-911,AGS-CP-910,AGS-CP-972,AGS-CP-084,AGS-HW-1273,AGS-HW-1313,AGS-CP-470,AGS-S-2180,AGS-CP-895,AGS-CN-311,AGS-CP-611,AGS-CP-900,AGS-CP-949,AGS-CP-787,AGS-CP-559,AGS-CN-309,AGS-CN-300,AGS-CN-004,AGS-CN-378,AGS-CN-035,AGS-CN-647,AGS-CN-072,AGS-CP-600,AGS-CP-901,AGS-CP-602,AGS-CN-308,AGS-CN-299,AGS-CP-956,AGS-CP-896,AGS-CP-924,AGS-CP-601,AGS-CN-682,AGS-CP-1103,AGS-CP-947',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46486_1.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CN-682,AGS-CN-309,AGS-CN-308',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46486_2.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CN-300,AGS-CN-299',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/2_kisan_raksha_kavch_MH.jpg',
              type: 'IMAGE',
            },
            type: 'EXTERNAL_WEB_PAGE',
            data: {
              url: 'https://youtu.be/XOghRJt-Gs0',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46486_3.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-HW-1372,AGS-HW-1371,AGS-HW-1370,AGS-HW-1369',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46486_4.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CP-895,AGS-CP-896,AGS-CP-1103',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/B_Bannerz_MH_Insurance_New.gif',
              type: 'IMAGE',
            },
            type: 'EXTERNAL_WEB_PAGE',
            data: {
              url: 'https://www.corporate.agrostar.in/kisan-raksha-kavach-mh',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46486_5.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-KIT-498,AGS-KIT-497,AGS-KIT-496',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46486_6.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-HW-1360,AGS-HW-1366',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/2_kisan_raksha_kavch_MH.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-HW-1312,AGS-HW-774,AGS-CP-471,AGS-S-3154,AGS-S-2815,AGS-HW-1313,AGS-CN-311',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Scroll_Referral_Banner_MH.gif',
              type: 'IMAGE',
            },
            type: 'EXTERNAL_WEB_PAGE',
            data: {
              url: 'ulink://android.agrostar.in/refer-and-earn-details',
            },
          },
        ],
        priority: 1,
        source: 'APPMH',
        startTime: 1643913000000,
        endTime: 1643999400000,
        section: 'SHOP',
        autoScroll: true,
      },
      section: 'SCROLLABLE_BANNERS',
    },
    {
      type: 'HEADING',
      data: {
        value: 'SHOP_BY_CATEGORY',
        label: 'खरेदी विभिन्न प्रकारच्या उत्पानदांची',
      },
      section: '',
    },
    {
      type: 'SHOP_BY_CATEGORY',
      data: {
        categories: [
          {
            id: '72bdfcfb-246d-41e7-9ff4-15c68b3bf419',
            label: 'संरक्षण',
            value: 'Crop Protection',
            url: 'https://static.agrostar.in/static/category/category-cp.png',
          },
          {
            id: '44a00ec0-60ed-4229-813d-e7ba35c42994',
            label: 'पोषण',
            value: 'Crop Nutrition',
            url: 'https://static.agrostar.in/static/category/category-cn.png',
          },
          {
            id: '5241bf95-38b4-4b66-a4e0-a073a5bf7bc2',
            label: 'बीज',
            value: 'Seeds',
            url: 'https://static.agrostar.in/static/category/category-seed.png',
          },
          {
            id: '885598cb-a635-4f1f-bb6e-4a9c03260f43',
            label: 'हार्डवेअर',
            value: 'Hardware',
            url: 'https://static.agrostar.in/static/category/category-hardware.png',
          },
          {
            id: '2da2e37a-7557-4a59-9856-5b8be2d39059',
            label: 'काॅम्बो कीट',
            value: 'Combo',
            url: 'https://static.agrostar.in/static/category/category-combo.png',
          },
        ],
      },
      section: '',
    },
    {
      type: 'HEADING',
      data: {
        value: 'SHOP_BY_MY_CROPS',
        label: 'आपल्या पिकांसाठी खरेदी करा',
      },
      section: '',
    },
    {
      type: 'TAGS',
      data: {
        tags: [
          {
            type: 'CROP_DETAILS',
            _id: 'Onion',
            imageUrl: 'https://static.agrostar.in/static/Onion.jpg',
            label: 'कांदा',
            cropId: 'f6276e84-6c8c-45b9-9ec8-9031b4f02fb4',
            metaData: {
              cropId: 'f6276e84-6c8c-45b9-9ec8-9031b4f02fb4',
            },
          },
          {
            type: 'CROP_DETAILS',
            _id: 'Brinjal',
            imageUrl: 'https://static.agrostar.in/static/Brinjal.jpg',
            label: 'वांगी',
            cropId: 'af88d2e2-eab4-451e-897d-9e958cb7db57',
            metaData: {
              cropId: 'af88d2e2-eab4-451e-897d-9e958cb7db57',
            },
          },
          {
            type: 'CROP_DETAILS',
            _id: 'Hot Pepper (Chilli)',
            imageUrl:
              'https://static.agrostar.in/static/Hot Pepper (Chilli).jpg',
            label: 'तिखट मिरची',
            cropId: 'cfc4025a-2044-4477-955c-e715a8f4cca9',
            metaData: {
              cropId: 'cfc4025a-2044-4477-955c-e715a8f4cca9',
            },
          },
          {
            type: 'CROP_DETAILS',
            _id: 'Cotton',
            imageUrl: 'https://static.agrostar.in/static/Cotton.jpg',
            label: 'कापूस',
            cropId: '2e6a7cb2-c1c7-43dd-940c-4adb0bb23701',
            metaData: {
              cropId: '2e6a7cb2-c1c7-43dd-940c-4adb0bb23701',
            },
          },
          {
            type: 'CROP_DETAILS',
            _id: 'Potato',
            imageUrl: 'https://static.agrostar.in/static/Potato.jpg',
            label: 'बटाटा',
            cropId: '96d34b3a-5a86-4f17-928f-26f700944f8d',
            metaData: {
              cropId: '96d34b3a-5a86-4f17-928f-26f700944f8d',
            },
          },
          {
            type: 'SELECT_CROPS',
            _id: 'Select Crops',
            imageUrl:
              'https://agrostarcatalog.s3.ap-south-1.amazonaws.com/static/tag_add+crop.jpg',
            label: 'पिके निवडा',
            metaData: {
              cropId: '',
            },
          },
        ],
      },
      section: 'EXPLORE_BY_YOUR_CROPS_WITH_ONLY_HEADERS',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner45402',
        name: '',
        description: 'new_insurance_banner_mh',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/new_insurance_banner_mh_1.jpg',
              type: 'IMAGE',
            },
            type: 'EXTERNAL_WEB_PAGE',
            data: {
              url: 'https://youtu.be/XOghRJt-Gs0',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/new_insurance_banner_mh_2.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-S-2699,AGS-HW-1286,AGS-HW-1244,AGS-HW-863,AGS-HW-1311,AGS-HW-1159,AGS-HW-862,AGS-CP-642,AGS-HW-710,AGS-HW-1287,AGS-HW-1305,AGS-CP-471,AGS-S-2427,AGS-HW-1260,AGS-HW-708,AGS-S-2498,AGS-S-2407,AGS-S-2956,AGS-S-2815,AGS-S-2477,AGS-CP-911,AGS-CP-910,AGS-CP-972,AGS-CP-084,AGS-HW-1273,AGS-HW-1313,AGS-CP-470,AGS-S-2180,AGS-CP-895,AGS-CN-311,AGS-CP-611,AGS-CP-900,AGS-CP-949,AGS-CP-787,AGS-CP-559,AGS-CN-309,AGS-CN-300,AGS-CN-004,AGS-CN-378,AGS-CN-035,AGS-CN-647,AGS-CN-072,AGS-CP-600,AGS-CP-901,AGS-CP-602,AGS-CN-308,AGS-CN-299,AGS-CP-956,AGS-CP-896,AGS-CP-924,AGS-CP-601,AGS-CN-682,AGS-CP-1103,AGS-CP-947',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 1,
        source: 'APPMH',
        rows: 2,
        columns: 1,
        startTime: 1641493800000,
        endTime: 1645986600000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner24620',
        name: '',
        description: 'Dec CPCN Slab Offer',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/CPCN_Slab_MH_Feb22.gif',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CN-004,AGS-CP-471,AGS-CN-035,AGS-CP-494,AGS-CN-119,AGS-CN-643,AGS-CP-265,AGS-CN-426,AGS-CN-311,AGS-CP-171,AGS-CP-895,AGS-CN-300,AGS-CN-208,AGS-CP-361,AGS-CP-509,AGS-CP-130,AGS-CP-609,AGS-CP-614,AGS-CP-608,AGS-CN-647,AGS-CP-788,AGS-CP-575,AGS-CP-600,AGS-CP-730,AGS-CP-701,AGS-CP-244,AGS-CP-659,AGS-CN-443,AGS-CP-129,AGS-CP-793,AGS-CP-030,AGS-CP-937,AGS-CP-613,AGS-CN-378,AGS-CP-950,AGS-CN-299,AGS-CP-150,AGS-CN-030,AGS-CN-650,AGS-CN-444,AGS-CP-015,AGS-CP-492,AGS-CP-653,AGS-CP-191,AGS-CP-907,AGS-CP-896,AGS-CP-325,AGS-CN-307,AGS-CN-303,AGS-CP-141',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 1,
        source: 'APPMH',
        rows: 1,
        columns: 1,
        startTime: 1640975400000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'HEADING',
      data: {
        value: 'FEATURED_PRODUCTS',
        label: 'खास उत्पादने',
      },
      section: '',
    },
    {
      type: 'PRODUCTS',
      data: {
        products: [
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-HW-710-MSK_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'टारप्लस शीट 17*24 (ताडपत्री) पिवळा',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-HW-710',
            freeShipping: true,
            mrp: 4500,
            version: '8.46.82',
            inventory: 962,
            sellingPrice: 3900,
            offers: {
              offerType: {
                url: 'https://static.agrostar.in/offerfreeproducts.png',
                value: 'offer_free_products',
                label: '',
                bestPriceText: 'ऑफर किंमत ₹3900/ प्रति नग+अधिक फायदा ₹1499 चा',
              },
            },
            rating: {
              totalCount: 1057,
              average: 4.3,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-HW-1313_N_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'कमांडो रिचार्जेबल टॉर्च - CM007',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-HW-1313',
            freeShipping: true,
            mrp: 1699,
            version: '9.42.19',
            inventory: 2179,
            sellingPrice: 1100,
            offers: {
              offerType: {
                url: 'https://static.agrostar.in/offercashdiscount.png',
                value: 'offer_cash_discount',
                label: '',
                bestPriceText: 'ऑफर किंमत ₹999/ प्रति नग',
              },
            },
            rating: {
              totalCount: 4040,
              average: 4.2,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-HW-1366-SK_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'ग्लॅडिएटर मिस्ट ब्लोअर गन बॅटरी स्प्रेअरसाठी',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-HW-1366',
            freeShipping: true,
            mrp: 2500,
            version: '1.47.36',
            inventory: 4322,
            sellingPrice: 1300,
            offers: null,
            rating: {
              totalCount: 54,
              average: 4.1,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-CP-471-MSK_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'कोराजन (रेनॉक्सीपीर) 150 मिली',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CP-471',
            freeShipping: true,
            mrp: 2710,
            version: '3.41.52',
            inventory: 1914,
            sellingPrice: 1929,
            offers: null,
            rating: {
              totalCount: 398,
              average: 4.3,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-CN-004_2N_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'पावर जेल (वनस्पती पोषण) (500 ग्रॅम)',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CN-004',
            freeShipping: true,
            mrp: 650,
            version: '16.29.71',
            inventory: 13317,
            sellingPrice: 479,
            offers: {
              offerType: {
                url: 'https://static.agrostar.in/offerfreeproducts.png',
                value: 'offer_free_products',
                label: '',
                bestPriceText: 'ऑफर किंमत ₹479/ प्रति नग+अधिक फायदा ₹1298 चा',
              },
            },
            rating: {
              totalCount: 6047,
              average: 4.3,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/RD_AGS-CN-300_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'न्युट्रीप्रो ग्रेड 2 - 500 ग्रॅम',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CN-300',
            freeShipping: true,
            mrp: 650,
            version: '6.9.42',
            inventory: 2951,
            sellingPrice: 425,
            offers: null,
            rating: {
              totalCount: 1577,
              average: 4.3,
              showRatingBars: true,
            },
          },
        ],
      },
      section: 'FEATURED_PRODUCTS',
    },
    {
      type: 'CALL_TO_ACTION',
      data: {
        section: 'FEATURED_PRODUCTS',
        label: 'सर्व खास उत्पादने पाहा',
        value: 'FEATURED_PRODUCTS_CTA',
        type: 'PRODUCTS',
        headerDetails: {
          value: 'FEATURED_PRODUCTS',
          label: 'खास उत्पादने',
        },
        metaData: {
          baseUrl: 'https://communication.agrostar.in',
          serviceEndpoint: '/shopaggregator/v1/featuredproducts',
          params: {
            count: 60,
            language: 'mr',
            source: 'APPMH',
          },
        },
      },
      section: '',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner16037_C',
        name: '',
        description: 'Insurance_Teaser_MH',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/New_marathi_Insurance_GIF.gif',
              type: 'IMAGE',
            },
            type: 'EXTERNAL_WEB_PAGE',
            data: {
              url: 'https://www.corporate.agrostar.in/kisan-raksha-kavach-mh',
            },
          },
        ],
        priority: 1,
        source: 'APPMH',
        rows: 1,
        columns: 1,
        startTime: 1636828200000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner16037_D',
        name: '',
        description: 'Insurance_DP_Video_MH',
        language: 'mr',
        data: [
          {
            media: {
              url: 'XOghRJt-Gs0',
              type: 'YOUTUBE',
              config: {
                autoPlay: true,
                autoMute: true,
                showRipple: false,
                landscapeMode: true,
                RippleDuration: 0,
              },
            },
            type: 'NO_DATA',
            data: null,
          },
        ],
        priority: 1,
        source: 'APPMH',
        rows: 1,
        columns: 1,
        startTime: 1636828200000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'HEADING',
      data: {
        value: 'FEATURED_BRANDS',
        label: 'खास ब्रॅंड',
      },
      section: '',
    },
    {
      type: 'BRANDS',
      data: {
        brands: [
          {
            brandId: '2152950a-4758-4d27-ab21-bae31cb60f21',
            brandImage: 'https://static.agrostar.in/static/Seminis.jpg',
            brandName: 'सेमिनीस',
            label: 'सेमिनीस',
            value: 'Seminis',
          },
          {
            brandId: '0d91242f-6fea-4c3c-b3b0-c260fde4bfe2',
            brandImage: 'https://static.agrostar.in/static/Tarplus.jpg',
            brandName: 'टारप्लस',
            label: 'टारप्लस',
            value: 'Tarplus',
          },
          {
            brandId: '2f85e79b-8d3f-4b4c-841c-cf1f168ec7bc',
            brandImage: 'https://static.agrostar.in/static/Tata Rallis.jpg',
            brandName: 'टाटा रेलीस',
            label: 'टाटा रेलीस',
            value: 'Tata Rallis',
          },
          {
            brandId: '65ab5461-f2d0-4c03-8057-a983ae0543a3',
            brandImage: 'https://static.agrostar.in/static/Bayer.jpg',
            brandName: 'बेयर',
            label: 'बेयर',
            value: 'Bayer',
          },
          {
            brandId: 'caaa2317-3c43-4ef6-8897-1d15c4fa49cf',
            brandImage: 'https://static.agrostar.in/static/Panchganga.jpg',
            brandName: 'पंचगंगा',
            label: 'पंचगंगा',
            value: 'Panchganga',
          },
          {
            brandId: 'c5323c05-568a-460b-b26c-2ac5776fdc01',
            brandImage: 'https://static.agrostar.in/static/PowerGrow.jpg',
            brandName: 'पॉवरग्रो',
            label: 'पॉवरग्रो',
            value: 'PowerGrow',
          },
        ],
      },
      section: 'FEATURED_BRANDS',
    },
    {
      type: 'CALL_TO_ACTION',
      data: {
        section: 'FEATURED_BRANDS',
        label: 'सर्व कृषी ब्रॅंड पाहा',
        value: 'FEATURED_BRANDS_CTA',
        type: 'BRANDS',
        headerDetails: {
          value: 'FEATURED_BRANDS',
          label: 'खास ब्रॅंड',
        },
        metaData: {
          baseUrl: 'https://catalog.agrostar.in',
          serviceEndpoint: '/catalogservice/v2/brand/',
          params: {
            language: 'mr',
          },
        },
      },
      section: '',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner46202',
        name: '',
        description: 'Launch-Banner-Super-Tarp-120-GSM-MH.jpg:IMAGE',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/Launch-Banner-Super-Tarp-120-GSM-MH.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-HW-1380,AGS-HW-1384,AGS-HW-1379,AGS-HW-1383,AGS-HW-1378,AGS-HW-1382,AGS-HW-1377,AGS-HW-1381',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 2,
        source: 'APPMH',
        rows: 1,
        columns: 1,
        startTime: 1643308200000,
        endTime: 1643999400000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner5025',
        name: '',
        description: 'Catagory-Banner',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/Category-Banner-MH-1_Dec21.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CP-129,AGS-CP-130,AGS-CP-251,AGS-CP-265,AGS-CP-469,AGS-CP-494,AGS-CP-525,AGS-CP-559,AGS-CP-560,AGS-CP-563,AGS-CP-566,AGS-CP-141,AGS-CP-597,AGS-CP-599,AGS-CP-730,AGS-CP-600,AGS-CP-602,AGS-CP-605,AGS-CP-606,AGS-CP-701,AGS-CP-787,AGS-CP-788,AGS-CP-895,AGS-CP-896,AGS-CP-907,AGS-CP-926,AGS-CP-928,AGS-CP-935,AGS-CP-937',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Category-Banner-MH-2_Dec21.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CN-004,AGS-CN-030,AGS-CN-035,AGS-CN-119,AGS-CN-208,AGS-CN-267,AGS-CN-269,AGS-CN-300,AGS-CN-303,AGS-CN-305,AGS-CN-308,AGS-CN-309,AGS-CN-375,AGS-CN-376,AGS-CN-378,AGS-CN-425,AGS-CN-426,AGS-CN-443,AGS-CN-602,AGS-CN-604,AGS-CN-612,AGS-CN-643,AGS-CN-647',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Category-Banner-MH-3_Dec21.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-HW-775,AGS-HW-1354,AGS-HW-1353,AGS-HW-901,AGS-HW-786',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Category-Banner-MH-4_Dec21.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_DETAILS',
            data: {
              skuCode: 'AGS-HW-1313',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Category-Banner-MH-5_Dec21.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-HW-1286,AGS-HW-1244,AGS-HW-863,AGS-HW-862,AGS-HW-710,AGS-HW-1305,AGS-HW-1260,AGS-HW-708,AGS-HW-1273,AGS-HW-1196',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Category-Banner-MH-6_Dec21.jpg',
              type: 'IMAGE',
            },
            type: 'NO_CLICK',
            data: null,
          },
        ],
        priority: 2,
        source: 'APPMH',
        rows: 2,
        columns: 3,
        startTime: 1611945000000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'HEADING',
      data: {
        value: 'POPULAR_PRODUCTS_IN_REGION',
        label: 'चर्चित उत्पादने',
      },
      section: '',
    },
    {
      type: 'PRODUCTS',
      data: {
        products: [
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-CN-035_l.jpg',
              },
            ],
            priceOnApp: true,
            productName:
              'हयूमिक पॉवर अडवांस पावडर (95% हुमिक ऍसिड) (250 ग्रॅम)',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CN-035',
            freeShipping: false,
            mrp: 500,
            version: '10.83.45',
            inventory: 2764,
            sellingPrice: 399,
            offers: {
              offerType: {
                url: 'https://static.agrostar.in/offerfreeproducts.png',
                value: 'offer_free_products',
                label: '',
                bestPriceText: 'ऑफर किंमत ₹399/ प्रति नग+अधिक फायदा ₹998 चा',
              },
            },
            rating: {
              totalCount: 2127,
              average: 4.2,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-CP-471-MSK_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'कोराजन (रेनॉक्सीपीर) 150 मिली',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CP-471',
            freeShipping: true,
            mrp: 2710,
            version: '3.41.52',
            inventory: 1914,
            sellingPrice: 1929,
            offers: null,
            rating: {
              totalCount: 398,
              average: 4.3,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-CP-787_l.jpg',
              },
            ],
            priceOnApp: true,
            productName:
              'किल- एक्स (थायोमेथॉक्झाम 12.6% + लॅम्डासायहॅलोथ्रीन 9.5% झेड सी.) २०० मिली',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CP-787',
            freeShipping: true,
            mrp: 700,
            version: '5.14.10',
            inventory: 1199,
            sellingPrice: 525,
            offers: {
              offerType: {
                url: 'https://static.agrostar.in/offerfreeproducts.png',
                value: 'offer_free_products',
                label: '',
                bestPriceText: 'ऑफर किंमत ₹525/ प्रति नग+अधिक फायदा ₹749 चा',
              },
            },
            rating: {
              totalCount: 900,
              average: 4,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/RD_AGS-CN-444_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'महाधन एनपीके (19:19:19) -1 किलो',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CN-444',
            freeShipping: false,
            mrp: 200,
            version: '2.85.15',
            inventory: 385,
            sellingPrice: 139,
            offers: null,
            rating: {
              totalCount: 612,
              average: 4.4,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-S-1994_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'एमएच सेमिनीस-बिन फाल्गुनी (500 ग्रॅम) बियाणे',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-S-1994',
            freeShipping: false,
            mrp: 498,
            version: '1.4.20',
            inventory: 2,
            sellingPrice: 365,
            offers: null,
            rating: {
              totalCount: 3,
              average: 3.5,
              showRatingBars: false,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-S-2427_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'एफ१ मॅक्स कलिंगड (१०००बियाणे) बियाणे',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-S-2427',
            freeShipping: true,
            mrp: 2425,
            version: '1.46.60',
            inventory: 628,
            sellingPrice: 1800,
            offers: {
              offerType: {
                url: 'https://static.agrostar.in/offercashdiscount.png',
                value: 'offer_cash_discount',
                label: '',
                bestPriceText: 'ऑफर किंमत ₹1750/ प्रति नग',
              },
            },
            rating: {
              totalCount: 36,
              average: 4.1,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-CP-954_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'पीक बूस्टर(ट्रायकोंटानॉल 0.1% EW)250 मिली',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CP-954',
            freeShipping: false,
            mrp: 185,
            version: '2.78.49',
            inventory: 1031,
            sellingPrice: 135,
            offers: null,
            rating: {
              totalCount: 259,
              average: 4.1,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-CP-909_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'क्रुझर (थायमेथॉक्साम 25% डब्ल्यूजी) 100 ग्रॅम',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CP-909',
            freeShipping: false,
            mrp: 250,
            version: '3.59.94',
            inventory: 422,
            sellingPrice: 149,
            offers: null,
            rating: {
              totalCount: 381,
              average: 4.3,
              showRatingBars: true,
            },
          },
        ],
      },
      section: 'POPULAR_PRODUCTS_IN_REGION',
    },
    {
      type: 'CALL_TO_ACTION',
      data: {
        section: 'POPULAR_PRODUCTS_IN_REGION',
        label: 'सर्व लोकप्रिय उत्पादने',
        value: 'POPULAR_PRODUCTS_CTA',
        type: 'PRODUCTS',
        headerDetails: {
          value: 'POPULAR_PRODUCTS_IN_REGION',
          label: 'चर्चित उत्पादने',
        },
        metaData: {
          baseUrl: 'https://communication.agrostar.in',
          serviceEndpoint: '/shopaggregator/v1/popularproducts',
          params: {
            count: 60,
            language: 'mr',
            source: 'APPMH',
          },
        },
      },
      section: '',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner11983',
        name: '',
        description: 'Top Selling Banner',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/Top-Selling-MH-1_Dec21_A.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_DETAILS',
            data: {
              skuCode: 'AGS-HW-1313',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Top-Selling-MH-2_Dec21_A.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_DETAILS',
            data: {
              skuCode: 'AGS-CN-004',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Top-Selling-MH-3_Dec21_A.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_DETAILS',
            data: {
              skuCode: 'AGS-HW-1312',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Top-Selling-MH-4_Dec21_A.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_DETAILS',
            data: {
              skuCode: 'AGS-CN-311',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Top-Selling-MH-5_Dec21_A.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_DETAILS',
            data: {
              skuCode: 'AGS-CN-300',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Top-Selling-MH-6_Dec21_A.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_DETAILS',
            data: {
              skuCode: 'AGS-HW-1286',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Top-Selling-MH-7_Dec21_A.jpg',
              type: 'IMAGE',
            },
            type: 'NO_CLICK',
            data: null,
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Top-Selling-MH-8_Dec21_A.jpg',
              type: 'IMAGE',
            },
            type: 'NO_CLICK',
            data: null,
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Top-Selling-MH-9_Dec21_A.jpg',
              type: 'IMAGE',
            },
            type: 'NO_CLICK',
            data: null,
          },
        ],
        priority: 2,
        source: 'APPMH',
        rows: 3,
        columns: 3,
        startTime: 1636641000000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner16037',
        name: '',
        description: 'Insurance_Teaser_MH',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/Kisan_Kavach_MH_Launch_Banner.gif',
              type: 'IMAGE',
            },
            type: 'EXTERNAL_WEB_PAGE',
            data: {
              url: 'https://www.corporate.agrostar.in/kisan-raksha-kavach-mh',
            },
          },
        ],
        priority: 2,
        source: 'APPMH',
        rows: 1,
        columns: 1,
        startTime: 1636828200000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'HEADING',
      data: {
        value: 'RECENTLY_VIEWED_PRODUCTS',
        label: 'आपण नुकतीच पाहिलेली उत्पादने',
      },
      section: '',
    },
    {
      type: 'PRODUCTS',
      data: {
        products: [
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-HW-1374_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'हेक्‍टर भाजीपाला रोपांचे ट्रान्सप्लांटर',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-HW-1374',
            freeShipping: true,
            mrp: 3450,
            version: '1.2.93',
            inventory: 13,
            sellingPrice: 2700,
            offers: null,
            rating: {
              totalCount: 0,
              average: 0,
              showRatingBars: false,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/RD_AGS-CN-305_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'वेटसिल प्लस 100 मिली',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CN-305',
            freeShipping: false,
            mrp: 280,
            version: '6.33.4',
            inventory: 2316,
            sellingPrice: 210,
            offers: {
              offerType: {
                url: 'https://static.agrostar.in/offerfreeproducts.png',
                value: 'offer_free_products',
                label: '',
                bestPriceText: 'ऑफर किंमत ₹210/ प्रति नग+अधिक फायदा ₹279 चा',
              },
            },
            rating: {
              totalCount: 1176,
              average: 4.2,
              showRatingBars: true,
            },
          },
          {
            buyBar: false,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-CP-736-MSK_l.jpg',
              },
            ],
            priceOnApp: true,
            productName:
              'बेयर ग्लॅमोर (एथिप्रोल + इमिडाक्लोप्रिड 80 डब्ल्यूजी 40 + 40% डब्ल्यू) 100 ग्रॅम',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CP-736',
            freeShipping: true,
            mrp: 1450,
            version: '1.1.76',
            inventory: 0,
            sellingPrice: 1240,
            offers: null,
            rating: {
              totalCount: 0,
              average: 0,
              showRatingBars: false,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/RD_AGS-CN-443_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'पावर ग्रो भूमिका (4 किलो)',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CN-443',
            freeShipping: true,
            mrp: 810,
            version: '8.17.0',
            inventory: 4725,
            sellingPrice: 440,
            offers: {
              offerType: {
                url: 'https://static.agrostar.in/offerfreeproducts.png',
                value: 'offer_free_products',
                label: '',
                bestPriceText: 'ऑफर किंमत ₹440/ प्रति नग+अधिक फायदा ₹809 चा',
              },
            },
            rating: {
              totalCount: 2519,
              average: 4.3,
              showRatingBars: true,
            },
          },
        ],
      },
      section: 'RECENTLY_VIEWED_PRODUCTS',
    },
    {
      type: 'CALL_TO_ACTION',
      data: {
        section: 'RECENTLY_VIEWED_PRODUCTS',
        label: 'आपण पाहिलेल्या गोष्टींचा तपशील',
        value: 'RECENTLY_VIEWED_PRODUCTS_CTA',
        type: 'PRODUCTS',
        headerDetails: {
          value: 'RECENTLY_VIEWED_PRODUCTS',
          label: 'आपण नुकतीच पाहिलेली उत्पादने',
        },
        metaData: {
          baseUrl: 'https://communication.agrostar.in',
          serviceEndpoint: '/shopaggregator/v1/recentlyviewedproducts',
          params: {
            count: 60,
            language: 'mr',
            source: 'APPMH',
          },
        },
      },
      section: '',
    },
    {
      type: 'HEADING',
      data: {
        value: 'RELATED_PRODUCTS',
        label: 'आपली मनपसंद उत्पादने',
      },
      section: '',
    },
    {
      type: 'PRODUCTS',
      data: {
        products: [
          {
            buyBar: false,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-CP-736-MSK_l.jpg',
              },
            ],
            priceOnApp: true,
            productName:
              'बेयर ग्लॅमोर (एथिप्रोल + इमिडाक्लोप्रिड 80 डब्ल्यूजी 40 + 40% डब्ल्यू) 100 ग्रॅम',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CP-736',
            freeShipping: true,
            mrp: 1450,
            version: '1.1.76',
            inventory: 0,
            sellingPrice: 1240,
            offers: null,
            rating: {
              totalCount: 0,
              average: 0,
              showRatingBars: false,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-CP-653-MSK_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'इकोनीम प्लस 250 मिली',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CP-653',
            freeShipping: true,
            mrp: 762,
            version: '2.7.96',
            inventory: 458,
            sellingPrice: 619,
            offers: null,
            rating: {
              totalCount: 160,
              average: 4.3,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-CP-492-MSK_l.jpg',
              },
            ],
            priceOnApp: true,
            productName:
              'सिजेंटा अलिका (थायोमेथॉक्झाम १२.६% + लॅम्डासायहॅलोथ्रीन ९.५ % झेड सी.) २०० मिली',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CP-492',
            freeShipping: true,
            mrp: 620,
            version: '3.29.80',
            inventory: 459,
            sellingPrice: 585,
            offers: null,
            rating: {
              totalCount: 404,
              average: 4.2,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-CN-311-MSK_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'टाटा बहार (1000 मिली)',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CN-311',
            freeShipping: true,
            mrp: 1025,
            version: '4.39.88',
            inventory: 1685,
            sellingPrice: 649,
            offers: {
              offerType: {
                url: 'https://static.agrostar.in/offerfreeproducts.png',
                value: 'offer_free_products',
                label: '',
                bestPriceText: 'ऑफर किंमत ₹649/ प्रति नग+अधिक फायदा ₹749 चा',
              },
            },
            rating: {
              totalCount: 966,
              average: 4.4,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/RD_AGS-CN-443_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'पावर ग्रो भूमिका (4 किलो)',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CN-443',
            freeShipping: true,
            mrp: 810,
            version: '8.17.0',
            inventory: 4725,
            sellingPrice: 440,
            offers: {
              offerType: {
                url: 'https://static.agrostar.in/offerfreeproducts.png',
                value: 'offer_free_products',
                label: '',
                bestPriceText: 'ऑफर किंमत ₹440/ प्रति नग+अधिक फायदा ₹809 चा',
              },
            },
            rating: {
              totalCount: 2519,
              average: 4.3,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-CP-540-MSK_l.jpg',
              },
            ],
            priceOnApp: true,
            productName:
              'रॉकेट (प्रोफेनोफॉस 40% + सायपरमेथ्रीन 4% EC) 1000 मिली',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CP-540',
            freeShipping: true,
            mrp: 1220,
            version: '3.57.89',
            inventory: 180,
            sellingPrice: 799,
            offers: null,
            rating: {
              totalCount: 318,
              average: 4.1,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/RD_AGS-CN-305_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'वेटसिल प्लस 100 मिली',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CN-305',
            freeShipping: false,
            mrp: 280,
            version: '6.33.4',
            inventory: 2316,
            sellingPrice: 210,
            offers: {
              offerType: {
                url: 'https://static.agrostar.in/offerfreeproducts.png',
                value: 'offer_free_products',
                label: '',
                bestPriceText: 'ऑफर किंमत ₹210/ प्रति नग+अधिक फायदा ₹279 चा',
              },
            },
            rating: {
              totalCount: 1176,
              average: 4.2,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-CN-180-MSK_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'सुमिटोमो होशी जीए 0.001% 1 लीटर',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CN-180',
            freeShipping: true,
            mrp: 900,
            version: '3.44.7',
            inventory: 230,
            sellingPrice: 650,
            offers: {
              offerType: {
                url: 'https://static.agrostar.in/offerfreeproducts.png',
                value: 'offer_free_products',
                label: '',
                bestPriceText: 'ऑफर किंमत ₹650/ प्रति नग+अधिक फायदा ₹749 चा',
              },
            },
            rating: {
              totalCount: 290,
              average: 4.3,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-CN-004_2N_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'पावर जेल (वनस्पती पोषण) (500 ग्रॅम)',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-CN-004',
            freeShipping: true,
            mrp: 650,
            version: '16.29.71',
            inventory: 13317,
            sellingPrice: 479,
            offers: {
              offerType: {
                url: 'https://static.agrostar.in/offerfreeproducts.png',
                value: 'offer_free_products',
                label: '',
                bestPriceText: 'ऑफर किंमत ₹479/ प्रति नग+अधिक फायदा ₹1298 चा',
              },
            },
            rating: {
              totalCount: 6047,
              average: 4.3,
              showRatingBars: true,
            },
          },
          {
            buyBar: true,
            leadCaptureEnabled: false,
            productImages: [
              {
                type: 'thumb',
                name: 'https://static.agrostar.in/static/AGS-HW-1374_l.jpg',
              },
            ],
            priceOnApp: true,
            productName: 'हेक्‍टर भाजीपाला रोपांचे ट्रान्सप्लांटर',
            needExpertHelp: true,
            displayMessage: '',
            skuCode: 'AGS-HW-1374',
            freeShipping: true,
            mrp: 3450,
            version: '1.2.93',
            inventory: 13,
            sellingPrice: 2700,
            offers: null,
            rating: {
              totalCount: 0,
              average: 0,
              showRatingBars: false,
            },
          },
        ],
      },
      section: 'RELATED_PRODUCTS',
    },
    {
      type: 'CALL_TO_ACTION',
      data: {
        section: 'RELATED_PRODUCTS',
        label: 'अधिक उत्पादने पहा',
        value: 'RELATED_PRODUCTS_CTA',
        type: 'PRODUCTS',
        headerDetails: {
          value: 'RELATED_PRODUCTS',
          label: 'आपली मनपसंद उत्पादने',
        },
        metaData: {
          baseUrl: 'https://communication.agrostar.in',
          serviceEndpoint: '/shopaggregator/v1/relatedproducts',
          params: {
            count: 60,
            language: 'mr',
            source: 'APPMH',
          },
        },
      },
      section: '',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner29081',
        name: '',
        description: 'CN Banners',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/CN_Banner_1_AS.gif',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CN-307,AGS-CN-306',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/CN_Banner_2_AS.gif',
              type: 'IMAGE',
            },
            type: 'PRODUCT_DETAILS',
            data: {
              skuCode: 'AGS-CN-305',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/CN_Banner_3_AS.gif',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CN-072,AGS-CN-311,AGS-CN-142',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/CN_Banner_4_AS.gif',
              type: 'IMAGE',
            },
            type: 'PRODUCT_DETAILS',
            data: {
              skuCode: 'AGS-CN-030',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/CN_Banner_5_AS.jpg',
              type: 'IMAGE',
            },
            type: 'NO_CLICK',
            data: null,
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/CN_Banner_6_AS.gif',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CN-216,AGS-CN-116,AGS-CN-115',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/CN_banner_7_AS.gif',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CN-004,AGS-CN-443,AGS-CN-035,AGS-CN-119,AGS-CN-174,AGS-CN-222',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/CN_Banner_8_AS.jpg',
              type: 'IMAGE',
            },
            type: 'NO_CLICK',
            data: null,
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/CN_Banner_9_AS.gif',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CN-370,AGS-CN-368,AGS-CN-248,AGS-CN-369,AGS-CN-221,AGS-CN-373',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 2,
        source: 'APPMH',
        rows: 3,
        columns: 3,
        startTime: 1633545000000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner33567',
        name: '',
        description: 'Dec Veg Seeds Offer',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/Veg_Seed_Slab_MH_Feb22.gif',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-S-1777,AGS-S-1927,AGS-S-2535,AGS-S-2623,AGS-S-2200,AGS-S-3022,AGS-S-2396,AGS-S-2213,AGS-S-2008,AGS-S-1750,AGS-S-2781,AGS-S-2816,AGS-S-2222,AGS-S-2574,AGS-S-2854,AGS-S-3023,AGS-S-3020,AGS-S-2810,AGS-S-2002,AGS-S-916,AGS-S-2126,AGS-S-334,AGS-S-085,AGS-S-148,AGS-S-1752,AGS-S-2465,AGS-S-357,AGS-S-352,AGS-S-2541,AGS-S-2776',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 2,
        source: 'APPMH',
        rows: 1,
        columns: 1,
        startTime: 1640975400000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner16037_E',
        name: '',
        description: 'Insurance_DP_Video_MH',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/Kisan-Raksha-kavach_MH_Insurance_Strip_New.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CN-004,AGS-CP-471,AGS-CN-300,AGS-CN-035,AGS-CN-311,AGS-CN-072,AGS-CN-299,AGS-CP-470,AGS-CP-268,AGS-CN-030,AGS-CN-119,AGS-CP-606,AGS-CP-620,AGS-CN-378,AGS-CP-084,AGS-CP-910,AGS-CN-643,AGS-CN-425,AGS-CP-597,AGS-CN-309,AGS-CP-908,AGS-CP-600,AGS-CN-208,AGS-CP-469,AGS-CP-602,AGS-CN-443,AGS-CP-899,AGS-CP-905,AGS-CP-703,AGS-CN-308',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 2,
        source: 'APPMH',
        rows: 1,
        columns: 1,
        startTime: 1636828200000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner46450',
        name: '',
        description:
          '1 -  Coragen(Rynaxypyr) 300 ml\nMH Coragen (Rynaxypyr) 150 ml\nAmaze - X (Emamectin Benzoate 5% SG) 500 g\nAmaze - X (Emamectin Benzoate 5% SG) 250g\nCoragen(Rynaxypyr) 80 ml\nMH Coragen (Rynaxypyr) 60 ml\nKill X (Thiamethoxam 12.6% + Lambdacyhalothrin 9.5% ZC) 200 ml\nCoragen (Rynaxypyr) 30 ml\nAmaze - X (Emamectin Benzoate 5% SG) 100 gm\nKill X (Thiamethoxam 12.6% + Lambdacyhalothrin 9.5% ZC) 80 ml\nCoragen(Rynaxypyr) 10 ml\n2 - Power Gel - Plant Nutrient (500 Gms) \n Humic power Advanced powder 95% (250 Gms) \n Root power (200 Gms)',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46450_1.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CP-642,AGS-CP-471,AGS-CP-911,AGS-CP-910,AGS-CP-972,AGS-CP-470,AGS-CP-787,AGS-CP-559,AGS-CP-600,AGS-CP-956,AGS-CP-924',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46450_2.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CN-004,AGS-CN-035,AGS-CN-119',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 3,
        source: 'APPMH',
        rows: 1,
        columns: 2,
        startTime: 1643913000000,
        endTime: 1643999400000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner6035',
        name: '',
        description: 'Top_purchased_MH',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/top_purchased_mh.gif',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CP-241,AGS-HW-1313,AGS-CP-936,AGS-CN-299,AGS-CN-378,AGS-CN-072,AGS-CN-300,AGS-CN-311,AGS-S-1777,AGS-CP-602,AGS-CN-004,AGS-S-4057,AGS-CN-219,AGS-S-2623,AGS-CN-188,AGS-CN-425,AGS-CP-600,AGS-CP-601,AGS-CP-203,AGS-CN-035,AGS-CN-444,AGS-CP-956,AGS-CN-119,AGS-CP-189,AGS-CP-787,AGS-S-2407,AGS-CP-938,AGS-CP-954,AGS-CP-199,AGS-CN-306,AGS-CN-449,AGS-KIT-515,AGS-CN-307,AGS-CN-308,AGS-CN-316,AGS-CP-960,AGS-KIT-516,AGS-CN-647,AGS-CP-896,AGS-CN-194',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 3,
        source: 'APPMH',
        rows: 1,
        columns: 1,
        startTime: 1611945000000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner25788',
        name: '',
        description: 'Crop Issue Banner',
        language: 'hi',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/Issue_banner_MH_1_AS_DEc.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CP-217,AGS-CP-216,AGS-CP-141,AGS-CP-244,AGS-CP-245,AGS-CP-496,AGS-CP-580',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Issue_banner_MH_2_AS_DEc.jpg',
              type: 'IMAGE',
            },
            type: 'NO_CLICK',
            data: null,
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Issue_banner_MH_3_AS_DEc.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CP-217,AGS-CP-216,AGS-CP-496,AGS-CP-580,AGS-CP-171,AGS-CP-214,AGS-CP-774,AGS-CP-567,AGS-CP-513,AGS-CP-699',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Issue_banner_MH_4_AS_DEc.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CP-529,AGS-CP-581,AGS-CP-660,AGS-CP-492',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Issue_banner_MH_5_AS_DEc.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CP-435,AGS-CP-318,AGS-CP-334',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Issue_banner_MH_6_AS_DEc.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CP-620,AGS-KIT-352,AGS-CP-471,AGS-KIT-353,AGS-CP-470,AGS-CP-469,AGS-CP-924,AGS-CP-319,AGS-CP-229,AGS-CP-228,AGS-CP-747,AGS-CP-766,AGS-CP-540,AGS-CP-539,AGS-CP-625',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 3,
        source: 'APPMH',
        rows: 2,
        columns: 3,
        startTime: 1613068200000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner33572',
        name: '',
        description: 'Dec Torch Offer Banner',
        language: 'gu',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/Torch--Offer-01-MH_Jan2022.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_DETAILS',
            data: {
              skuCode: 'AGS-HW-1313',
            },
          },
        ],
        priority: 3,
        source: 'APPMH',
        rows: 1,
        columns: 1,
        startTime: 1638297000000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner24289',
        name: '',
        description: 'Referral Banner',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/Referral_Banner_MH_Jan22.gif',
              type: 'IMAGE',
            },
            type: 'EXTERNAL_WEB_PAGE',
            data: {
              url: 'ulink://android.agrostar.in/refer-and-earn-details',
            },
          },
        ],
        priority: 3,
        source: 'APPMH',
        rows: 1,
        columns: 1,
        startTime: 1611945000000,
        endTime: 1666549800000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner46451',
        name: '',
        description:
          '1 -  "F1 Maxx Watermelon (1000 Seeds) Seed\nKalash-Candy HYB Watermelon KSP-1434 (50 Gms) Seeds\nSeamons-Bahubali HYB Watermelon (50 gm) seeds\nKalash - Melody Watermelon 50 Gram Seed\nSagar King Watermelon 50gms seeds\nSyngenta Augasta watermelon (800 Seeds)"\n2 - Nunhems Singham Okra - 7000 Seeds\r\nNunhems - Samrat Okra (7000 Seeds)\r\nUPL - HYB Okra Adv 216 (250g) Seed\r\nUPL - Radhika Okra (250 Gms) Seed\r\nUPL - HYB Okra Lavanya (250 Gms) Seed\r\nUPL - Navya Okra (250 Gms) Seed\r\nNunhems Singham Okra - 3500 Seeds\r\nUPL - F1 HYB Okra Venus Plus (250 Gms) Seed\r\nNunhems - Samrat Okra 3500 Seeds\r\nUPL - HYB Okra Mona 002 (250 Gms) Seed',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46451_1.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-S-2427,AGS-S-2923,AGS-S-2956,AGS-S-2815,AGS-S-2477,AGS-S-2180',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46451_2.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-S-2776,AGS-S-1295,AGS-S-4046,AGS-S-2535,AGS-S-2396,AGS-S-2881,AGS-S-2882,AGS-S-910,AGS-S-1296,AGS-S-916',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 4,
        source: 'APPMH',
        rows: 1,
        columns: 2,
        startTime: 1643913000000,
        endTime: 1643999400000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner6040',
        name: '',
        description: 'Top_searched_MH',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/top_serched_MH.gif',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CP-241,AGS-HW-1313,AGS-CP-936,AGS-CN-299,AGS-CN-378,AGS-CN-072,AGS-CN-300,AGS-CN-311,AGS-S-1777,AGS-CP-602,AGS-CN-004,AGS-S-4057,AGS-CN-219,AGS-S-2623,AGS-CN-188,AGS-CN-425,AGS-CP-600,AGS-CP-601,AGS-CP-203,AGS-CN-035,AGS-CN-444,AGS-CP-956,AGS-CN-119,AGS-CP-189,AGS-CP-787,AGS-S-2407,AGS-CP-938,AGS-CP-954,AGS-CP-199,AGS-CN-306,AGS-CN-449,AGS-KIT-515,AGS-CN-307,AGS-CN-308,AGS-CN-316,AGS-CP-960,AGS-KIT-516,AGS-CN-647,AGS-CP-896,AGS-CN-194',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 4,
        source: 'APPMH',
        rows: 1,
        columns: 1,
        startTime: 1611945000000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner33562',
        name: '',
        description: 'Tarp_Bogo_Week_Offers',
        language: 'gu',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/BOGO_Offer_MH_Jan22_Harware.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-HW-775,AGS-HW-774,AGS-HW-773,AGS-HW-1354,AGS-HW-1353,AGS-HW-1229,AGS-HW-1210,AGS-HW-1286,AGS-HW-863,AGS-HW-1144,AGS-HW-710,AGS-HW-708,AGS-HW-1273',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 4,
        source: 'APPMH',
        rows: 1,
        columns: 1,
        startTime: 1642357800000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner46452',
        name: '',
        description:
          '1 -  Cabriotop (Pyraclostrobin + Metiram) 600 gm\nMATCO (Metalaxyl 8% WP+Mancozeb 64%) 1 Kg\nTaqat(Hexaconazole 5% + Captan 70% WP ) 500 gm\nCabriotop (Pyraclostrobin + Metiram) 300 gm\nMATCO (Metalaxyl 8% WP+Mancozeb 64%) 500 Gm\nTaqat(Hexaconazole 5% + Captan 70% डब्ल्यू पी ) 250 gm\nMATCO (Metalaxyl 8%WP+Mancozeb 64 %) 250 Gms\n2 - Super Sona (250 Gms)\nSugarcane Special (500 Gms)\nGodrej Vipul Booster 250 ml',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46452_1.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CP-084,AGS-CP-665,AGS-CP-496,AGS-CP-611,AGS-CP-171,AGS-CP-580,AGS-CP-170',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46452_2.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CN-030,AGS-CN-208,AGS-CN-182',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 5,
        source: 'APPMH',
        rows: 1,
        columns: 2,
        startTime: 1643913000000,
        endTime: 1643999400000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner6030',
        name: '',
        description: 'Top Rated_MH',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/top_rated_MH_1.gif',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-HW-1313,AGS-S-4057,AGS-CN-311,AGS-CN-004,AGS-CN-072,AGS-S-2373,AGS-HW-774,AGS-CN-300,AGS-HW-775,AGS-S-2407,AGS-CP-492,AGS-CP-488,AGS-CP-241,AGS-CN-378,AGS-HW-773,AGS-CP-141,AGS-HW-1159,AGS-S-2498,AGS-S-3143,AGS-HW-1360,AGS-CP-600,AGS-CP-917,AGS-CP-136,AGS-CP-895,AGS-S-2623,AGS-CN-180,AGS-HW-1244,AGS-CN-299,AGS-HW-1361,AGS-CN-035,AGS-HW-1359,AGS-CP-030,AGS-HW-863,AGS-CN-030,AGS-CN-444,AGS-CN-602,AGS-CN-425,AGS-CN-069,AGS-HW-1175,AGS-CN-074,AGS-CP-471,AGS-HW-710,AGS-HW-862,AGS-CN-222,AGS-KIT-322,AGS-CP-660,AGS-CP-659,AGS-KIT-515,AGS-S-2535,AGS-CP-703,AGS-CP-245,AGS-CN-446,AGS-CN-188,AGS-CP-661,AGS-S-694,AGS-CN-448,AGS-CP-456,AGS-CP-189,AGS-CN-119,AGS-CN-070,AGS-CP-787,AGS-CP-1007,AGS-CN-309,AGS-CP-374,AGS-CP-470,AGS-CP-602,AGS-CP-562',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 5,
        source: 'APPMH',
        rows: 1,
        columns: 1,
        startTime: 1611945000000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner46453',
        name: '',
        description:
          '1 -  Peak Booster (Triacontanol 0.1% EW) 1 litre\r\nPeak Booster (Triacontanol 0.1% EW) 500 ml\r\nPeak Booster (Triacontanol 0.1% EW) 250 ml\n2 - Sumitomo Hoshi GA 0.001% 1 Lt\nTata Bahaar - 1 lit\nNew Florofix (250 Gms)\nMH TATA Bahaar - 500 ml\nMH Sumitomo Hoshi GA 0.001% 500 ml',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46453_1.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CP-952,AGS-CP-953,AGS-CP-954',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46453_2.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CN-180,AGS-CN-311,AGS-CN-378,AGS-CN-072,AGS-CN-254',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 6,
        source: 'APPMH',
        rows: 1,
        columns: 2,
        startTime: 1643913000000,
        endTime: 1643999400000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner11146',
        name: '',
        description: 'CropWise_Article',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/Crop_Artical_MH_1_AS.jpg',
              type: 'IMAGE',
            },
            type: 'NO_CLICK',
            data: null,
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Crop_Artical_MH_2_AS.jpg',
              type: 'IMAGE',
            },
            type: 'NO_CLICK',
            data: null,
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Crop_Artical_MH_3_AS.jpg',
              type: 'IMAGE',
            },
            type: 'NO_CLICK',
            data: null,
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Crop_Artical_MH_4_AS.jpg',
              type: 'IMAGE',
            },
            type: 'ARTICLE_DETAILS',
            data: {
              articleId: '61adc9dafd99f9db45b7263e',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Crop_Artical_MH_5_AS.jpg',
              type: 'IMAGE',
            },
            type: 'ARTICLE_DETAILS',
            data: {
              articleId: '616d6d5ffd99f9db45319c77',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Crop_Artical_MH_6_AS.jpg',
              type: 'IMAGE',
            },
            type: 'NO_CLICK',
            data: null,
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Crop_Artical_MH_7_AS.jpg',
              type: 'IMAGE',
            },
            type: 'NO_CLICK',
            data: null,
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Crop_Artical_MH_8_AS.jpg',
              type: 'IMAGE',
            },
            type: 'NO_CLICK',
            data: null,
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Crop_Artical_MH_9_AS.jpg',
              type: 'IMAGE',
            },
            type: 'NO_CLICK',
            data: null,
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Crop_Artical_MH_10_AS.jpg',
              type: 'IMAGE',
            },
            type: 'ARTICLE_DETAILS',
            data: {
              articleId: '61827569fd99f9db45a141d1',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Crop_Artical_MH_11_AS.jpg',
              type: 'IMAGE',
            },
            type: 'ARTICLE_DETAILS',
            data: {
              articleId: '618a8004fd99f9db453bd35b',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Crop_Artical_MH_12_AS.jpg',
              type: 'IMAGE',
            },
            type: 'NO_CLICK',
            data: null,
          },
        ],
        priority: 6,
        source: 'APPMH',
        rows: 4,
        columns: 3,
        startTime: 1601317800000,
        endTime: 1646073000000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
    {
      type: 'REDIRECT_ENTITY',
      data: {
        code: 'Banner46454',
        name: '',
        description:
          '1 -  Kitoguard (250 Ml)\nMH KITOGUARD (100 ML)\nSanjeevani (Trichoderma Viride) Powder (1 Kg)\nIPL Nematofree Plus (Verticillium Chlamydosporium) 1 Kg\nIPL Kalichakra (Metarhizium anisopliae) 1 Kg\nIPL Phasal Rakshak (Pseudomonas fluorescens) 1 Kg\n2 - MH TATA Solubor - 1 Kg\nNutriPro Boron 20% - 1 Kg\nNutriPro Boron 20% - 500 gm',
        language: 'mr',
        data: [
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46454_1.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CP-431,AGS-CP-314,AGS-CP-573,AGS-CP-421,AGS-CP-419,AGS-CP-418',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
          {
            media: {
              url: 'https://static.agrostar.in/static/Banner46454_2.jpg',
              type: 'IMAGE',
            },
            type: 'PRODUCT_LIST',
            data: {
              baseUrl: 'https://communication.agrostar.in',
              params: {
                language: 'mr',
                sku: 'AGS-CN-074,AGS-CN-307,AGS-CN-306',
                sortBy: 'sellingPrice',
              },
              serviceEndpoint: '/shopaggregator/v1/products',
            },
          },
        ],
        priority: 7,
        source: 'APPMH',
        rows: 1,
        columns: 2,
        startTime: 1643913000000,
        endTime: 1643999400000,
        section: 'SHOP',
      },
      section: 'REDIRECT_ENTITY',
    },
  ],
};
export default feedMockData;
