import React from 'react';
import { CircularProgress } from '@mui/material';
import { Box } from '@mui/system';

export function Loader({ bgColor }) {
  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '80vh',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: '0',
        left: '0',
        backgroundColor: bgColor || 'white',
        zIndex: 10,
      }}
    >
      <CircularProgress />
    </Box>
  );
}
