import React from 'react';
import { styled } from '@mui/material/styles';

const PreviewTextStyled = styled('div')({
  height: '4em',
  position: 'relative',
  overflow: 'hidden',
  lineHeight: '1.3em',
  '.hasMore': {
    '::after': {
      content: '""',
      textAlign: 'right',
      position: 'absolute',
      bottom: '0',
      right: '0',
      width: '100%',
      height: '1.3em',
      background: '#ffffff99',
    },
  },
});

function PreviewText({ text }) {
  let displayText;
  let hasMore;
  if (text && text.length > 170) {
    const truncAt = text.indexOf(' ', 163);
    displayText = `${text.substr(0, truncAt)}...`;
    hasMore = true;
  } else {
    displayText = text;
    hasMore = false;
  }

  return (
    <PreviewTextStyled className={hasMore ? 'hasMore' : ''}>
      {displayText}
    </PreviewTextStyled>
  );
}

export default PreviewText;
