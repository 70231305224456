import React from 'react';
import { injectIntl } from 'react-intl';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { useFetchData } from '../services/useFetchData';
import store from '../utilities/store';
import MessagePage from './MessagePage';

function Success({ setHeaderURLs, intl }) {
  const { loadingStatus } = useFetchData(Success, store, setHeaderURLs, intl);
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;
  return (
    <MessagePage
      messageId="label_your_request_submitted"
      image="/green-tick.png"
    />
  );
}

Success.fetchData = () => ({
  titleId: 'label_your_request_submitted',
  canonical: 'nstate',
  stateURLs: 'home',
  languageURLs: 'home',
  initialData: {},
});

export default injectIntl(Success);
