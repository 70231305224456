import React from 'react';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';
import Stars from './Stars';
import Heading from './Heading';

const RatingWrapper = styled('div')({
  display: 'grid',
  gridTemplateColumns: 'auto auto',
  background: 'white',
  alignItems: 'center',
  height: '8rem',
  marginBottom: '0.75rem',
  width: '100%',
  justifyContent: 'space-around',
});

const ProgressBarWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

const ProgressBar = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  progress: {
    border: '0',
    height: '0.5rem',
    width: '10rem',
    borderRadius: '20px',
    background: '#cccccc',
    '@media (max-width: 360px)': {
      width: '7rem',
    },
  },
  'progress::-webkit-progress-bar': {
    border: '0',
    height: '0.5rem',
    borderRadius: '20px',
    background: '#cccccc',
  },
  'progress::-webkit-progress-value': {
    border: '0',
    height: '0.5rem',
    borderRadius: '20px',
  },
  'progress::-moz-progress-bar': {
    border: '0',
    height: '0.5rem',
    borderRadius: '20px',
    background: '#cccccc',
  },
  '.numberOfRatings': {
    color: '#aaaaaa',
    marginLeft: '0.5rem',
  },
  '.numberOfStar': {
    color: '#aaaaaa',
    marginRight: '0.3rem',
  },
  '.starIcon': {
    color: '#cccccc',
    marginRight: '0.5rem',
    fontSize: '1rem',
  },
});

const StarWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '50%',
});

const Label = styled('h4')({
  color: '#000',
  fontSize: '15px',
  fontWeight: '600',
  margin: '0',
  display: 'flex',
  alignItems: 'center',
});

const FormattedMessageWrapper = styled('span')({
  marginLeft: '0.5rem',
});

const getRatingBarArr = (obj) => {
  const properties = Object.keys(obj).reverse();
  const missing = [];
  for (let i = 1; i <= 5; i += 1) {
    if (properties.indexOf(i) === -1) {
      missing.push(i);
    }
  }
  return missing;
};
function ReviewRating({
  avgRating,
  individualCount,
  showRatingBars,
  totalCount,
}) {
  if (!showRatingBars || !avgRating) {
    return null;
  }
  const RatingBarArr = getRatingBarArr(individualCount);
  return (
    <>
      <Heading>
        <Label>
          <img
            height="1.5rem"
            width="1.8rem"
            alt="Free Home Delivery"
            src="/rating-star.svg"
          />
          <FormattedMessageWrapper>
            <FormattedMessage id="label_Ratings" />
          </FormattedMessageWrapper>
        </Label>
      </Heading>
      <RatingWrapper>
        <StarWrapper>
          <Stars stars={avgRating} />
        </StarWrapper>
        <ProgressBarWrapper>
          {RatingBarArr.reverse().map((key, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ProgressBar key={index}>
              <label htmlFor="file">
                <span className="numberOfStar">{[key]}</span>
                <span className="starIcon">&#9733;</span>
              </label>
              <progress
                value={parseInt(individualCount[key], 10)}
                max={totalCount}
              />
              <span className="numberOfRatings">
                {individualCount[key] || 0}
              </span>
            </ProgressBar>
          ))}
        </ProgressBarWrapper>
      </RatingWrapper>
    </>
  );
}

export default ReviewRating;
