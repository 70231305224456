import React from 'react';
import { styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
const S = {};

S.WrapperDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

S.BackStarsDiv = styled('div')({
  display: 'flex',
  position: 'relative',
  color: '#d3d3d3',
});

S.FrontDiv = styled('div')({
  display: 'flex',
  position: 'absolute',
  top: '0',
  overflow: 'hidden',
  color: '#ffbc0b',
});

S.EmptyDiv = styled('div')({ minWidth: '80px', minHeight: '16px' });

function StarsProductHeader({ stars, size }) {
  /* This is to round the rating to closest .5 or .0 */
  if (!stars) return <S.EmptyDiv />;
  stars = `${(((Math.round((stars * 10) / 5) * 5) / 10) * 20).toString()}%`;

  return (
    <S.WrapperDiv>
      <S.BackStarsDiv>
        <StarIcon sx={{ fontSize: `${size} !important` }} />
        <StarIcon sx={{ fontSize: `${size} !important` }} />
        <StarIcon sx={{ fontSize: `${size} !important` }} />
        <StarIcon sx={{ fontSize: `${size} !important` }} />
        <StarIcon sx={{ fontSize: `${size} !important` }} />
        <S.FrontDiv sx={{ width: stars }}>
          <StarIcon sx={{ fontSize: `${size} !important` }} />
          <StarIcon sx={{ fontSize: `${size} !important` }} />
          <StarIcon sx={{ fontSize: `${size} !important` }} />
          <StarIcon sx={{ fontSize: `${size} !important` }} />
          <StarIcon sx={{ fontSize: `${size} !important` }} />
        </S.FrontDiv>
      </S.BackStarsDiv>
    </S.WrapperDiv>
  );
}

export default StarsProductHeader;
