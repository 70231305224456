import React from 'react';

import { Title } from '../components/Heading';
import IssueCard, { IssueListWrapper } from './IssueCard';

const IssuesTab = ({ tabData }) => {
  const {
    issues: {
      data: { content, label },
    },
  } = tabData;
  return (
    <>
      {content && content.length > 0 && (
        <>
          <Title>{label}</Title>
          <IssueListWrapper>
            {content.map((issue) => (
              <IssueCard issue={issue} key={issue.associationId} />
            ))}
          </IssueListWrapper>
        </>
      )}
    </>
  );
};

IssuesTab.getTabData = (match, search, response) => {
  const { categoryName, issues } = response;
  issues.data.content.forEach((issue) => {
    // eslint-disable-next-line no-param-reassign
    issue.cropName = categoryName;
  });
  return { issues };
};

export default IssuesTab;
