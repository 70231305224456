import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';

const LinkText = styled(Link)({
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#00733e',
  '&:visited': {
    color: '#00733e',
  },
});

const SeeAllLinkWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '10px 0',
  color: '#00733e',
  i: {
    marginLeft: '10px',
    fontSize: '14px',
  },
});

const SeeAllLink = ({ text, href }) => {
  return (
    <SeeAllLinkWrapper>
      <LinkText to={href}>{text}</LinkText>
      <ArrowCircleRightRoundedIcon />
    </SeeAllLinkWrapper>
  );
};

export default withRouter(SeeAllLink);
