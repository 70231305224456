import React from 'react';
import { styled } from '@mui/material/styles';
import IconStar from './IconStar';

const S = {};

S.WrapperDiv = styled('div')({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
});

S.RatingSpan = styled('span')({
  fontSize: '1rem',
  fontWeight: '700',
  paddingRight: '5px',
  lineHeight: '1.8rem',
});

S.BackStarsDiv = styled('div')({
  display: 'flex',
  position: 'relative',
  color: '#d3d3d3',
});

S.FrontDiv = styled('div')({
  display: 'flex',
  position: 'absolute',
  top: '0',
  overflow: 'hidden',
  color: '#ffbc0b',
});

function Stars({ stars }) {
  let rating = 0;

  /* This is to round the rating to closest .5 or .0 */
  if (stars) {
    rating = `${(((Math.round((stars * 10) / 5) * 5) / 10) * 20).toString()}%`;
  }

  return (
    <S.WrapperDiv>
      <S.RatingSpan>{`${stars}` || ''}</S.RatingSpan>
      <S.BackStarsDiv>
        <IconStar />
        <IconStar />
        <IconStar />
        <IconStar />
        <IconStar />
        <S.FrontDiv sx={{ width: rating }}>
          <IconStar />
          <IconStar />
          <IconStar />
          <IconStar />
          <IconStar />
        </S.FrontDiv>
      </S.BackStarsDiv>
    </S.WrapperDiv>
  );
}

export default Stars;
