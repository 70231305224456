import React, { useState, useEffect } from 'react';
import {
  Switch,
  Route,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { IntlProvider } from 'react-intl';

import CssBaseline from '@mui/material/CssBaseline';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';

import Header from './Header';
import store from '../utilities/store';
import routes from '../utilities/routes';
import strings from '../utilities/strings';
import { getRouteParams } from '../utilities/links';
import useQuery from '../utilities/useQuery';

const Main = styled('div')({
  maxWidth: '600px',
  margin: '0 auto',
  backgroundColor: '#f1f1f1',
});

const theme = createTheme({
  props: { MuiButtonBase: { disableRipple: true } },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          maxWidth: 600,
          margin: '0 auto',
        },
        '::-webkit-scrollbar': {
          // To hide the scrollbar, so that menus don't cause a shift of the page
          width: 0,
          background: 'transparent',
        },
        body: {
          fontFamily: 'Roboto, Noto Sans, sans-serif',
          fontSize: '14px',
          color: '#333',
          background: '#E5E5E5',
        },
        'a, a: visited': {
          textDecoration: 'none',
          color: 'inherit',
        },
        '.clearfix': {
          clear: 'both',
        },
      },
    },
  },
  palette: {
    primary: {
      main: 'rgb(155,31,36)',
    },
  },
});

Sentry.init({
  dsn: 'https://2ac229cabd3c4e57a45ee916cdba09f4@o1305558.ingest.sentry.io/4505465226067968',
  integrations: [
    new Sentry.BrowserTracing({ tracePropagationTargets: ['agrostar'] }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 0.001,
  replaysSessionSampleRate: 0.01,
  replaysOnErrorSampleRate: 0.1,
});
const SentryRoute = Sentry.withSentryRouting(Route);

function Page({ initialLanguage }) {
  const query = useQuery();
  const [showHeader, setShowHeader] = useState(
    query?.get('showHeader') !== 'false',
  );
  const [headerURLs, setHeaderURLs] = useState({
    stateURLs: store.stateURLs,
    languageURLs: store.languageURLs,
  });

  const history = useHistory();
  history.listen(() => {
    window.scrollTo(0, 0);
  });

  const location = useLocation();
  const params = useParams();
  const [language, setLanguage] = useState(initialLanguage);

  useEffect(() => {
    const { language } = getRouteParams({ params }, location.search);
    setLanguage(language);
  }, [location, params]);

  /*
   * Note: We are not using withRouter to wrap the component because it
   * seems to cause useEffect to fire in the page when this component's state is
   * changed (headerURLs). The component is getting remounted. We are
   * avoiding this by use useLocation and useParams in the page, and
   * setting the dependencies as location.pathname and location.search.
   */
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Switch>
        {routes.map((r) => (
          <SentryRoute path={r.path} exact={r.exact} key={r.path}>
            <IntlProvider locale="en" messages={strings[language]}>
              {showHeader && <Header headerURLs={headerURLs} />}
              <Main>
                <r.component setHeaderURLs={setHeaderURLs} />
              </Main>
            </IntlProvider>
          </SentryRoute>
        ))}
      </Switch>
    </ThemeProvider>
  );
}

export default Sentry.withProfiler(Page);
