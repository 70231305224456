import React from 'react';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { Card, Grid, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import styled from '@emotion/styled';
import AddRemoveBagItem from './AddRemoveBagItem';
import { getRouteParams, makeProductHref } from '../utilities/links';
import slugify from '../utilities/slugify';
import { FormattedMessage } from 'react-intl';
import { getStateBySlug } from '../utilities/states';

const ProductPriceDetail = styled('div')({
  fontSize: '12px',
  margin: '5px 0',
  lineHeight: '16px',
  color: '#666666',
});
const ProductSellingPrice = styled('span')({
  color: '#00733E',
  marginLeft: '3px',
});

function BagItems({
  match,
  search,
  cartProducts,
  handleRemove,
  isAnyCartChange,
  setIsAnyCartChange,
}) {
  let history = useHistory();
  const { lang, state } = getRouteParams(match, search);

  return (
    <div>
      {cartProducts.map((product) => {
        return (
          <Card
            sx={{ border: 'none', marginTop: '12px', padding: '10px' }}
            variant={'outlined'}
            key={product.skuCode}
          >
            <Grid
              container
              direction={`row`}
              alignItems={'flex-start'}
              justifyContent={'space-between'}
              spacing={0}
            >
              <Grid item sx={{ width: '80px' }}>
                <Link
                  to={{
                    pathname: makeProductHref(
                      match,
                      search,
                      slugify(product.productName[lang]),
                      product.skuCode,
                    ),
                    state: { from: history?.location?.pathname },
                  }}
                >
                  <img
                    src={product.image}
                    width="100%"
                    height="100%"
                    alt={product.productName[lang]}
                  />
                </Link>
              </Grid>
              <Grid item xs={8}>
                <Grid
                  container
                  direction={`column`}
                  alignItems={'flex-start'}
                  justifyContent={'space-between'}
                  spacing={0}
                >
                  <Grid
                    container
                    direction={`row`}
                    alignItems={'flex-start'}
                    justifyContent={'space-between'}
                    spacing={0}
                  >
                    <Grid item xs={10}>
                      <Typography
                        variant={'subtitle1'}
                        sx={{
                          fontSize: '14px',
                          lineHeight: `1.3`,
                          marginLeft: -1,
                        }}
                      >
                        {product.productName[lang]}
                        <br />
                        <ProductPriceDetail>
                          <FormattedMessage id="label_price_per_quantity" />:
                          {product.sellingPrice > 0 && (
                            <ProductSellingPrice>
                              ₹{product.sellingPrice || 0}
                            </ProductSellingPrice>
                          )}
                        </ProductPriceDetail>
                      </Typography>
                    </Grid>
                    <Grid item xs={1} sx={{ textAlign: 'center' }}>
                      <IconButton
                        size="large"
                        sx={{
                          position: 'relative',
                          top: '-13px',
                          color: '#999999',
                          padding: 0,
                        }}
                        onClick={() => handleRemove(product)}
                      >
                        <Close sx={{ height: '40px' }} />
                      </IconButton>
                    </Grid>
                  </Grid>

                  <Grid sx={{ width: '100%' }}>
                    <AddRemoveBagItem
                      state={getStateBySlug(state).name}
                      product={product}
                      isAnyCartChange={isAnyCartChange}
                      setIsAnyCartChange={setIsAnyCartChange}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        );
      })}
    </div>
  );
}

export default BagItems;
