import React from 'react';
import { FormattedMessage } from 'react-intl';

import apiCall from '../utilities/apiCall';
import { getRouteParams, makeShopNotAvailableHref } from '../utilities/links';
import ProductCard from '../shop/ProductCard';
import { getAppSource, isAgriShopEnabled } from '../utilities/states';
import { Title } from '../components/Heading';
import useQuery from '../utilities/useQuery';
import { Typography } from '@mui/material';
import ClevertapService from '../services/ClevertapService';

export default function ListPage({ data, titleId }) {
  const { products } = data;
  const query = useQuery();
  const appLinks = query.get('appLinks');
  const crop = query.get('crop');

  if (products.length === 0) {
    return <FormattedMessage id="no_products_found_title" />;
  }

  return (
    <div style={{ margin: '10px', backgroundColor: '#f1f1f1' }}>
      {appLinks === 'saathi' ? (
        <Typography variant="h2" fontSize={16} fontWeight={700} sx={{ pb: 1 }}>
          {crop && `${crop}: `}
          <FormattedMessage id="label_products" />
        </Typography>
      ) : (
        <Title>{titleId && <FormattedMessage id={titleId} />}</Title>
      )}

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          columnGap: '8px',
          rowGap: '8px',
        }}
      >
        {products.map((p) => (
          <div
            key={p.skuCode}
            onClick={() =>
              ClevertapService.pushEvent(`Card Clicked`, {
                Type: 'Product',
                SkuCode: p?.skuCode,
                'Product Name': p?.productName,
              })
            }
          >
            <ProductCard key={p.skuCode} product={p} />
          </div>
        ))}
      </div>
    </div>
  );
}

ListPage.fetchData = async (match, search, api, params = {}) => {
  const { language, state } = getRouteParams(match, search);
  const query = new URLSearchParams(search);
  const isB2B = query.get('source')?.toLowerCase()?.includes('b2b');
  if (!isAgriShopEnabled(state)) {
    return { redirectURL: makeShopNotAvailableHref(match, search, state) };
  }

  const url = new URL(api);
  Object.keys(params).forEach((param) => {
    url.searchParams.append(param, params[param]);
  });
  url.searchParams.append('language', language);
  const response = await apiCall(url.href, {
    Source: isB2B ? query.get('source') : getAppSource(state),
  });

  const initialData = { products: response.responseData.productGists };

  /*
   * Canonical: The state affects availability, so even if the ID is present,
   * the API doesn't return the product details if it is not available for the state.
   * Thus, canonical has to be self.
   */
  return {
    initialData,
    titleId: 'shop_home',
    stateURLs: 'shop',
    seoData: {},
    canonical: 'self',
  };
};
