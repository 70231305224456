/* eslint-disable camelcase */
import React from 'react';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

import Heading from '../components/Heading';

const ProductHighlightWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  marginBottom: '12px',
  background: '#ffffff',
  lineHeight: '24px',
  table: {
    borderCollapse: 'collapse',
    '.light - text': {
      color: 'grey',
    },
    td: {
      lineHeight: '1.5em',
      border: '1px solid rgba(232, 232, 232, 1)',
      width: '50%',
      paddingLeft: '0.5rem',
    },
  },
});

const Label = styled('h4')({
  color: '#000',
  fontSize: '15px',
  fontWeight: '400',
  margin: '0',
});

function ProductHighlight({ details }) {
  if (
    details === null ||
    details[1] === undefined ||
    details[1].attributes == null
  ) {
    return null;
  }
  return (
    <ProductHighlightWrapper>
      <Heading>
        <Label>
          {' '}
          <FormattedMessage id="label_product_highlights" />
        </Label>
      </Heading>
      {details[1] && details[1].attributes && (
        <table>
          <tbody>
            {details[1].attributes.map(({ key, value, attr_id }) => (
              <tr key={attr_id}>
                <td>{key}</td>
                <td className="light-text">{value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </ProductHighlightWrapper>
  );
}

export default ProductHighlight;
