/*
 * Popular tags changes very often, but we don't know when it changes. Best is to refresh this
 * list on a cron-job daily. This is just a list of popular tags, it does not hurt much to have
 * the wrong list, we just show the list of popular tags as of yesterday.
 *
 * Tag mapping (article tag code like TAG0001) can change frequently, but when a new tag is added,
 * it must return the correct mapping. Otherwise, the links in Article Pages will be incorrect.
 *
 * Since the mapping functions are async, they can't be called during a render. If it is needed
 * at render time, it must be fetched during fetchData() itself.
 *
 * todo: we can make this a cache with timeout using LRUCache or even a simple expiry global
 * variable.
 */

import apiCall from './apiCall';
import { getEnv } from './getEnv';

const slugTagMapping = {};
const codeTagMapping = {};

async function loadTags() {
  console.log('Loading tags ...');
  const tagMappingEndpoint = `${getEnv(
    'FWA_API_URL',
  )}/contentservice/v1/tagmapping`;

  const tagMappingResponse = await apiCall(tagMappingEndpoint);
  const tagMapping = tagMappingResponse.responseData;
  if (!tagMapping || tagMapping.length === 0) {
    console.error(`Could not load tag mappings from ${tagMappingEndpoint}`);
    console.log(tagMapping);
  }

  tagMapping.forEach((tag) => {
    const slug = tag.post_tag_name.replace(/ /g, '-').toLowerCase();
    slugTagMapping[slug] = tag.post_tag_name;
    codeTagMapping[tag.article_tag_name] = tag.post_tag_name;
  });
}

async function loadTagsRequest(req, res) {
  await loadTags();
  res.send('Tags refreshed');
}

function tagToSlug(tag) {
  return tag.replace(/ /g, '-').toLowerCase();
}

async function slugToTag(slug) {
  /*
   * We cannot use an automatic conversion like the tagToSlug function because
   * The slug agrostar-delivery needs to be converted to the tag AgroStar Delivery
   */
  if (!slugTagMapping[slug]) {
    await loadTags();
  }
  return slugTagMapping[slug];
}

async function codeToTag(code) {
  if (!codeTagMapping[code]) {
    await loadTags();
  }
  return codeTagMapping[code];
}

export { loadTags, loadTagsRequest, tagToSlug, slugToTag, codeToTag };
