import React from 'react';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

import {
  facebookLink,
  instagramLink,
  playStoreLink,
  twitterLink,
} from '../constants/links';

const FooterWrapper = styled('div')({
  padding: '10px 10px 0px',
  backgroundImage: 'url(/footer-background.png)',
  backgroundColor: '#f1f1f1',
  height: '100%',
});

const FooterContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const FooterItems = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-evenly',
  height: '150px',
  padding: '5px',
});

const Icon = styled('div')({
  height: '60px',
  borderRadius: '50%',
});

const Header = styled('div')({
  fontSize: '14px',
  fontWeight: 'bold',
  color: '#000',
  textAlign: 'center',
  marginBottom: '10px',
  '@media (max-width: 375px)': {
    height: '50px',
  },
});

const SubHeader = styled('div')({
  fontSize: '12px',
  color: '#968e8e',
  textAlign: 'center',
  height: '40px',
});

const SocialMediaContainer = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
});

const SocialMediaWrapper = styled('div')({
  display: 'flex',
  width: '10rem',
  justifyContent: 'space-between',
});

const SocialMediaIcon = styled('a')({
  display: 'inline-block',
  cursor: 'pointer',
});

const GooglePlayImage = styled('a')({
  display: 'block',
  textAlign: 'center',
});

const Footer = ({ lang }) => {
  const playLogoLang = lang === 'or' ? 'en' : lang;
  const playLogo = `https://play.google.com/intl/en_us/badges/images/generic/${playLogoLang}_badge_web_generic.png`;

  return (
    <>
      <FooterWrapper>
        <FooterContainer>
          <FooterItems>
            <Icon>
              <img
                height="60"
                width="60"
                alt="Free Home Delivery"
                src="/footer-delivery.svg"
              />
            </Icon>
            <Header>
              <FormattedMessage id="label_home_delivery" />
            </Header>
            <SubHeader>
              <FormattedMessage id="label_no_delivery_charges" />
            </SubHeader>
          </FooterItems>
          <FooterItems>
            <Icon>
              <img
                height="61"
                width="61"
                alt="200+ Brands"
                src="/footer_brands.svg"
              />
            </Icon>
            <Header>
              <FormattedMessage id="label_200+_brands" />
            </Header>
            <SubHeader>
              <FormattedMessage id="label_best_quality" />
            </SubHeader>
          </FooterItems>
          <FooterItems>
            <Icon>
              <img
                height="61"
                width="61"
                alt="100% Orignal Products"
                src="/footer_original.svg"
              />
            </Icon>
            <Header>
              <FormattedMessage id="label_original_products" />
            </Header>
            <SubHeader>
              <FormattedMessage id="label_products_with_proper_bill" />
            </SubHeader>
          </FooterItems>
        </FooterContainer>
        <SocialMediaContainer>
          <GooglePlayImage href={playStoreLink} target="_blank">
            <img
              height="50px"
              width="129px"
              alt="Get it on Google Play"
              src={playLogo}
            />
          </GooglePlayImage>
          <SocialMediaWrapper>
            <SocialMediaIcon href={instagramLink} target="_blank">
              <img
                height="50px"
                width="35px"
                alt="instagram-icon"
                src="/insta.svg"
              />
            </SocialMediaIcon>
            <SocialMediaIcon href={facebookLink} target="_blank">
              <img
                height="50px"
                width="40px"
                alt="facebook-icon"
                src="/fb.svg"
              />
            </SocialMediaIcon>
            <SocialMediaIcon href={twitterLink} target="_blank">
              <img
                height="50px"
                width="40px"
                alt="twitter-icon"
                src="/twitter.svg"
              />
            </SocialMediaIcon>
          </SocialMediaWrapper>
        </SocialMediaContainer>
      </FooterWrapper>
    </>
  );
};

export default Footer;
