export const playStoreLink =
  'https://play.google.com/store/apps/details?id=com.ulink.agrostar&utm_source=farmer-webapp';

export const corporateLink = 'https://corporate.agrostar.in';

export const instagramLink = 'https://www.instagram.com/agrostar_in/?hl=en';

export const facebookLink = 'https://www.facebook.com/AgroStar.India/';

export const twitterLink = 'https://twitter.com/agrostar_in?lang=en';
