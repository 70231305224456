/* eslint-disable camelcase */
import React from 'react';
import { styled } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl';

import Heading from '../components/Heading';

const OtherDetailsWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '8px',
  marginBottom: '12px',
  background: '#ffffff',
  lineHeight: '24px',
  ul: {
    margin: '0',
    padding: '0 11px',
    li: {
      lineHeight: '1.5em',
      listStyleType: '"- "',
    },
  },
});

const Label = styled('h4')({
  color: '#000',
  fontSize: '15px',
  fontWeight: '400',
  margin: '0',
});

function OtherDetails({ details }) {
  if (
    details === null ||
    details[2] === undefined ||
    details[2].attributes == null
  ) {
    return null;
  }
  return (
    <OtherDetailsWrapper>
      <Heading>
        <Label>
          <FormattedMessage id="label_other_details" />
        </Label>
      </Heading>
      {details[2] && details[2].attributes && (
        <ul>
          {details[2].attributes.map(({ key, value, attr_id }) => (
            <li key={attr_id}>
              {key}:<b>{value}</b>
            </li>
          ))}
        </ul>
      )}
    </OtherDetailsWrapper>
  );
}

export default OtherDetails;
