import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { Grid, Typography, TextField, Button, MenuItem } from '@mui/material';
import { ArrowForwardTwoTone, Store } from '@mui/icons-material';
import { getAppSource, getStateBySlug } from '../utilities/states';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { getRouteParams, makeCheckoutPagesHref } from '../utilities/links';
import { Box } from '@mui/system';
import { useHistory } from 'react-router-dom';
import { Address, AddressLocalisation } from '../services/cartService';

function AddressForm({
  setHeaderURLs,
  intl,
  shippingAddress,
  setShippingAddress,
  setIsAddressChange,
  edit,
}) {
  const { loadingStatus, match, search } = useFetchData(
    AddressForm,
    Store,
    setHeaderURLs,
    intl,
    true,
  );
  const { state, lang } = getRouteParams(match, search);
  const source = getAppSource(state);
  const history = useHistory();
  const [districts, setDistricts] = useState(
    edit ? [shippingAddress.district] : [],
  );
  const [talukas, setTalukas] = useState(edit ? [shippingAddress.taluka] : []);
  const [villages, setVillages] = useState(
    edit ? [shippingAddress.village] : [],
  );

  const [address, setAddress] = useState(
    shippingAddress || {
      state,
      pinCode: '',
      district: { label: '', value: '' },
      fullName: '',
      taluka: { label: '', value: '' },
      village: { label: '', value: '' },
      street: '',
    },
  );

  useEffect(() => {
    const otpVerified = localStorage?.getItem('otpVerified');
    if (!otpVerified) {
      return history.push(makeCheckoutPagesHref(match, search, 'signin'));
    } else {
      setAddress({
        ...address,
        state: {
          label: getStateBySlug(address?.state).name,
          value: getStateBySlug(address?.state).name,
        },
      });
    }
  }, []);

  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === 'pinCode') {
      setAddress({
        ...address,
        district: { label: '', value: '' },
        taluka: { label: '', value: '' },
        village: { label: '', value: '' },
        [name]: value,
      });
    }
    if (name === 'district') {
      setAddress({
        ...address,
        taluka: { label: '', value: '' },
        village: { label: '', value: '' },
        [name]: { value },
      });
    }
    if (name === 'taluka') {
      setAddress({
        ...address,
        village: { label: '', value: '' },
        [name]: { value },
      });
    }
    if (name === 'village') {
      setAddress({ ...address, [name]: { value } });
    }
    if (name === 'street' || name === 'fullName') {
      setAddress({ ...address, [name]: value });
    }
  };

  const handlepinCodeChange = async (event) => {
    handleChange(event);
    try {
      if (event.target.value.length === 6) {
        const districtResponse = await AddressLocalisation.getDistricts(
          source,
          lang,
          event.target.value,
        );
        if (districtResponse?.status === 400) {
          localStorage.removeItem('otpVerified');
          history.push(makeCheckoutPagesHref(match, search, 'signin'));
        }
        setDistricts([...districtResponse?.responseData]);
      }
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleDistrictChange = async (event) => {
    handleChange(event);
    try {
      const talukaResponse = await AddressLocalisation.getTalukas(
        source,
        lang,
        address.pinCode,
        event.target.value,
      );
      if (talukaResponse?.status === 400) {
        localStorage.removeItem('otpVerified');
        history.push(makeCheckoutPagesHref(match, search, 'signin'));
      }
      setTalukas([...talukaResponse?.responseData]);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleTalukaChange = async (event) => {
    handleChange(event);
    try {
      const villageResponse = await AddressLocalisation.getVillages(
        source,
        lang,
        address.pinCode,
        address.district.value,
        event.target.value,
      );
      if (villageResponse?.status === 400) {
        localStorage.removeItem('otpVerified');
        history.push(makeCheckoutPagesHref(match, search, 'signin'));
      }
      setVillages([...villageResponse?.responseData]);
    } catch (e) {
      console.log(e.message);
    }
  };

  const handleAddAddress = async () => {
    const addressToAdd = {
      addressId: 0,
      district: address?.district?.value,
      eddText: '',
      fullName: address?.fullName,
      id: 0,
      isOnlinePaymentAvailable: false,
      language: 'mr',
      pinCode: address?.pinCode.toString(),
      state: getStateBySlug(address?.state).name,
      street: address?.street,
      taluka: address?.taluka?.value,
      village: address?.village?.value,
    };
    try {
      await Address.addAddress(addressToAdd, source);
      history.push(makeCheckoutPagesHref(match, search, 'checkout'));
    } catch (e) {
      console.log('Failed to add address: ', e.message);
    }
  };

  const handleUpdateAddress = async () => {
    if (address.pinCode.length !== 6) {
      return alert(`Enter correct pinCode`);
    }
    const addressToUpdate = {
      addressId: address.id,
      id: 0,
      isOnlinePaymentAvailable: false,
      language: 'mr',
      phoneNumber: '',
      postOffice: 'post',
      district: address?.district?.value,
      fullName: address?.fullName,
      pinCode: address?.pinCode.toString(),
      state: getStateBySlug(address?.state).name,
      street: address?.street,
      taluka: address?.taluka?.value,
      village: address?.village?.value,
    };
    try {
      await Address.updateAddress(addressToUpdate);
      setShippingAddress(address);
      setIsAddressChange('done');
    } catch (e) {
      console.log('Failed to add address: ', e.message);
    }
  };
  return (
    <>
      <Box
        sx={{ minHeight: '95vh', padding: '30px 20px', background: 'white' }}
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (edit) handleUpdateAddress();
            else handleAddAddress();
          }}
        >
          <Typography variant="h6" gutterBottom mb={3}>
            <FormattedMessage id="add_address" />
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <TextField
                id="state"
                name="state"
                label={<FormattedMessage id="label_state" />}
                fullWidth
                variant="standard"
                value={address?.state?.value}
                disabled={true}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="pinCode"
                name="pinCode"
                label={<FormattedMessage id="label_pincode" />}
                fullWidth
                pattern="[0-9]*"
                variant="standard"
                value={address?.pinCode}
                onChange={handlepinCodeChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                id="district"
                name="district"
                label={<FormattedMessage id="label_select_district" />}
                fullWidth
                variant="standard"
                value={address?.district?.value}
                onChange={handleDistrictChange}
                disabled={!address?.pinCode}
              >
                {districts.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                id="taluka"
                name="taluka"
                label={<FormattedMessage id="label_select_taluka" />}
                fullWidth
                variant="standard"
                value={address?.taluka?.value}
                onChange={handleTalukaChange}
                disabled={!address?.district?.value}
              >
                {talukas.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                select
                id="village"
                name="village"
                label={<FormattedMessage id="label_select_village" />}
                fullWidth
                variant="standard"
                value={address?.village?.value}
                onChange={handleChange}
                disabled={!address?.taluka?.value}
              >
                {villages.map((item) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
                <MenuItem key="other" value="other">
                  <FormattedMessage id="other" />
                </MenuItem>
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="street"
                name="street"
                label={<FormattedMessage id="hint_locality" />}
                fullWidth
                variant="standard"
                value={address?.street}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                id="fullName"
                name="fullName"
                label={<FormattedMessage id="label_name" />}
                fullWidth
                variant="standard"
                value={address?.fullName}
                onChange={handleChange}
              />
            </Grid>
          </Grid>
          <Button
            size="large"
            variant="contained"
            color={`primary`}
            type={'submit'}
            sx={{ width: '100%', margin: '50px 0 20px' }}
            endIcon={<ArrowForwardTwoTone />}
          >
            <FormattedMessage id="add_address" />
          </Button>
        </form>
      </Box>
    </>
  );
}

AddressForm.fetchData = () => ({
  initialData: ' ',
  titleId: 'label_checkout',
});

export default injectIntl(AddressForm);
