import axios from 'axios';
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';

import store from '../utilities/store';
import { allStates, getStateBySlug } from '../utilities/states';
import {
  getRouteParams,
  makeBuyNowHref,
  makeBuyNowSuccessHref,
} from '../utilities/links';
import Heading, { CenteredTitle, Text, SubText } from '../components/Heading';
import { productDataFetcher } from './ProductPage';
import ProductDetails from './ProductDetails';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { useHistory } from 'react-router-dom';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const MainWrapper = styled('div')({
  background: '#fff',
  padding: '10px 20px 20px 20px',
  maxWidth: '600px',
  margin: '0 auto',
  '& .required': {
    color: '#d66565',
    marginLeft: '4px',
  },
  '& .link-text': {
    color: '#00733e',
    textDecoration: 'underline',
  },
});

const SubTextWrapper = styled(SubText)({
  textAlign: 'left',
});

const GooglePlayImage = styled('a')({
  display: 'block',
  textAlign: 'center',
});

const OtherWayWrapper = styled('div')({
  color: '#999999',
  fontSize: '12px',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
});

const StaticWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  div: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

const HeadingWrapper = styled(Heading)({
  color: '#000',
  fontSize: '14px',
  fontWeight: '500',
});

function BuyNowPage({ setHeaderURLs, intl }) {
  const [submitting, setSubmitting] = React.useState(false);
  const [mobileNumber, setMobileNumber] = React.useState('');
  const [name, setName] = React.useState('');
  const [userState, setUserState] = React.useState('');
  const [coupon, setCoupon] = React.useState('');
  const history = useHistory();
  const { data, loadingStatus, match, search } = useFetchData(
    BuyNowPage,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;

  const { language, state } = getRouteParams(match, search);
  const stateName = getStateBySlug(state).name;
  const { response } = data;

  const handleMobileChange = (event) => {
    setMobileNumber(event.target.value);
  };
  const handleNameChange = (event) => {
    setName(event.target.value);
  };
  const handleUserStateChange = (event) => {
    setUserState(event.target.value);
  };
  const handleCouponChange = (event) => {
    setCoupon(event.target.value);
  };

  if (!response) {
    return <FormattedMessage id="label_no_product_available" />;
  }
  const { skuCode, productLabel, productName } = response;

  const playLogoLang = language === 'or' ? 'en' : language;
  const playLogo = `https://play.google.com/intl/en_us/badges/images/generic/${playLogoLang}_badge_web_generic.png`;

  const stateLabel = intl.formatMessage({ id: `state_${state}` });
  const mobileLabel = intl.formatMessage({ id: 'et_hint_phone_number' });
  const nameLabel = intl.formatMessage({ id: 'label_name' });
  const userStateLabel = intl.formatMessage({ id: 'label_state_2' });
  const couponLabel = intl.formatMessage({ id: 'label_coupon' });

  const handleSubmit = async (event) => {
    event.preventDefault(); // Don't submit the form!
    setSubmitting(true);
    const data = {
      phone: mobileNumber,
      name,
      couponCode: coupon,
      sku: skuCode,
      skuName: productLabel,
      state: userState,
    };
    try {
      const response = await axios.post('/product-request', data, {
        headers: { 'Content-Type': 'application/json' },
        validateStatus: (status) => status < 500,
      });

      setSubmitting(false);
      history.push(makeBuyNowSuccessHref(match, search));
    } catch (e) {
      setSubmitting(false);
      console.log('Error submitting product request: ', e.message);
    }
  };

  let submit;
  if (response.isEnabledForRequestedSource) {
    // Button type=submit ensures that validations are enforced
    submit = (
      <Button
        type="submit"
        variant="contained"
        color="success"
        sx={{ width: '100%' }}
      >
        <FormattedMessage id="label_submit" />
      </Button>
    );
  } else {
    submit = (
      <Button variant="contained" disabled sx={{ width: '100%' }}>
        <FormattedMessage
          id="label_product_not_enabled"
          values={{ state: stateLabel }}
        />
      </Button>
    );
  }

  const progress = (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <CircularProgress size={60} />
    </Box>
  );

  return (
    <div>
      <MainWrapper>
        {/* Success will be redirected to the success page by the server using AMP-Redirect-To header */}
        <CenteredTitle>
          <FormattedMessage id="label_thankyou_choosing_agrostar" />
        </CenteredTitle>
        <ProductDetails product={response} />

        <form
          id="buynow-form"
          target="_top"
          onSubmit={handleSubmit}
          on="submit:loader.show,buynow-form.clear,buynow-form.hide"
        >
          <TextField
            label={mobileLabel}
            value={mobileNumber}
            onChange={handleMobileChange}
            required
            inputProps={{ inputMode: 'numeric', pattern: '\\d{10,10}' }}
            fullWidth
            margin="normal"
          />
          <TextField
            label={nameLabel}
            value={name}
            onChange={handleNameChange}
            fullWidth
            margin="normal"
          />
          <FormControl fullWidth sx={{ mt: 2, mb: 1 }}>
            <InputLabel id="demo-simple-select-autowidth-label">
              {userStateLabel}
            </InputLabel>
            <Select
              required
              fullWidth
              autoComplete="state"
              value={userState}
              label={userStateLabel}
              onChange={handleUserStateChange}
              labelId="demo-simple-select-autowidth-label"
              id="demo-simple-select-autowidth"
            >
              {allStates.map((state) =>
                state.shop ? (
                  <MenuItem key={state.slug} value={state.name}>
                    {state.name}
                    {state.localised_name && ` (${state.localised_name})`}
                  </MenuItem>
                ) : null,
              )}
            </Select>
          </FormControl>
          <TextField
            label={couponLabel}
            value={coupon}
            onChange={handleCouponChange}
            fullWidth
            margin="normal"
          />

          {/* todo: convert these to regular inline styles. This is too complex */}
          <SubTextWrapper>
            <Text>
              <FormattedMessage
                id="label_product_is_for_state"
                values={{
                  state: <b>{stateLabel}</b>,
                  product: productName,
                }}
              />
            </Text>
          </SubTextWrapper>

          <SubTextWrapper>
            <Text>
              <FormattedMessage id="label_clicking_submit_agree_laws" />
              <a
                className="link-text"
                // eslint-disable-next-line react/jsx-no-target-blank
                target="_blank"
                href={`https://www.corporate.agrostar.in/apptnc-${language}`}
                rel="noreferrer"
              >
                <FormattedMessage id="label_agrostar_tandc" />
              </a>
            </Text>
          </SubTextWrapper>
          {submitting ? progress : submit}
        </form>
      </MainWrapper>

      {/* todo: put this in a Grid for better alignment */}
      <OtherWayWrapper>
        <HeadingWrapper>
          <FormattedMessage id="label_other_ways_to_purchase" />
        </HeadingWrapper>
        <StaticWrapper>
          <div>
            <SubText>
              <FormattedMessage id="label_download_our_app" />
            </SubText>
            <GooglePlayImage
              href="https://play.google.com/store/apps/details?id=com.ulink.agrostar&utm_source=farmer-webapp"
              target="_blank"
            >
              <img
                height="50px"
                width="129px"
                alt="Get it on Google Play"
                src={playLogo}
              />
            </GooglePlayImage>
          </div>
          <div>
            <SubText>
              <FormattedMessage id="label_give_us_missed_call" />
            </SubText>
            <a id="missed-called-id" href="tel:9503095030">
              <Button
                variant="contained"
                startIcon={<LocalPhoneRoundedIcon />}
                sx={{ color: '#ffaa00', margin: '10px' }}
              >
                <FormattedMessage id="label_missed_call" />
              </Button>
            </a>
          </div>
        </StaticWrapper>
      </OtherWayWrapper>
    </div>
  );
}

BuyNowPage.fetchData = async (match, search) => {
  // This is similar to the product page, so we have a common function.
  // The only difference is that the links should be buynow links instead
  // of product page links.
  return productDataFetcher(match, search, makeBuyNowHref);
};

export default injectIntl(BuyNowPage);
