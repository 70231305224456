import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ArrowForwardTwoTone } from '@mui/icons-material';

import {
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { makeCheckoutPagesHref } from '../utilities/links';

export default function Addresses({
  match,
  search,
  shippingAddress,
  setShippingAddress,
  setIsAddressChange,
}) {
  const history = useHistory();

  const handleAddressSelection = (address) => {
    setIsAddressChange('edit');
    setShippingAddress({ ...address });
  };

  return (
    <>
      <React.Fragment>
        <Typography variant="h6" gutterBottom>
          <FormattedMessage id="new_address" />
        </Typography>
        <Button
          size="large"
          variant="outlined"
          color="success"
          sx={{ width: '100%', margin: '0 0 20px' }}
          onClick={() =>
            history.push(makeCheckoutPagesHref(match, search, 'address'))
          }
          endIcon={<ArrowForwardTwoTone />}
        >
          <FormattedMessage id="title_add_new_address" />
        </Button>
      </React.Fragment>
      <FormControl sx={{ width: '100%' }}>
        <Typography variant="h6" gutterBottom>
          <FormattedMessage id="my_saved_addresses" />
        </Typography>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue={shippingAddress.SAddressId}
          name="radio-buttons-group"
        >
          <FormControlLabel
            value={shippingAddress.SAddressId}
            control={<Radio />}
            key={shippingAddress.SAddressId}
            sx={{
              margin: '10px 0',
              padding: '12px 10px',
              background: 'rgb(0, 100, 0, 0.2)',
              border: '1px solid green',
              borderRadius: '2px',
            }}
            onClick={() => handleAddressSelection(shippingAddress)}
            label={
              <>
                <Typography gutterBottom sx={{ fontSize: '15px' }}>
                  <b>{shippingAddress?.fullName}</b>
                </Typography>
                <Typography
                  gutterBottom
                  sx={{ fontSize: '14px' }}
                >{`${shippingAddress?.village?.value}, ${shippingAddress?.district?.value}, ${shippingAddress?.pinCode}`}</Typography>
              </>
            }
          />
        </RadioGroup>
      </FormControl>
      <Button
        size="large"
        variant="contained"
        color="success"
        sx={{ width: '100%', margin: '16px 0' }}
        onClick={() => setIsAddressChange('done')}
      >
        <FormattedMessage id="label_continue" />
      </Button>
    </>
  );
}
