import React from 'react';

import apiCall from '../utilities/apiCall';
import store from '../utilities/store';
import { getRouteParams, makeShopNotAvailableHref } from '../utilities/links';
import { getAppSource, isAgriShopEnabled } from '../utilities/states';
import CropCard, { CropListWrapper } from './CropCard';
import LayoutWrapper from '../components/Layout';
import { Title } from '../components/Heading';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { injectIntl } from 'react-intl';
import { getEnv } from '../utilities/getEnv';

function CategoryView({ category: { display, children } }) {
  return (
    <LayoutWrapper>
      <Title>{display}</Title>
      <CropListWrapper>
        {children?.map((c) => (
          <CropCard key={c.id} crop={c} />
        ))}
      </CropListWrapper>
    </LayoutWrapper>
  );
}

function CropListPage({ setHeaderURLs, intl }) {
  const { data, loadingStatus } = useFetchData(
    CropListPage,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error' || !data) return <ServerError />;

  const { response } = data;
  const { children } = response;
  return (
    <>
      {children?.map((c) => (
        <CategoryView category={c} key={c.category} />
      ))}
    </>
  );
}

export async function getCropsList(userState, lang) {
  const api = `${getEnv('FWA_API_URL')}/catalogservice/v2/crop/`;

  const url = new URL(api);
  url.searchParams.append('language', lang);

  return apiCall(url.href, { Source: getAppSource(userState) });
}

CropListPage.fetchData = async (match, search) => {
  const { language, state } = getRouteParams(match, search);
  if (!isAgriShopEnabled(state)) {
    return { redirectURL: makeShopNotAvailableHref(match, search, state) };
  }

  const titleId = 'label_all_crops';
  const seoData = {};
  let result = {};

  result = await getCropsList(state, language);

  const initialData = { response: result.responseData || [] };

  // The order of crops can differ for each state, so canonical is self
  const canonical = 'self';
  return {
    initialData,
    titleId,
    canonical,
    stateURLs: 'shop',
    seoData,
  };
};

export default injectIntl(CropListPage);
