import React from 'react';

import { styled } from '@mui/material/styles';
import { Card, Typography } from '@mui/material';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { playStoreLink } from '../constants/links';
import { FormattedMessage } from 'react-intl';
import { Box } from '@mui/system';

const ValuesWrapper = styled('div')({
  margin: '20px 0 12px',
  lineHeight: '24px',
});

const GooglePlayImage = styled('a')({
  display: 'block',
  textAlign: 'center',
});

function AgrostarValues({ lang }) {
  const playLogoLang = lang === 'or' ? 'en' : lang;
  const playLogo = `https://play.google.com/intl/en_us/badges/images/generic/${playLogoLang}_badge_web_generic.png`;

  return (
    <ValuesWrapper>
      <Box
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: 'repeat(4, 150px)',
          gap: '10px',
          padding: '0',
          overflowX: 'scroll',
        }}
      >
        <Card
          sx={{
            display: 'flex',
            alignItems: 'start',
            gap: '10px',
            padding: '10px 5px',
            background: '#d8e5de',
            boxShadow: 'none',
            borderRadius: '10px',
            svg: { width: '45px', height: '45px', marginTop: '8px' },
          }}
        >
          <PhoneInTalkIcon color="success" />
          <Typography variant="subtitle2">
            <FormattedMessage id="ag_value_agri_doctor" />
          </Typography>
        </Card>
        <Card
          sx={{
            display: 'flex',
            alignItems: 'start',
            gap: '10px',
            padding: '10px 5px',
            background: '#f1e5e0',
            boxShadow: 'none',
            borderRadius: '10px',
            img: {
              width: '45px',
              height: '45px',
              minWidth: '45px',
              marginTop: '8px',
            },
          }}
        >
          <img src="/values_original_product.svg" alt="original product" />
          <Typography variant="subtitle2">
            <FormattedMessage id="ag_value_original_product" />
          </Typography>
        </Card>
        <Card
          sx={{
            display: 'flex',
            alignItems: 'start',
            gap: '10px',
            padding: '10px 5px',
            background: '#eadcdd',
            boxShadow: 'none',
            borderRadius: '10px',
            img: { width: '45px', height: '45px', marginTop: '8px' },
          }}
        >
          <img src="/values_weather_info.svg" alt="weather information" />
          <Typography variant="subtitle2">
            <FormattedMessage id="ag_value_climate_info" />
          </Typography>
        </Card>
        <Card
          sx={{
            display: 'flex',
            alignItems: 'start',
            gap: '10px',
            padding: '10px 5px',
            background: '#ece9e0',
            boxShadow: 'none',
            borderRadius: '10px',
            svg: { width: '45px', height: '45px', marginTop: '8px' },
          }}
        >
          <PlayCircleIcon sx={{ color: '#C9A050' }} />
          <Typography variant="subtitle2">
            <FormattedMessage id="ag_value_farming_update" />
          </Typography>
        </Card>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '62px',
          padding: '0 5px',
          marginTop: '10px',
          background: '#FBEBDF',
          border: '4px solid #181332',
          borderRadius: '12px',
        }}
      >
        <img
          src="/valueKisaan.svg"
          alt="valueKisaan"
          style={{ height: '60px', position: 'relative', top: '-3px' }}
        />
        <Typography
          variant="subtitle2"
          fontWeight="bold"
          sx={{ maxWidth: '125px' }}
        >
          <FormattedMessage id="ag_value_user_trust" />
        </Typography>
        <GooglePlayImage href={playStoreLink} target="_blank">
          <img
            height="50px"
            width="129px"
            alt="Get it on Google Play"
            src={playLogo}
          />
        </GooglePlayImage>
      </Box>
    </ValuesWrapper>
  );
}

export default AgrostarValues;
