import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@mui/material';
import { East } from '@mui/icons-material';

import TaskCard from './TaskCard';
import useQuery from '../utilities/useQuery';
import { makeTaskListingPageHref } from '../utilities/links';
import { SubHeadingWrapper, ViewAllButton } from './ExpertVideos';
import { Link } from 'react-router-dom';
import ClevertapService from '../services/ClevertapService';
import { getEnv } from '../utilities/getEnv';
import { getRegionalAppString } from '../utilities/getRegionalAppString';

export default function CropTasks({
  index,
  match,
  search,
  isAndroid,
  cropSchedule,
}) {
  const query = useQuery();
  const mockDate = query.get('mockDate');
  const language = query.get('language');
  const showTasks = query.get('showTasks') === 'false' ? false : true;
  const mockAllTasks = query.get('mockAllTasks');
  const farmerAuthToken = query.get('farmerAuthToken');
  const mockScheduleName = query.get('mockScheduleName');
  const allParams = {
    match,
    search,
    cropName: cropSchedule?.category,
    language,
    mockDate,
    mockAllTasks,
    farmerAuthToken,
    mockScheduleName,
  };
  const viewAllTaskHref = makeTaskListingPageHref(allParams);

  if (!cropSchedule?.tasks?.length) {
    return showTasks && index === 0 ? (
      <p style={{ padding: '0 16px', opacity: 0.9 }}>
        <FormattedMessage id="label_no_tasks_for_today" />
      </p>
    ) : null;
  }
  return (
    <>
      {index === 0 ? (
        <>
          {cropSchedule?.tasks?.length ? (
            <>
              <SubHeadingWrapper style={{ opacity: 0.9 }}>
                {cropSchedule?.tasks?.length === 1 ? (
                  <FormattedMessage
                    id="label_task_for_today"
                    values={{ tasksForToday: cropSchedule?.tasks?.length }}
                  />
                ) : (
                  <FormattedMessage
                    id="label_tasks_for_today"
                    values={{ tasksForToday: cropSchedule?.tasks?.length }}
                  />
                )}
              </SubHeadingWrapper>
              <p
                style={{
                  margin: '5px 0 0',
                  padding: '0 16px',
                  fontSize: '13px',
                  color: '#6b6b6b',
                }}
              >
                <FormattedMessage id="label_tasks_for_today_description" />
              </p>
            </>
          ) : (
            ''
          )}
        </>
      ) : (
        <SubHeadingWrapper>
          <FormattedMessage id="label_tasks" />
        </SubHeadingWrapper>
      )}

      <Grid
        container
        flexDirection={index === 0 ? 'column' : 'row'}
        flexWrap="nowrap"
        gap="10px"
        sx={{
          mt: 1,
          p: '1px 16px 2px',
          overflowX: 'scroll',
          overflowY: 'hidden',
        }}
      >
        {cropSchedule?.tasks?.map((task, taskIndex) => {
          if (task && task?.title) {
            return isAndroid ? (
              <a
                key={taskIndex}
                href={`ulink://android.agrostar.in/internalWebpage?url=${getEnv(
                  'FWA_BASE_URL',
                )}${makeTaskListingPageHref({
                  ...allParams,
                  cropName: task?.category,
                  task: task?.title,
                })}&title=${task?.cropName}`}
                onClick={() => {
                  ClevertapService.pushEvent(`Card Clicked`, {
                    Type: 'Task',
                    'Task Name': task?.taskName,
                    'Task Title': task?.title,
                    'Crop Name': task?.category,
                  });
                }}
                style={{ minWidth: '300px' }}
              >
                <TaskCard
                  index={index}
                  task={task}
                  match={match}
                  search={search}
                />
              </a>
            ) : (
              <Link
                key={taskIndex}
                to={`${makeTaskListingPageHref({
                  ...allParams,
                  cropName: task?.category,
                  task: task?.title,
                })}&title=${task?.cropName}`}
                onClick={() => {
                  ClevertapService.pushEvent(`Card Clicked`, {
                    Type: 'Task',
                    'Task Name': task?.taskName,
                    'Task Title': task?.title,
                    'Crop Name': task?.category,
                  });
                }}
                style={{ minWidth: '300px' }}
              >
                <TaskCard
                  index={index}
                  task={task}
                  match={match}
                  search={search}
                />
              </Link>
            );
          }
          return null;
        })}
      </Grid>

      {index !== 0 && (
        <ViewAllButton
          variant="text"
          endIcon={<East />}
          href={
            isAndroid
              ? `ulink://android.agrostar.in/internalWebpage?url=${getEnv(
                  'FWA_BASE_URL',
                )}${viewAllTaskHref}&title=${cropSchedule?.cropName}`
              : viewAllTaskHref + `&title=${cropSchedule?.cropName}`
          }
          onClick={() =>
            ClevertapService.pushEvent(`Button Clicked`, {
              Type: 'View All Tasks',
              'Crop Name': cropSchedule?.category,
            })
          }
        >
          <FormattedMessage id="label_view_all_tasks" />
        </ViewAllButton>
      )}
    </>
  );
}
