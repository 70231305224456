import React from 'react';
import { Box } from '@mui/system';
import { Button, Drawer, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';

export default function MockDate({
  query,
  openDrawer,
  setOpenDrawer,
  toggleDrawer,
}) {
  const history = useHistory();

  const handleSubmit = (event) => {
    event.preventDefault();
    const mockDate = document.querySelector('#mock-date').value;
    query?.set('mockDate', mockDate);
    history.replace(`/crop-info?${query.toString()}`);
    setOpenDrawer(false);
  };

  return (
    <Drawer
      anchor="bottom"
      open={openDrawer}
      onClose={toggleDrawer('bottom', false)}
    >
      <Box
        role="presentation"
        sx={{ minHeight: '300px', padding: '40px 30px' }}
      >
        <form onSubmit={handleSubmit}>
          <Typography sx={{ mb: 1 }}>Select Mock Date:</Typography>
          <input
            type="date"
            id="mock-date"
            required
            style={{ width: '100%', padding: '10px 13px' }}
          />
          <Button
            fullWidth
            type="submit"
            color="primary"
            variant="contained"
            sx={{ mt: 2 }}
          >
            Submit
          </Button>
        </form>
      </Box>
    </Drawer>
  );
}
