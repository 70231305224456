import React from 'react';
import { styled } from '@mui/system';
import { injectIntl } from 'react-intl';
import axios from 'axios';

import { getRouteParams } from '../utilities/links';
import store from '../utilities/store';
import { Loader } from '../components/Loader';
import { getEnv } from '../utilities/getEnv';
import ServerError from './../components/ServerError';
import { useFetchData } from '../services/useFetchData';

const PolicyContainer = styled('div')({
  padding: '10px',
  h1: {
    textAlign: 'center',
    color: '#9d2123',
  },
  h2: {
    textAlign: 'center',
    color: '#a0a09f',
  },
});

function PolicyPage({ intl, setHeaderURLs }) {
  const { data, match, loadingStatus } = useFetchData(
    PolicyPage,
    store,
    setHeaderURLs,
    intl,
  );

  if (match?.pathname?.includes('/policy/terms-conditions')) {
    try {
      window.location.replace('https://www.corporate.agrostar.in/apptnc-en');
    } catch (e) {
      console.log('window not found');
    }
    console.log(`redirecting to wix site tnc`);
    return;
  }

  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;

  // eslint-disable-next-line react/no-danger
  return <PolicyContainer dangerouslySetInnerHTML={{ __html: data }} />;
}

PolicyPage.fetchData = async (match, search) => {
  const policyLanguage = {
    en: 'en',
    hi: 'hi',
    mr: 'mr',
    gu: 'gu',
    kn: 'en',
    te: 'en',
  };
  const { type, language } = getRouteParams(match, search);
  let policyFile = `${getEnv('FWA_BASE_URL')}/policies/${type}-${
    policyLanguage[language]
  }.html`;
  if (type === 'terms-conditions') {
    policyFile = `${getEnv('FWA_BASE_URL')}/policies/${type}-en.html`;
  }

  const policyDoc = await axios
    .get(policyFile, 'utf8')
    .then((response) => response.data);

  const titleId =
    type === 'return-refund'
      ? 'label_return-refund_policy'
      : 'label_agrostar_tandc';
  return {
    initialData: policyDoc,
    titleId,
    canonical: 'nstate',
  };
};

export default injectIntl(PolicyPage);
