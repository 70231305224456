import React from 'react';

import { Title } from '../components/Heading';
import ProductCard, { ProductListWrapper } from '../shop/ProductCard';

const SeedsTab = ({ tabData }) => {
  const {
    seeds: {
      data: { content, label },
    },
  } = tabData;
  return (
    <>
      {content && content.length > 0 && (
        <>
          <Title>{label}</Title>
          <ProductListWrapper>
            {content.map((p) => (
              <ProductCard key={p.skuCode} product={p} />
            ))}
          </ProductListWrapper>
        </>
      )}
    </>
  );
};

SeedsTab.getTabData = (match, search, response) => {
  const { seeds } = response;

  return { seeds };
};

export default SeedsTab;
