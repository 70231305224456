import React from 'react';

import apiCall from '../utilities/apiCall';
import store from '../utilities/store';
import strings from '../utilities/strings';

import LayoutWrapper from '../components/Layout';
import { Title } from '../components/Heading';
import { TAB_KEY_MAPPING, TAB_ORDER_MAPPING } from '../constants/crops';
import TabsView from './TabsView';
import ArticlesTab from './ArticlesTab';
import IssuesTab from './IssuesTab';
import SeedsTab from './SeedsTab';
import {
  getRouteParams,
  makeCropHref,
  makeShopNotAvailableHref,
} from '../utilities/links';
import slugify from '../utilities/slugify';
import { shopLanguages } from '../utilities/languages';
import {
  getAppSource,
  getStateBySlug,
  allStates,
  isAgriShopEnabled,
} from '../utilities/states';
import PageNotFound from '../misc/NotFoundPage';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { injectIntl } from 'react-intl';
import { getEnv } from '../utilities/getEnv';

const getSelectTabComponent = (tab) => {
  switch (tab) {
    case 'issues':
      return IssuesTab;
    case 'seeds':
      return SeedsTab;
    default:
      return ArticlesTab;
  }
};

const CropPage = ({ setHeaderURLs, intl }) => {
  const { data, loadingStatus, match, search } = useFetchData(
    CropPage,
    store,
    setHeaderURLs,
    intl,
  );
  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;

  if (!data || !data.response || !data.activeTab || !data.tabData)
    return <PageNotFound />;

  const { page } = getRouteParams(match, search);
  const { response, activeTab, tabData } = data;
  const { categoryName, order } = response;
  const TabComponent = getSelectTabComponent(activeTab);
  return (
    <LayoutWrapper>
      <Title>{categoryName}</Title>
      <TabsView order={order} activeTab={activeTab} />
      <TabComponent tabData={tabData} page={page} />
    </LayoutWrapper>
  );
};

export async function getCropDetails(id, userState, lang) {
  const api = `${getEnv(
    'FWA_API_URL',
  )}/shopaggregator/v2/cropconfiguration/${id}`;

  const url = new URL(api);
  url.searchParams.append('language', lang);
  url.searchParams.append('source', getAppSource(userState));

  return apiCall(url.href, { Source: getAppSource(userState) });
}

CropPage.fetchData = async (match, search) => {
  const { id, tab, language, state } = getRouteParams(match, search);
  if (!isAgriShopEnabled(state)) {
    return { redirectURL: makeShopNotAvailableHref(match, search, state) };
  }

  const result = await getCropDetails(id, state, language);
  if (!result || !result.responseData) {
    return {
      titleId: 'page_not_found',
      stateURLs: 'home',
      languageURLs: 'home',
    };
  }
  const seoData = {};
  let activeTab = tab;
  let tabData = {};
  const cropName = result.responseData.categoryName;
  const { order } = result.responseData;

  if (!tab || !TAB_KEY_MAPPING[tab]) {
    activeTab = TAB_ORDER_MAPPING[order[0]].key;
  }

  const titleId = 'title_shop';
  const titleParams = {
    heading: `${cropName} - ${
      strings[language][TAB_ORDER_MAPPING[TAB_KEY_MAPPING[activeTab]].labelKey]
    }`,
  };

  if (getSelectTabComponent(activeTab).fetchData) {
    tabData = await getSelectTabComponent(activeTab).fetchData(
      match,
      search,
      result.responseData,
    );
  } else {
    tabData = getSelectTabComponent(activeTab).getTabData(
      match,
      search,
      result.responseData,
    );
  }

  const languageSlugs = {};
  // Crop Page is supported only for shop states
  const jobs = shopLanguages.map((lang) =>
    (async (l) => {
      const crop = await getCropDetails(id, state, l);
      if (crop && crop.responseData) {
        languageSlugs[l] = slugify(crop.responseData.categoryName);
      }
    })(lang),
  );
  await Promise.all(jobs);

  const languageURLs = {};
  getStateBySlug(state).supportedLanguages.forEach((lang) => {
    const slug = languageSlugs[lang];
    languageURLs[lang] = slug
      ? makeCropHref(match, search, slug, id, tab, lang)
      : 'languageNa';
  });

  const stateURLs = {};
  allStates.forEach((s) => {
    const lang = language === 'en' ? 'en' : s.language;
    const slug = languageSlugs[lang];
    if (!slug) {
      stateURLs[s.slug] = 'stateNa';
    } else {
      stateURLs[s.slug] = makeCropHref(
        match,
        search,
        slug,
        id,
        tab,
        lang,
        s.slug,
      );
    }
  });

  const initialData = {
    response: result.responseData,
    activeTab,
    tabData,
  };

  // Canonical:
  //  Articles: list can depend on state, So canonical is self.
  //  Products (seeds): depends on state. So canonical is self.
  //  Issues: depends on state because the list of products depends on state
  // Thus, canonical should be self for all.

  return {
    initialData,
    titleId,
    titleParams,
    seoData,
    languageURLs,
    stateURLs,
  };
};

export default injectIntl(CropPage);
