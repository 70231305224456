import React from 'react';
import { styled } from '@mui/material/styles';

import Heading from '../components/Heading';
import { Box } from '@mui/system';
import { Card, Container, Typography } from '@mui/material';
import { ProductPrice } from '../shop/ProductCard';
import StarsProductHeader from '../components/StarsProductHeader';
import InventoryIcon from '@mui/icons-material/Inventory';
import { makeProductHref } from '../utilities/links';
import slugify from '../utilities/slugify';
import { Link } from 'react-router-dom';

const ComboWrapper = styled('div')({
  padding: '8px',
  marginBottom: '12px',
  background: '#ffffff',
  lineHeight: '24px',
});

const Label = styled('div')({
  display: 'flex',
  alignItems: 'center',
  color: '#000',
  fontSize: '16px',
  fontWeight: '400',
  margin: '0',
});

const ComboDetail = styled('div')({
  padding: '8px 10px',
  lineHeight: '18px !important',
});

function RecommendedCombo({
  product: { comboProducts, comboProductsTitle },
  match,
  search,
  lang,
}) {
  if (!comboProducts || comboProducts.length === 0) {
    return null;
  }
  return (
    <ComboWrapper>
      <Heading>
        <Label>
          <InventoryIcon sx={{ mr: '5px' }} />
          {comboProductsTitle}
        </Label>
      </Heading>
      <Container
        sx={{
          width: '100%',
          display: 'grid',
          gridTemplateColumns: `repeat(${comboProducts.length}, 140px)`,
          gap: '10px',
          padding: '5px 5px !important',
          margin: '0',
          overflowX: 'scroll',
        }}
      >
        {comboProducts.map((comboProduct) => (
          <Link
            key={comboProduct.skuCode}
            style={{ display: 'contents' }}
            to={makeProductHref(
              match,
              search,
              slugify(comboProduct.productName),
              comboProduct.skuCode,
              lang,
            )}
          >
            <Card
              sx={{
                display: 'flex',
                flexDirection: 'column',
                minWidth: '140px',
                boxShadow: 'rgba(0, 0, 0, 0.1)',
                borderRadius: '6px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '4px',
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    color: 'white',
                    background: 'green',
                    padding: '0px 3px',
                    borderRadius: '5px',
                  }}
                >
                  -
                  {Math.round(
                    ((comboProduct.mrp - comboProduct.sellingPrice) /
                      comboProduct.mrp) *
                      100,
                  )}
                  %
                </Typography>
                <StarsProductHeader
                  stars={comboProduct?.rating?.average}
                  size="1rem"
                />
              </Box>
              <Typography
                variant="span"
                sx={{
                  width: '140px',
                  height: '140px',
                  img: { objectFit: 'contain', width: '100%', height: '100%' },
                }}
              >
                <img
                  src={comboProduct?.productImages[0]?.name}
                  alt={comboProduct.productName}
                />
              </Typography>
              <ComboDetail>
                <Typography variant="span">
                  {comboProduct.productName}
                </Typography>
                <Typography
                  variant="span"
                  sx={{ display: 'block', paddingTop: '10px' }}
                >
                  <ProductPrice
                    mrp={comboProduct.mrp}
                    sellingPrice={comboProduct.sellingPrice}
                  />
                </Typography>
              </ComboDetail>
            </Card>
          </Link>
        ))}
      </Container>
    </ComboWrapper>
  );
}

export default RecommendedCombo;
