import React, { useState } from 'react';

import { Grid, IconButton, TextField, Typography } from '@mui/material';
import { Add, Remove } from '@mui/icons-material';
import styled from '@emotion/styled';
import { addToCart, removeFromCart } from '../services/cartService';

const ProductMrp = styled('span')({
  color: '#666666',
  textDecoration: 'line-through',
  lineHeight: '19.07px',
  fontSize: '14px',
});
const ProductSellingPrice = styled('span')({
  fontWeight: 'bold',
  marginLeft: '3px',
});

const AddRemoveBagItem = ({
  state,
  product,
  isAnyCartChange,
  setIsAnyCartChange,
}) => {
  let initialQuantity = product?.quantity ? product.quantity : 1;
  const [quantity, setQuantity] = useState(Number(initialQuantity));

  const handleAdd = () => {
    let newQuantity = Number(quantity) + 1;
    setQuantity(newQuantity);
    setIsAnyCartChange(!isAnyCartChange);
    let productToAdd = {
      ...product,
      quantity: newQuantity,
    };
    addToCart(productToAdd, state);
  };

  const handleRemove = () => {
    if (quantity > 1) {
      let newQuantity = Number(quantity) - 1;
      setQuantity(newQuantity);
      setIsAnyCartChange(!isAnyCartChange);
      if (newQuantity > 0) {
        let productToAdd = {
          ...product,
          quantity: newQuantity,
        };
        addToCart(productToAdd, state);
      } else {
        removeFromCart(product.skuCode);
      }
    }
  };

  const handleBlur = (event) => {
    let quantityToSet = 1;

    if (event?.target) {
      let userInputQuantity = event.target.value;
      userInputQuantity = parseInt(userInputQuantity);
      if (userInputQuantity === 0) {
        setQuantity(1);
        quantityToSet = 1;
        setIsAnyCartChange(!isAnyCartChange);
      } else {
        if (!userInputQuantity || userInputQuantity === '') {
          setQuantity(quantityToSet);
          setIsAnyCartChange(!isAnyCartChange);
        } else {
          setQuantity(userInputQuantity);
          quantityToSet = userInputQuantity;
          setIsAnyCartChange(!isAnyCartChange);
        }
      }

      let productToAdd = {
        ...product,
        quantity: quantityToSet,
      };
      addToCart(productToAdd, state);
    } else {
      removeFromCart(product.skuCode);
    }
  };

  const handleChange = (event) => {
    let quantityToSet = 1;

    if (event?.target) {
      let userInputQuantity = event.target.value;
      userInputQuantity = parseInt(userInputQuantity);
      if (userInputQuantity === 0) {
        setQuantity(1);
        quantityToSet = 1;
        setIsAnyCartChange(!isAnyCartChange);
      } else {
        if (!userInputQuantity || userInputQuantity === '') {
          setQuantity(quantityToSet);
          setIsAnyCartChange(!isAnyCartChange);
        } else {
          setQuantity(userInputQuantity);
          quantityToSet = userInputQuantity;
          setIsAnyCartChange(!isAnyCartChange);
        }
      }

      let productToAdd = {
        ...product,
        quantity: quantityToSet,
      };
      addToCart(productToAdd, state);
    } else {
      removeFromCart(product.skuCode);
    }
  };

  const handleChangeInput = (event) => {
    let userInputQuantity = event.target.value;
    userInputQuantity = parseInt(userInputQuantity);

    if (quantity === userInputQuantity) {
    } else {
      setQuantity(event.target.value);
      handleChange(event);
    }
  };
  return (
    <div>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={0}
      >
        <Grid item xs={5}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={3}>
              <IconButton
                size={'small'}
                sx={{ border: '1px solid #999999' }}
                onClick={handleRemove}
              >
                <Remove
                  sx={{ width: '10px', height: '10px', color: '#333333' }}
                />
              </IconButton>
            </Grid>
            <Grid item xs={6}>
              <TextField
                name={`quantity`}
                required={true}
                type={`number`}
                size={`small`}
                variant={'outlined'}
                value={quantity}
                onChange={handleChangeInput}
                onBlur={handleBlur}
                fullWidth={true}
                inputProps={{
                  style: {
                    width: '10rem',
                    height: '10px',
                    padding: '8px 4px',
                    textAlign: 'center',
                  },
                }}
                sx={{ padding: 0 }}
                min={1}
              />
            </Grid>
            <Grid item xs={3}>
              <IconButton
                size={'small'}
                onClick={handleAdd}
                sx={{ border: '1px solid #999999' }}
              >
                <Add sx={{ width: '10px', height: '10px', color: '#333333' }} />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={5}>
          <Typography
            variant={'subtitle1'}
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              alignItems: 'center',
            }}
          >
            <ProductSellingPrice>
              ₹
              {product.sellingPrice && quantity
                ? quantity * product.sellingPrice
                : product.sellingPrice}
            </ProductSellingPrice>
            <ProductMrp>
              ₹
              {product.mrp > 0 && quantity > 0
                ? quantity * product.mrp
                : product.mrp}
            </ProductMrp>
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

export default AddRemoveBagItem;
