/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import Button from '@mui/material/Button';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { getStateBySlug, allStates } from '../utilities/states';
import {
  getRouteParams,
  changeState,
  getDefaultPageForState,
  makeArticleNotAvailableHref,
  makeShopNotAvailableHref,
} from '../utilities/links';

/*
 * How we want the state-switcher to work:
 * - In principle, when the state changes, we need to change the
 *   language also (unless the current language is English). This is to avoid
 *   incompatible language-state combinations like Kannada-Maharashtra.
 *   But if the current language is applicable for the new state, we shouldn't
 *   change it.
 * - Shop applicability: shop related pages may not be applicable to the new
 *   state. For these, we point to a shop NA page, also in the new state's
 *   default language (unless the current language is English).
 * - Language applicability: Article may not be available in the state's
 *   language. We need to point to Article not available page. But if the user
 *   is currently using English, we can let them switch the state without
 *   changing the language.
 * - Error Pages: These should point to the default home page for that state.
 *   There is really no point in seeing the error page in a different state.
 *
 * Thus we support 3 kinds of values for the stateURLs parameter.
 * 1. null - the page is applicable to all states (eg, for articles)
 * 2. 'home' - all links point to that state's default page (eg, error pages)
 * 3. 'shop' - the page is applicable to shop states (eg brand list, crop list)
 * 4. Set of URLs - custom option, typically because it is not a simple
 *    changeState call, there may be a slug which also needs translation.
 *    Here, we don't have the translatable slug information.
 */

function StateSelect({ match, search, stateURLs }) {
  const { state, language } = getRouteParams(match, search);
  const selectedState = getStateBySlug(state);

  let stateLinks;
  if (!stateURLs) {
    // It is OK to switch state to any, so let us allow to switch all.
    stateLinks = allStates.map((stateObj) => {
      const url = changeState(match, search, stateObj.slug);
      return { stateObj, url };
    });
  } else if (stateURLs === 'home') {
    stateLinks = allStates.map((stateObj) => {
      // For error pages, it's better to lead them to home page.
      const url = getDefaultPageForState(stateObj.slug);
      return { stateObj, url };
    });
  } else if (stateURLs === 'shop') {
    stateLinks = allStates.map((stateObj) => {
      const url = stateObj.shop
        ? changeState(match, search, stateObj.slug)
        : makeShopNotAvailableHref(match, search, stateObj.slug);
      return { stateObj, url };
    });
  } else {
    // Process each individually. We have a URL, or "stateNa" or "languageNa"
    stateLinks = allStates.map((stateObj) => {
      const url = stateURLs[stateObj.slug];
      if (url === 'stateNa') {
        const naUrl = makeShopNotAvailableHref(match, search, stateObj.slug);
        return { stateObj, url: naUrl };
      }
      if (url === 'languageNa') {
        const stateLang = language === 'en' ? 'en' : stateObj.language;
        const naUrl = makeArticleNotAvailableHref(
          match,
          search,
          stateObj.slug,
          stateLang,
        );
        return { stateObj, url: naUrl };
      }
      return { stateObj, url };
    });
  }

  const [menuAnchor, setMenuAnchor] = React.useState(null);
  const handleButtonClick = (event) => {
    setMenuAnchor(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  return (
    <>
      <Button
        color="inherit"
        onClick={handleButtonClick}
        sx={{ paddingRight: '0' }}
      >
        <LocationOnIcon />
        {selectedState ? `${selectedState.name}` : state}
      </Button>
      <Menu
        id="menu-state"
        anchorEl={menuAnchor}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(menuAnchor)}
        onClose={handleMenuClose}
      >
        {stateLinks.map((s) => (
          <MenuItem key={s.stateObj.slug}>
            <Link to={s.url} onClick={handleMenuClose}>
              {s.stateObj.name}
              {s.stateObj.localised_name && ` (${s.stateObj.localised_name})`}
            </Link>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}

export default StateSelect;
