/* eslint-disable no-param-reassign */
/* eslint-disable no-loop-func */
import LRUCache from 'lru-cache';

import apiCall from './apiCall';
import {
  getRouteParams,
  makeIssueHref,
  makeBrandHref,
  makeProductHref,
  makeCropHref,
  makeArticleHref,
  makeProductListHref,
  makeBannerProductListHref,
  makePostHref,
} from './links';
import {
  getIssueDetails,
  getBrandInfo,
  getCropInfo,
  getArticle,
} from '../services';
import slugify from './slugify';
import { getEnv } from './getEnv';

async function addIssueHref(b, data, i, language, match, search) {
  const issueResponse = await getIssueDetails(data.data.issueId, language);
  const name =
    issueResponse && issueResponse.responseData
      ? issueResponse.responseData.issueDetails.issueName
      : 'AgroStar Issue';
  data.href = makeIssueHref(match, search, slugify(name), data.issueId);
}

/*
 * The product details API is slow mainly because of related products.
 * Here we need only the name, so we keep a separate cache of product names
 * in different languages.
 */
const productNamesCache = new LRUCache({
  max: 500,
  maxAge: 24 * 60 * 60 * 1000,
});

export async function getProductName(id, lang) {
  const cacheKey = id;
  const cacheSize = productNamesCache.length;
  const names = productNamesCache.get(cacheKey);
  if (names) {
    console.log(`Product names cache hit: ${cacheKey} size: ${cacheSize}`);
    return names[lang];
  }
  console.log(`Product names cache miss: ${cacheKey} size: ${cacheSize}`);
  const api = `${getEnv(
    'FWA_API_URL',
  )}/catalogservice/v2/product/${id}?include=none`;
  const url = new URL(api);
  url.searchParams.append('language', lang);

  const result = await apiCall(url.href, { Source: 'APP' });
  // Cache it only if the fetch succeeds.
  if (result.responseData && result.responseData.productLanguageNames) {
    productNamesCache.set(cacheKey, result.responseData.productLanguageNames);
    return result.responseData.productLanguageNames[lang];
  }
  return '';
}

async function addProductHref(b, data, i, language, match, search) {
  const name = await getProductName(data.data.skuCode, language);
  data.href = makeProductHref(match, search, slugify(name), data.data.skuCode);
}

async function addBrandHref(b, data, i, l, match, search) {
  const { language } = getRouteParams(match, search);
  const brandInfo = await getBrandInfo(data.data.brandId, language);
  if (!brandInfo || !brandInfo.brandId) {
    console.error('addBrandHref: empty brand API response');
    return;
  }
  data.href = makeBrandHref(
    match,
    search,
    slugify(brandInfo.brandName),
    data.data.brandId,
  );
}

async function addCropHref(b, data, i, language, match, search) {
  const id = data.data.cropId;
  const response = await getCropInfo(id, language);
  if (!(response && response.responseData)) {
    console.error('addCropHref: Empty response for crop: ', id);
    return;
  }
  const name = response.responseData.category;
  data.href = makeCropHref(match, search, slugify(name), id);
}

async function addArticleHref(b, data, i, language, match, search) {
  const id = data.data.articleId;
  const article = await getArticle(id, language);
  const heading =
    article && article.responseData && article.responseData.article
      ? article.responseData.article.heading
      : 'Agrostar information article';
  data.href = makeArticleHref(match, search, slugify(heading), id);
}

async function addProductListHref(banner, data, index, l, match, search) {
  const ids = data.data.params.sku;
  if (ids.length < 1000) {
    data.href = makeProductListHref(match, search, ids);
  } else {
    // We need the banner code and also the index of the data element to get
    // the correct list of products.
    const listingId = `${banner.code}.${index}`;
    data.href = makeBannerProductListHref(match, search, listingId);
  }
}

async function addWebPageHref(b, data) {
  // Sometimes they put deeplinks in the banner. These will cause AMP validation failure.
  if (data.data.url && data.data.url.indexOf('ulink://') !== 0) {
    data.href = data.data.url;
  }
}

async function addPostHref(b, data, i, l, match, search) {
  const id = data.data.postId;
  data.href = makePostHref(match, search, id);
}

const hrefAdderMap = {
  ISSUE_DETAILS: addIssueHref,
  PRODUCT_DETAILS: addProductHref,
  BRAND_DETAILS: addBrandHref,
  CROP_DETAILS: addCropHref,
  ARTICLE_DETAILS: addArticleHref,

  PRODUCT_LIST: addProductListHref,
  INTERNAL_WEB_PAGE: addWebPageHref,
  EXTERNAL_WEB_PAGE: addWebPageHref,
  POST_DETAILS: addPostHref,

  CATEGORY_DETAILS: null, // todo
  SELECT_YOUR_CROPS: null,
  NO_CLICK: null,
  NO_DATA: null,
};

function addHrefsToBanner(banner, match, search) {
  const { language } = getRouteParams(match, search);
  const promises = banner.data.map((data, index) => {
    const hrefAdder = hrefAdderMap[data.type];
    if (hrefAdder) {
      return hrefAdder(banner, data, index, language, match, search);
    }
    return 0;
  });
  return Promise.all(promises);
}

function addHrefsToBanners(banners, match, search) {
  if (!banners) return null;
  const promises = banners.map((banner) =>
    addHrefsToBanner(banner, match, search),
  );
  return Promise.all(promises);
}

export { addHrefsToBanners };
