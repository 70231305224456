import React from 'react';
import { styled } from '@mui/material/styles';
import Carousel from '../components/Carousel';
import ReactPlayer from 'react-player';

const CarouselWrapper = styled('div')({
  background: '#fff',
  marginBottom: '12px',
  '& .carousel': {
    height: 351,
  },
  '& .fixed-height-container': {
    position: 'relative',
    width: '100%',
    height: 320,
    padding: '5% 0px',
    img: {
      objectFit: 'contain',
      height: '100%',
      width: '100%',
    },
  },
});

function ProductCarousel({ media }) {
  if (!(media && media.length > 0)) {
    return null;
  }

  return (
    <CarouselWrapper>
      <Carousel autoPlay={false}>
        {media.map((m) => (
          <div key={m.url} className="fixed-height-container">
            {m.type === 'IMAGE' ? (
              <img src={m.url} alt="" />
            ) : (
              <ReactPlayer width="100%" height="100%" url={m.url} />
            )}
          </div>
        ))}
      </Carousel>
    </CarouselWrapper>
  );
}

export default ProductCarousel;
