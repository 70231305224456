import apiCall from '../utilities/apiCall';
import { getEnv } from '../utilities/getEnv';
import { getAppSource } from '../utilities/states';

export function getSaathiStore(retailerId, state) {
  const url = `${getEnv(
    'FWA_API_URL',
  )}/institutionalsalesservice/getnearbystore/?advancedSearch=true&retailerId=${retailerId}`;
  return apiCall(url, { Source: getAppSource(state) });
}

export function getFarmerWallet(farmerAuthToken, farmerId, language, state) {
  const url = `${getEnv(
    'ONLINE_ECOMMERCE_SERVICE',
  )}/onlineecommerceservice/v1/walletbalance/?farmerId=${farmerId}&language=${language}`;
  return apiCall(url, {
    Source: getAppSource(state),
    'X-Authorization-Token': farmerAuthToken,
  });
}
