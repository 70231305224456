import React from 'react';
import { Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { makeListingHref, makeShopHref } from '../utilities/links';

function NavigationDiscovery({ match, search }) {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-around"
      alignItems="center"
      sx={{
        position: 'sticky',
        top: '48px',
        padding: '5px 15px 2px',
        backgroundColor: '#ffffff',
        borderBottom: '2px solid #ddd',
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <NavigationItem
        link={makeListingHref(match, search, 'crops')}
        imgUrl="/allCrops.svg"
        labelId="label_all_crops"
      />
      <NavigationItem
        link={makeListingHref(match, search, 'articles')}
        imgUrl="/krishiGyan.svg"
        labelId="label_agri_updates"
      />
      <NavigationItem
        link={makeListingHref(match, search, 'posts', null, 'popular')}
        imgUrl="/krishiCharcha.svg"
        labelId="all_discussions"
      />
      <NavigationItem
        link={makeShopHref(match, search)}
        imgUrl="/agriDukaan.svg"
        labelId="shop_home"
      />
    </Grid>
  );
}

function NavigationItem({ link, imgUrl, labelId }) {
  return (
    <Link to={link}>
      <Grid
        item
        key={labelId}
        sx={{
          textAlign: 'center',
          img: { width: '35px', height: '35px' },
        }}
      >
        <img src={imgUrl} alt="" />
        <Typography variant="subtitle2" sx={{ fontSize: '11px' }}>
          <FormattedMessage id={labelId} />
        </Typography>
      </Grid>
    </Link>
  );
}
export default NavigationDiscovery;
