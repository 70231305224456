import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import apiCall from '../utilities/apiCall';
import store from '../utilities/store';
import ProductCard, { ProductListWrapper } from './ProductCard';
import LayoutWrapper from '../components/Layout';
import { Title } from '../components/Heading';
import { getBrandInfo } from '../services';
import {
  getRouteParams,
  makeBrandHref,
  makeShopNotAvailableHref,
} from '../utilities/links';
import slugify from '../utilities/slugify';
import { shopLanguages } from '../utilities/languages';
import {
  getAppSource,
  getStateBySlug,
  allStates,
  isAgriShopEnabled,
} from '../utilities/states';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { getEnv } from '../utilities/getEnv';

const BrandPage = ({ setHeaderURLs, intl }) => {
  const { data, loadingStatus } = useFetchData(
    BrandPage,
    store,
    setHeaderURLs,
    intl,
  );
  const brandName = data?.brandName;
  const productGists = data?.response?.productGists;

  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error' || !(productGists && productGists.length > 0)) {
    return <ServerError textId="no_products_found_title" />;
  }

  return (
    <LayoutWrapper>
      <Title>
        <FormattedMessage id="label_productdetails_brand" />
        {` ${brandName}`}
      </Title>
      <ProductListWrapper>
        {productGists.map((p) => (
          <ProductCard key={p.skuCode} product={p} />
        ))}
      </ProductListWrapper>
    </LayoutWrapper>
  );
};

async function getBrandProducts(id, userState, lang) {
  const api = `${getEnv(
    'FWA_API_URL',
  )}/catalogservice/v2/products/?brand=${id}`;

  const url = new URL(api);
  url.searchParams.append('language', lang);
  url.searchParams.append('source', getAppSource(userState));

  return apiCall(url.href, { Source: 'APP' });
}

BrandPage.fetchData = async (match, search) => {
  const { id, language, state } = getRouteParams(match, search);
  if (!isAgriShopEnabled(state)) {
    return { redirectURL: makeShopNotAvailableHref(match, search, state) };
  }
  const titleId = 'title_shop';
  const seoData = {};
  let result = {};
  let brandName;

  const brandInfo = await getBrandInfo(id, language);
  if (brandInfo) {
    ({ brandName = 'AgroStar Brand' } = brandInfo);
  }
  const titleParams = { heading: brandName || '' };

  result = await getBrandProducts(id, state, language);

  if (
    !(
      result.responseData &&
      result.responseData.productGists &&
      result.responseData.productGists
    )
  ) {
    return {
      initialData: { response: {}, brandName },
      titleId: 'no_products_found_title',
      languageURLs: 'home',
      stateURLs: 'home',
    };
  }

  const initialData = { response: result.responseData || [], brandName };

  const languageSlugs = {};
  const jobs = shopLanguages.map((lang) =>
    (async (l) => {
      const resp = await getBrandInfo(id, l);
      if (resp) {
        languageSlugs[l] = slugify(resp.brandName);
      }
    })(lang),
  );
  await Promise.all(jobs);

  const languageURLs = {};
  getStateBySlug(state).supportedLanguages.forEach((lang) => {
    const slug = languageSlugs[lang];
    languageURLs[lang] = slug
      ? makeBrandHref(match, search, slug, id, lang)
      : 'languageNa';
  });

  const stateURLs = {};
  allStates.forEach((s) => {
    const lang = language === 'en' ? 'en' : s.language;
    const slug = languageSlugs[lang];
    stateURLs[s.slug] = slug
      ? makeBrandHref(match, search, slug, id, lang, s.slug)
      : 'stateNa';
  });

  // Canonical: State may have an effect based on availability (sorting can change)
  // so we leave the canonical as self.

  return {
    initialData,
    titleId,
    titleParams,
    seoData,
    canonical: 'self',
    languageURLs,
    stateURLs,
  };
};

export default injectIntl(BrandPage);
