/* eslint-disable react/no-danger */
import React from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import { styled } from '@mui/material/styles';
import { Container } from '@mui/material';
import ProductHighlight from './ProductHighlight';
import OtherDetails from './OtherDetails';
import store from '../utilities/store';
import getProductStructuredData from '../utilities/googleStructuredData';

import ProductCard from '../shop/ProductCard';
import Heading from '../components/Heading';
import ProductCarousel from './ProductCarousel';
import ProductDetails from './ProductDetails';
import AdditionalDetails from './AdditionalDetails';
import slugify from '../utilities/slugify';
import {
  getStateBySlug,
  allStates,
  isAgriShopEnabled,
} from '../utilities/states';
import { shopLanguages } from '../utilities/languages';
import {
  getRouteParams,
  makeProductHref,
  makeBuyNowHref,
  makeShopNotAvailableHref,
  getCurrentUrl,
} from '../utilities/links';
import ReviewRating from '../components/ReviewRating';
import {
  getProductDetailsWithRatings,
  getProductDetails,
  getProductsListBySKUs,
} from '../services/index';
import PageNotFound from '../misc/NotFoundPage';
import ProductHeader from './ProductHeader';
import ProductIssue from './ProductIssue';
import { useFetchData } from '../services/useFetchData';
import { Loader } from '../components/Loader';
import ServerError from '../components/ServerError';
import { Link } from 'react-router-dom';
import AddToCart from './AddToCart';
import { Box } from '@mui/system';
import BenefitUseTestimonial from './BenefitUseTestimonial';
import RecommendedCombo from './RecommendedCombo';
import ProductFooter from './ProductFooter';
import AgrostarValues from './AgrostarValues';
import ProductVariants from './ProductVariants';
import Search from '../components/Search';

const DetailsWrapper = styled('div')({
  'a.link': {
    width: '100%',
    display: 'inherit',
  },
  'button.buy-now, button.product-not-enabled': {
    borderRadius: '0',
    fontSize: '14px',
    height: '100%',
    border: '0',
  },
  'button.buy-now': {
    background: '#00733e',
    color: '#fff',
  },
  'button.product-not-enabled': {
    width: '200%',
    background: '#cccccc',
    color: 'black',
  },
  '& .buy-now-lightbox': {
    background: '#e5e5e5',
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    minHeight: '615px',
  },
  '#my-bindable-lightboxbuynow': {
    height: '100%',
    '> div': {
      height: '100%',
    },
  },
  '.go-to-agri-shop-btn': {
    backgroundColor: '#902123',
    borderRadius: '5px',
    padding: '10px 20px',
    color: 'white',
    border: 'none',
  },
});

const ProductLayoutWrapper = styled('div')({
  margin: '10px 10px 0 10px',
});

const AgrostarPromise = styled(Box)({
  width: '100%',
  img: { width: '100%' },
});

function ProductPage({ setHeaderURLs, intl }) {
  const { data, loadingStatus, match, search } = useFetchData(
    ProductPage,
    store,
    setHeaderURLs,
    intl,
  );
  const { state, lang, slug } = getRouteParams(match, search);
  const initialData = data;

  if (loadingStatus === 'loading') return <Loader />;
  if (loadingStatus === 'error') return <ServerError />;
  if (!initialData || !initialData.response) {
    return <PageNotFound />;
  }

  const { response } = initialData;
  const {
    rating: { individualCount, totalCount, showRatingBars, average: avgRating },
    promise: { imageUrl },
  } = response;

  const { details, relatedProducts, skuCode, media } = response;

  const stateLabel = intl.formatMessage({ id: `state_${state}` });
  let buyNow, addToCart;
  if (response.isEnabledForRequestedSource) {
    buyNow = (
      <Link to={makeBuyNowHref(match, search, slug, skuCode)} className="link">
        <button type="button" className="buy-now">
          <FormattedMessage id="label_need_expert_help" />
        </button>
      </Link>
    );
    addToCart = (
      <AddToCart
        product={response}
        skuCode={skuCode}
        quantity={1}
        slug={state}
        match={match}
        search={search}
        lang={lang}
      />
    );
  } else {
    buyNow = (
      <button type="button" className="product-not-enabled">
        <FormattedMessage
          id="label_product_not_enabled"
          values={{ state: stateLabel }}
        />
      </button>
    );
  }

  return (
    <DetailsWrapper>
      <Search match={match} search={search} />
      <ProductHeader product={response} />
      <ProductCarousel media={media} />
      <ProductLayoutWrapper>
        <ProductDetails product={response} />
        <ProductVariants product={response} match={match} search={search} />
        <AgrostarValues lang={lang} />
        <BenefitUseTestimonial product={response} />
        <ReviewRating
          avgRating={avgRating}
          individualCount={individualCount}
          totalCount={totalCount}
          showRatingBars={showRatingBars}
        />
        <RecommendedCombo
          product={response}
          match={match}
          search={search}
          lang={lang}
        />
        <ProductIssue product={response} match={match} search={search} />
        <OtherDetails details={details} />
        <ProductHighlight details={details} />
        <AdditionalDetails details={details} />
        {relatedProducts && relatedProducts.length > 0 && (
          <>
            <Heading>
              <FormattedMessage id="label_related_products" />
            </Heading>
            <Container
              sx={{
                width: '100%',
                display: 'grid',
                gridTemplateColumns: `repeat(${relatedProducts.length}, 150px)`,
                gap: '10px',
                padding: '5px 2px !important',
                margin: '0',
                overflowX: 'scroll',
              }}
            >
              {relatedProducts.map((p) => (
                <ProductCard key={p.skuCode} product={p} />
              ))}
            </Container>
          </>
        )}
      </ProductLayoutWrapper>
      <AgrostarPromise>
        <img src={imageUrl} alt="agrostar_promise" />
      </AgrostarPromise>
      <Box
        sx={{
          maxWidth: '600px',
          width: '100%',
          height: '48px',
          margin: '0',
          padding: '0px',
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          position: 'fixed',
          bottom: 0,
          zIndex: 1,
        }}
      >
        {addToCart}
        {buyNow}
      </Box>
      <ProductFooter match={match} search={search} />
    </DetailsWrapper>
  );
}

// Note: This is used by BuyNowPage also. linkMaker is passed as makeProductHref
// for ProductPage and makeBuyNowHref for BuyNowPage.
export async function productDataFetcher(match, search, linkMaker) {
  const { id, language, state } = getRouteParams(match, search);
  if (!isAgriShopEnabled(state)) {
    return { redirectURL: makeShopNotAvailableHref(match, search, state) };
  }

  const productNotFound = {
    titleId: 'label_no_product_available',
    languageURLs: 'home',
    stateURLs: 'home',
  };

  if (id === '%5Bobject+Object%5D' || id === '[object Object]') {
    // Some old Google cached incorrect pages (or maybe we still have this bug.)
    console.log('Quickly returning not found');
    return productNotFound;
  }

  const result = await getProductDetailsWithRatings(id, language, state);
  if (!result.responseData || !result.responseData.productName) {
    return productNotFound;
  }

  const { responseData } = result;
  const { productName, productImages, sellingPrice, variants } = responseData;
  // Disabled products will not be shown
  if (productName.toLowerCase().includes('disabled')) {
    return productNotFound;
  }

  const skuArray = variants.map((variant) => variant?.sku_code);
  const skus = skuArray.join(',');

  const variantsResponse = await getProductsListBySKUs(skus, language, state);
  const skuProducts = variantsResponse?.responseData?.productGists?.map(
    (skuProduct, index) => ({
      ...variants[index],
      link: linkMaker(
        match,
        search,
        slugify(skuProduct?.productName),
        skuProduct?.skuCode,
      ),
    }),
  );

  const productUrl = getCurrentUrl(match, search);
  const initialData = {
    response: { ...responseData, variants: skuProducts },
  };

  const brandName = responseData.brand || '';
  const titleId = 'title_shop';
  const titleParams = { heading: `${brandName} ${productName}` };
  const seoData = {};
  seoData.description = `${brandName} ${productName}`;
  seoData.googleStructuredData = getProductStructuredData(
    responseData,
    productUrl,
  );

  const image = productImages.find((p) => p.type === 'primary') || {};
  seoData.ogTagData = {
    description: productName,
    imageUrl: image.name,
    type: 'product',
    price: sellingPrice,
  };

  // Get all shop languages - we'll need it for the state switcher anyway
  const languageSlugs = {};
  if (responseData.productLanguageNames) {
    // New API returns productLanguageNames
    shopLanguages.forEach((l) => {
      languageSlugs[l] = slugify(responseData.productLanguageNames[l]);
    });
  } else {
    // This is old code, but here just in case the API is working incorrectly
    const jobs = shopLanguages.map((lang) =>
      (async (l) => {
        const details = await getProductDetails(id, l);
        if (details && details.responseData) {
          languageSlugs[l] = slugify(details.responseData.productName);
        }
      })(lang),
    );
    await Promise.all(jobs);
  }

  const languageURLs = {};
  getStateBySlug(state).supportedLanguages.forEach((l) => {
    const sl = languageSlugs[l];
    // Change only the language if it is supported
    languageURLs[l] = sl ? linkMaker(match, search, sl, id, l) : 'languageNa';
  });

  const stateURLs = {};
  allStates.forEach((s) => {
    if (s.shop) {
      // Change state, try to use current language if english.
      const lang = language === 'en' ? 'en' : s.language;
      const sl = languageSlugs[lang];
      stateURLs[s.slug] = linkMaker(match, search, sl, id, lang, s.slug);
    } else {
      stateURLs[s.slug] = 'stateNa';
    }
  });
  return {
    initialData,
    titleId,
    titleParams,
    seoData,
    languageURLs,
    stateURLs,
    canonical: 'self', // price can be different among states, and also availability.
  };
}

ProductPage.fetchData = async (match, search) =>
  productDataFetcher(match, search, makeProductHref);

export default injectIntl(ProductPage);
