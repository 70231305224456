import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import Heading from '../components/Heading';
import { makeCropHref } from '../utilities/links';
import slugify from '../utilities/slugify';

const CropCardWrapper = styled(Link)({
  position: 'relative',
  width: '33.3%',
  marginBottom: '1%',
  border: '1px solid #cccccc',
  borderRadius: '4px',
  background: '#ffffff',
});

const CropImageContainer = styled('div')({
  width: '100%',
  paddingTop: '76%', // fixed aspect-ratio trick
  position: 'relative',
  overflow: 'hidden',
  img: {
    objectFit: 'cover',
    width: '90%',
    height: '90%',
    position: 'absolute',
    top: '5.5%',
    left: '5.5%',
  },
});

export const CropListWrapper = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  marginRight: '-1%',
});

export const Title = styled(Heading)({
  textAlign: 'center',
});

function CropCard({
  match,
  location: { search },
  crop: { image, id, display },
}) {
  const href = makeCropHref(match, search, slugify(display), id, 'articles');
  return (
    <CropCardWrapper to={href}>
      <CropImageContainer>
        <img src={image} alt={display} />
      </CropImageContainer>
      <Title>{display}</Title>
    </CropCardWrapper>
  );
}

export default withRouter(CropCard);
