import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Card, CardMedia, Grid, IconButton, Typography } from '@mui/material';

import { getPaginatedStoreInventory } from '../services';
import { getRouteParams } from '../utilities/links';
import { LocalMall } from '@mui/icons-material';
import StarsProductHeader from '../components/StarsProductHeader';
import { FormattedMessage } from 'react-intl';
import { ProductPrice } from '../shop/ProductCard';
import InfiniteScroll from 'react-infinite-scroll-component';

const styles = {
  sectionHeading: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'start',
    gap: '16px',
    padding: '0 15px',
    background: '#fff',
    borderRadius: '30px 30px 0 0',
    marginTop: '30px',
  },
  iconButton: {
    width: '42px',
    height: '42px',
    background: 'rgb(157, 33, 35, 0.2)',
    borderRadius: '50%',
    cursor: 'auto',
    '&:hover': { background: 'rgb(157, 33, 35, 0.2)' },
  },
  productCardWrapper: {
    minHeight: '205px',
    position: 'relative',
    marginTop: '20px',
    borderRadius: '4px',
    border: '1px solid #CCCCCC',
    paddingLeft: '8px',
    paddingRight: '8px',
  },
  productName: {
    height: '36px',
    maxHeight: '36px',
    fontSize: '14px',
    lineHeight: '1.3em',
    letterSpacing: '0px',
    wordWrap: 'break-word',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3,
    lineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  agroStarPointsUsableWrapper: {
    width: 'inherit',
    padding: '4px 12px',
    margin: '6px 0px',
    borderRadius: '100px',
    background: 'rgba(0, 115, 62, 0.10)',
  },
};

function StoreInventory({ inventoryResponse, retailerId, match, search }) {
  const [partnerInventory, setPartnerInventory] = useState(
    inventoryResponse.productGists,
  );
  const [inventoryFetchedCount, setInventoryFetchedCount] = useState(
    inventoryResponse.productGists.length,
  );
  const totalCount = inventoryResponse?.totalProductsCount;

  const fetchStoreInventory = async () => {
    console.log(inventoryFetchedCount + ',' + totalCount);
    if (inventoryFetchedCount < totalCount) {
      const response = await getPaginatedStoreInventory({
        match,
        search,
        retailerId,
        offset: inventoryFetchedCount,
      });
      const productGists = response?.responseData?.productGists;
      if (productGists && productGists.length) {
        setInventoryFetchedCount(
          (prevCount) => prevCount + productGists?.length,
        );
        setPartnerInventory((prevStoreInventoryList) => [
          ...prevStoreInventoryList,
          ...productGists,
        ]);
      }
    }
  };

  return partnerInventory?.length ? (
    <>
      <div style={styles.sectionHeading}>
        <IconButton style={styles.iconButton}>
          <LocalMall sx={{ color: '#9D2123' }} />
        </IconButton>

        <div>
          <Typography variant="subtitle2" fontWeight={700}>
            <FormattedMessage id="store_front_product_heading" />
          </Typography>
          <Typography variant="body" fontSize={12}>
            <FormattedMessage id="store_front_product_sub_heading" />
          </Typography>
        </div>
      </div>

      <InfiniteScroll
        dataLength={inventoryFetchedCount}
        next={fetchStoreInventory}
        hasMore={inventoryFetchedCount < totalCount}
        loader={
          <Typography
            variant={`h4`}
            sx={{ textAlign: 'center', fontSize: '16px' }}
          >
            Loading...
          </Typography>
        }
      >
        <Grid
          container
          justifyContent="space-between"
          sx={{ width: 'auto !important', m: '0 15px', pb: '20px' }}
        >
          {partnerInventory?.map((product, index) => {
            if (!product?.productName) return null;
            return (
              <Grid item xs={5.5} key={index}>
                <ProductCard product={product} />
              </Grid>
            );
          })}
        </Grid>
      </InfiniteScroll>
    </>
  ) : (
    ''
  );
}

function ProductCard({ product }) {
  return (
    <Card style={styles.productCardWrapper} variant={'outlined'}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          sx={{
            width: '100%',
            minHeight: '26px',
            display: 'flex',
            justifyContent: 'end',
            padding: '5px 8px',
          }}
        >
          {product?.rating?.totalCount ? (
            <StarsProductHeader stars={product?.rating?.average} size="1rem" />
          ) : (
            ''
          )}
        </Grid>
        <Grid item xs={12}>
          <CardMedia
            component="img"
            image={
              product?.productImages.length
                ? product?.productImages?.[0]?.name
                : '/icons/productImageDummy.jpeg'
            }
            alt={product.productName}
            sx={{ maxHeight: '90px' }}
          />
        </Grid>

        <Grid item xs={12} sx={{ width: '100%', padding: '6px 0' }}>
          <Typography style={styles.productName}>
            {product.productName.length > 40
              ? product.productName.slice(0, 40).concat(`...`)
              : product.productName}
          </Typography>
        </Grid>
        {product?.orderStats?.totalOrdersString ? (
          <Grid
            item
            xs={12}
            sx={{
              width: '100%',
              position: 'absolute',
              bottom: '0',
              marginTop: '2px',
              padding: '5px 0',
              background: 'rgba(0, 115, 62, 0.2)',
            }}
          >
            <Typography
              fontSize={10}
              fontWeight={700}
              sx={{ color: '#00733E' }}
            >
              {product?.orderStats?.totalOrdersString}
            </Typography>
          </Grid>
        ) : (
          ''
        )}
        <Grid item xs={12} sx={{ width: '100%', padding: '6px 0' }}>
          {product.redeemablePointsAtStore &&
          product.redeemablePointsAtStore > 0 ? (
            <ProductPrice
              sellingPrice={product.priceAtStore}
              mrp={product.sellingPrice}
            />
          ) : (
            <ProductPrice
              sellingPrice={product.sellingPrice}
              mrp={product.mrp}
            />
          )}
        </Grid>

        {product.redeemablePointsAtStore > 0 && (
          <div style={styles.agroStarPointsUsableWrapper}>
            <Typography
              variant="subtitle2"
              fontSize={14}
              color="#00733E"
              fontWeight={700}
            >
              <img
                src="/ic_agrostar_points.svg"
                style={{ verticalAlign: 'top' }}
              />{' '}
              {product.redeemablePointsAtStore}{' '}
              <FormattedMessage id="label_by_using" />
            </Typography>
          </div>
        )}
      </Grid>
    </Card>
  );
}

export default StoreInventory;
