import React from 'react';
import { FormattedMessage } from 'react-intl';

import { Grid } from '@mui/material';
import { East } from '@mui/icons-material';

import ProductCard from '../shop/ProductCard';
import AndroidService from '../services/AndroidService';
import { SubHeadingWrapper, ViewAllButton } from './ExpertVideos';
import {
  makeProductListHref,
  makeAndroidProductListHref,
} from '../utilities/links';
import ClevertapService from '../services/ClevertapService';
import { CropInfoSkeleton } from './CropsTabs';
import useQuery from '../utilities/useQuery';
import { Link } from 'react-router-dom';

export default function CropProducts({ match, search, loading, cropSchedule }) {
  const query = useQuery();
  const appLinks = query.get('appLinks');
  const source = query.get('source');

  if (loading === 'skeleton') return <CropInfoSkeleton />;
  if (!cropSchedule?.products?.length) return null;
  return (
    <>
      <SubHeadingWrapper>
        <FormattedMessage id="label_task_products" />
      </SubHeadingWrapper>

      <Grid
        container
        flexWrap="nowrap"
        gap="10px"
        sx={{
          mt: 1,
          p: '1px 16px 2px',
          overflowX: 'scroll',
          overflowY: 'hidden',
        }}
      >
        {cropSchedule?.products?.map((section) =>
          section?.map((product, index) => (
            <div
              key={index}
              onClick={() =>
                ClevertapService.pushEvent(`Card Clicked`, {
                  Type: 'Product',
                  'Crop Name': cropSchedule?.category,
                  SkuCode: product?.skuCode,
                  'Product Name': product?.productName,
                })
              }
            >
              <ProductCard product={product} />
            </div>
          )),
        )}
      </Grid>

      {appLinks === 'saathi' ? (
        <Link
          to={
            makeProductListHref(match, search, cropSchedule?.skus) +
            `&showHeader=false&appLinks=saathi&source=${source}&crop=${cropSchedule?.cropName}`
          }
          onClick={() =>
            ClevertapService.pushEvent(`Button Clicked`, {
              Type: 'View All Products',
              'Crop Name': cropSchedule?.category,
            })
          }
        >
          <ViewAllButton variant="text" endIcon={<East />}>
            <FormattedMessage id="label_view_all_products" />
          </ViewAllButton>
        </Link>
      ) : (
        <ViewAllButton
          variant="text"
          endIcon={<East />}
          href={
            AndroidService.checkIfAndroid()
              ? makeAndroidProductListHref(
                  cropSchedule?.skus,
                  cropSchedule?.cropName,
                )
              : makeProductListHref(match, search, cropSchedule?.skus)
          }
          onClick={() =>
            ClevertapService.pushEvent(`Button Clicked`, {
              Type: 'View All Products',
              'Crop Name': cropSchedule?.category,
            })
          }
        >
          <FormattedMessage id="label_view_all_products" />
        </ViewAllButton>
      )}
    </>
  );
}
