import React, { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  List,
  ListItem,
  ListItemText,
  Typography,
  Button,
} from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { East, Edit } from '@mui/icons-material';
import { Box } from '@mui/system';
import Addresses from './Addresses';
import AddressForm from './AddressForm';
import { getRouteParams } from '../utilities/links';

export default function Review({
  match,
  search,
  shippingAddress,
  setShippingAddress,
  handlePlaceOrder,
}) {
  const { lang } = getRouteParams(match, search);
  const [reviewCart, setReviewCart] = useState({});
  const [isAddressChange, setIsAddressChange] = useState('not_started');

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const localCart = JSON.parse(localStorage?.getItem(`cart`));
      setReviewCart(localCart);
    }
  }, []);

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom sx={{ mt: '30px' }}>
        <FormattedMessage id="label_order_summary" />
      </Typography>
      <List disablePadding>
        {reviewCart?.products?.map((product) => (
          <Card
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              border: 'none',
              marginTop: '12px',
              padding: '5px',
              border: '1px solid #ddd',
            }}
            variant={'outlined'}
            key={product.skuCode}
          >
            <Grid item sx={{ width: '50px' }}>
              <img
                src={product.image}
                width="100%"
                height="100%"
                alt={product.productName[lang]}
              />
            </Grid>
            <Grid item xs={8}>
              <Typography
                variant={'subtitle1'}
                sx={{ lineHeight: `1.3`, marginLeft: -1 }}
              >
                {product.productName[lang]}
                <br />
                <Typography variant={'p'} sx={{ fontSize: '13px' }}>
                  <FormattedMessage id="label_price" />: ₹
                  {product.sellingPrice || 0}
                </Typography>
                <br />
                <Typography variant={'p'} sx={{ fontSize: '13px' }}>
                  <FormattedMessage id="label_quantity" />:{' '}
                  {product.quantity || 1}
                </Typography>
              </Typography>
            </Grid>
          </Card>
        ))}
        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary={<FormattedMessage id="label_total" />} />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            ₹ {reviewCart?.grandTotal}
          </Typography>
        </ListItem>
      </List>
      <Typography
        variant="h6"
        gutterBottom
        sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}
      >
        <FormattedMessage id="label_shipping" />
        <Button
          variant="outlined"
          color="success"
          onClick={() => setIsAddressChange('selection')}
          sx={{ fontSize: '10px' }}
          startIcon={<Edit style={{ fontSize: '16px' }} />}
        >
          <FormattedMessage id="change_address" />
        </Button>
      </Typography>
      <Typography gutterBottom>{shippingAddress?.fullName}</Typography>
      <Typography
        gutterBottom
      >{`${shippingAddress?.village?.value} ${shippingAddress?.taluka?.value} ${shippingAddress?.district?.value} ${shippingAddress?.pinCode} ${shippingAddress?.state?.value}`}</Typography>
      <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
        <FormattedMessage id="label_payment_detail" />
      </Typography>
      <Typography fontSize={14}>
        <FormattedMessage id="label_cash_on_delivery" />
      </Typography>

      <Button
        variant={'contained'}
        onClick={handlePlaceOrder}
        sx={{
          position: 'fixed',
          left: 0,
          bottom: 0,
          width: '100%',
          height: '45px',
          fontSize: '17px',
          borderRadius: '0',
        }}
        endIcon={<East />}
      >
        <FormattedMessage id="label_place_order" />
      </Button>

      {(isAddressChange === 'selection' || isAddressChange === 'edit') && (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            padding: '20px',
            position: 'absolute',
            top: '0',
            left: '0',
            background: 'white',
          }}
        >
          {isAddressChange === 'selection' ? (
            <Addresses
              match={match}
              search={search}
              shippingAddress={shippingAddress}
              setShippingAddress={setShippingAddress}
              setIsAddressChange={setIsAddressChange}
            />
          ) : (
            <AddressForm
              setShippingAddress={setShippingAddress}
              shippingAddress={shippingAddress}
              setIsAddressChange={setIsAddressChange}
              edit={true}
            />
          )}
        </Box>
      )}
    </React.Fragment>
  );
}
