import apiCall from '../utilities/apiCall';
import { getEnv } from '../utilities/getEnv';
import {
  getAppSource,
  getStateBySlug,
  getStateCode,
} from '../utilities/states';

export default class CropInfoService {
  static getMyCrops(language, state, farmerId, farmerAuthToken) {
    const api = `${getEnv(
      'CRM_SERVICE',
    )}/crmservice/v1/farmerdetails/advanced/?language=${language}&farmerId=${farmerId}&field=crops`;
    return apiCall(api, {
      Source: getAppSource(state),
      'X-Authorization-Token': farmerAuthToken,
    });
  }

  static getMyCropsSchedule(language, farmerAuthToken, farmerId) {
    const api = `${getEnv(
      'AGRONOMY_SERVICE',
    )}/agronomyservice/v1/schedules/mycrops?language=${language}&farmerId=${farmerId}`;

    return apiCall(api, { 'X-Authorization-Token': farmerAuthToken });
  }

  static getSowingDateValidation(
    cropName,
    attributeParams,
    state,
    farmerAuthToken,
  ) {
    const api = `${getEnv(
      'CONTENT_SERVICE',
    )}/agronomyservice/v1/schedules/validdates?cropName=${cropName}&stateCode=${getStateCode(
      state,
    )}${attributeParams}`;

    return apiCall(api, { 'X-Authorization-Token': farmerAuthToken });
  }

  static getCropAttributes(cropName, language, state, farmerAuthToken) {
    const api = `${getEnv(
      'CONTENT_SERVICE',
    )}/agronomyservice/v1/schedules/cropattributes?cropName=${cropName}&language=${language}&stateCode=${getStateCode(
      state,
    )}`;

    return apiCall(api, { 'X-Authorization-Token': farmerAuthToken });
  }

  static updateCropAttributes(payload, farmerAuthToken) {
    const api = `${getEnv(
      'CONTENT_SERVICE',
    )}/agronomyservice/v1/schedules/farmercropdetails`;

    return apiCall(api, { 'X-Authorization-Token': farmerAuthToken }, payload);
  }

  static getCropDetails({ cropId, state, language, farmerAuthToken }) {
    const api = `${getEnv(
      'SHOPAGGREGATOR_SERVICE',
    )}/shopaggregator/v2/cropconfiguration/${cropId}?language=${language}`;

    return apiCall(api, {
      Source: getAppSource(state),
      'X-Authorization-Token': farmerAuthToken,
    });
  }

  static getTasks({
    state,
    language,
    cropName,
    farmerAuthToken,
    mockDate,
    mockAllTasks,
    mockScheduleName,
  }) {
    // if cropName not provided, then all tasks will be part of response
    let api = `${getEnv(
      'AGRONOMY_SERVICE',
    )}/agronomyservice/v1/schedules/mytasks?language=${language}`;

    if (cropName) api += `&cropName=${cropName}`;
    if (mockDate) {
      api += `&mockNow=${mockDate}`;
    }
    if (mockAllTasks === 'true' || mockAllTasks === true) {
      api += `&mockAllTasks=${mockAllTasks}`;
    }
    if (
      mockScheduleName !== 'null' &&
      mockScheduleName !== null &&
      mockScheduleName?.length
    ) {
      api += `&mockScheduleName=${mockScheduleName}`;
    }

    return apiCall(api, {
      Source: getAppSource(state),
      'X-Authorization-Token': farmerAuthToken,
    });
  }

  static updateMyCrops(state, userId, farmerAuthToken, payload) {
    const api = `${getEnv(
      'APP_AUXILIARY_SERVICE',
    )}/appuserservice/v1/appuser/${userId}`;
    return apiCall(
      api,
      {
        Source: getAppSource(state),
        'X-Authorization-Token': farmerAuthToken,
      },
      payload,
      'patch',
    );
  }

  static getUserProfileDetails(state, userId, farmerAuthToken) {
    const api = `${getEnv(
      'AGRONOMY_SERVICE',
    )}/appuserservice/v1/appuser/${userId}`;
    return apiCall(api, {
      Source: getAppSource(state),
      'X-Authorization-Token': farmerAuthToken,
    });
  }

  static getCropProducts({ source, language, state, farmerAuthToken, cropId }) {
    const api = `${getEnv(
      'SHOPAGGREGATOR_SERVICE',
    )}/shopaggregator/v1/cropproducts?language=${language}&cropId=${cropId}`;
    return apiCall(api, {
      Source: source || getAppSource(state),
      'X-Authorization-Token': farmerAuthToken,
    });
  }

  static getProblemSolvedPosts({
    language,
    state,
    farmerId,
    cropName: tag,
    farmerAuthToken,
  }) {
    const createdOnLt = new Date();
    const createdOnGt = new Date(
      new Date().setDate(createdOnLt.getDate() - 30),
    );
    const api = `${getEnv(
      'COMMUNICATION_SERVICE',
    )}/communicationservice/v2/post?language=${language}&farmerId=${farmerId}&limit=5&offset=0&curated=false&sortBy=rank&smart_filters=true&filters=Solved%20Problems&blockTagSeenPosts=true&tags=${tag}&createdOnLt=${createdOnLt.getTime()}&createdOnGt=${createdOnGt.getTime()}`;
    return apiCall(api, {
      Source: getAppSource(state),
      'X-Authorization-Token': farmerAuthToken,
    });
  }

  static getGoldVideos({ cropName, state, farmerAuthToken }) {
    const stateCode = getStateBySlug(state)?.code;
    const api = `${getEnv(
      'AGRONOMY_SERVICE',
    )}/agronomyservice/v1/videos/cropvideos?cropName=${cropName}&stateCode=${stateCode}`;
    return apiCall(api, {
      Source: getAppSource(state),
      'X-Authorization-Token': farmerAuthToken,
    });
  }

  static ping() {
    const api = `${getEnv('AGRONOMY_SERVICE')}/agronomyservice/ping`;
    return apiCall(api, {});
  }

  static getSaathiCrops(authToken, farmerId, language) {
    return apiCall(
      `${getEnv(
        'AGRONOMY_SERVICE',
      )}/agronomyservice/v1/crops/mycrops?language=${language}&farmerId=${farmerId}`,
      { 'X-Authorization-Token': authToken },
    );
  }

  static addSaathiCrops(payload, authToken) {
    return apiCall(
      `${getEnv('AGRONOMY_SERVICE')}/agronomyservice/v1/crops/addmycrops`,
      { 'X-Authorization-Token': authToken },
      payload,
    );
  }

  static removeSaathiCrops(payload, authToken) {
    return apiCall(
      `${getEnv('AGRONOMY_SERVICE')}/agronomyservice/v1/crops/deletemycrops`,
      { 'X-Authorization-Token': authToken },
      payload,
    );
  }
}
